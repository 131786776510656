import React from 'react';
import { Container, Typography } from '@material-ui/core';

const DevSettings: React.FC = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Developer Settings
      </Typography>
                  
    </Container>
  );
};

export default DevSettings;