import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import ShowHostAddHostButton from './ShowHostAddHostButton';
import ShowQuicknavButton from '../ShowComponents/ShowQuicknavButton';

const ShowHostDatagridToolbar: React.FC = ({  }) => {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <ShowHostAddHostButton />
            <ShowQuicknavButton/>
        </GridToolbarContainer>
    );
};

export default ShowHostDatagridToolbar;