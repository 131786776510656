import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useShowHostComissions } from '../../Contexts/ShowContexts/ShowCommissions/ShowHostComissionsContext';
import ShowCommissionsDataGridToolbar from './ShowCommissionsDataGridToolbar';
import { CreateShowHostCommissionProvider } from '../../Contexts/ShowContexts/ShowCommissions/CreateShowHostCommissionContext';
import CreateShowHostComissionModal from './CreateShowHostComissionModal';
import { ShowHostProvider } from '../../Contexts/ShowContexts/ShowHost/ShowHostContext';
import { useSingleShow } from '../../Contexts/ShowContexts/SingleShow/SingleShowContext';
import { IShowHostComission } from '../../Contexts/ShowContexts/ShowCommissions/IShowHostCommission';
type ShowSaleDataGridProps = {
    children?: React.ReactNode;
}

const ShowHostCommissionDataGrid = ({} : ShowSaleDataGridProps)  => {
    const {  showHostCommissions, shcLoading, setSelectedShowHostCommissions
    } = useShowHostComissions();
    
    const [displayedHosts, setDisplayedHosts] = React.useState<IShowHostComission[]>([]);
    const {show } = useSingleShow();

    useEffect(() => {
      setDisplayedHosts(showHostCommissions.filter((shc) => show?.showSelectedHosts.some((show2: any) =>  show2.showHost.id === shc.showHost.id )))
    }, [show, showHostCommissions])

    const columns: GridColDef[] = [
        { field: 'showHost', headerName: 'Host', width: 150,
            renderCell: (params) => {
                return <Typography>{params.value.displayName}</Typography>
            }
        },
        { field: 'commissionAmount', headerName: 'Commission', width: 150,
            renderCell: (params) => {
                return <Typography>{params.value * 100}%</Typography>
            }
        },
        { field: 'commissionType', headerName: 'Commission Type', width: 150}
    ];
    if (shcLoading) return <div>Loading...</div>;

    return(
        <>
        <CreateShowHostCommissionProvider>
           <DataGridPro 
                sx={{height: '500px'}}
                rows={displayedHosts}
                columns={columns}
                editMode="row"
                slots={{
                    toolbar: ShowCommissionsDataGridToolbar
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedShowHostCommissions(newRowSelectionModel);
                  }}
                checkboxSelection
           />
           <ShowHostProvider>
            <CreateShowHostComissionModal />
           </ShowHostProvider>
           </CreateShowHostCommissionProvider>
        </>
    )
}
  
  
  export default ShowHostCommissionDataGrid;
  