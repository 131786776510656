import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useGridApiContext } from '@mui/x-data-grid';

const handleKeyDown = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    event.target.blur();
  }
};

function CustomTextInput(props) {
    const {id, field} = props;
  const [value, setValue] = useState(props.value || "");
  const apiRef = useGridApiContext()

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    apiRef.current.setEditCellValue({id, field, value: newValue})
  };

  return (
    <TextField
      inputProps={{style: {fontSize: 14}}}
      
      size="small"
      fullWidth
      clearOnBlur
      onKeyDown={handleKeyDown}
      value={value} // Use the state value
      onChange={handleChange}
    />
  );
}

export default CustomTextInput;
