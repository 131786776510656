import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useGridApiContext } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();
const AutocompleteUser = (props) => {
  const apiRef = useGridApiContext();
  const { id, field,  } = props;
  const [value, setValue] = useState(props.value);
  const [dataFields, ] = useState(props.datafields);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = async (event, newValue) => {
    if (newValue === null) {
      setValue("");
      return;
    }
    if (typeof newValue === "string") {
      setTimeout(async () => {
        setValue(newValue);
        await apiRef.current.setEditCellValue({ id, field, value: newValue });
      });
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const inputValue = event.target.value;
      const options = dataFields ? dataFields : [];
      const filtered =
        inputValue !== ""
          ? options.filter((option) =>
              option.toLowerCase().includes(inputValue.toLowerCase())
            )
          : [];

      if (filtered.length > 0) {
        handleChange(event, filtered[0]);
      }
      event.target.blur();
    }
    else if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();

      const inputValue = event.target.value;
      const options = dataFields;
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filtered.length > 0) {
        handleChange(event, filtered[0]);
        event.target.blur();
      }
    }
  };

  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    return filtered;
  };

  return (
    <div className="autocomplete-container">
      <Autocomplete
        sx={{
          width: 300,
        }}
        value={value ? value : ""}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        filterOptions={handleFilterOptions}
        id="free-solo-dialog-demo attribute"
        options={dataFields}
        getOptionLabel={(option) => {
          if (!option) {
            return "";
          }
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(p2, option) => <li style={{fontSize: "12px", whiteSpace: "nowrap"}} {...p2}>{option}</li>}
        freeSolo
        PaperComponent={({ children }) => (
          <Paper style={{ border: "2px solid #5048E5", top:"-1000px"}}>{children}</Paper>
        )}
        renderInput={(params) => <TextField {...params} 
        style={{ fontSize: "14px" }}
        size="small" />}
      />
    </div>
  );
};

AutocompleteUser.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array,
};

export default AutocompleteUser;
