import React from 'react';
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { FilterPresetTypeNames } from '../../Components/CommonUtility/ServerSide/FilterPresets/FilterPresetTypeNames'; // Ensure this path is correct
import { useShowSales } from '../../Contexts/ShowSalesContexts/ShowSaleContext';
import FilterList from '../CommonUtility/ServerSide/FilterPresets/FilterList';

const ShowSaleCommissionDataGridToolbar: React.FC = ({}) => {
    const {
        filterModel, setFilterModel, filterList
    } = useShowSales();

    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
            <GridToolbarFilterButton />
            <FilterList 
                filterList={filterList}
                refresh={true}
                setRefresh={() => {}}
                setFilterModel={setFilterModel} 
                filterModel={filterModel}
                deleteUrl="/Filter/delete"
                saveUrl="/Filter/save"
                filterPresetName={FilterPresetTypeNames.SINGLE_SHOW_SALE_FILTER_FOR_OPTIONS}  
            />
        </GridToolbarContainer>
    );
};

export default ShowSaleCommissionDataGridToolbar;