import React from 'react';
import EditIcon from '@mui/icons-material/Edit'; // Import EditIcon
import { useShow } from '../../Contexts/ShowContexts/Shows/ShowContext';
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid-pro';

interface EditShowGridButtonProps {
    id: GridRowId;
    row: any;
}

const EditShowGridButton: React.FC<EditShowGridButtonProps>  = ({row}) => {
    const {setEditFields} = useShow();

    const handleEditClick = (show: any) => {
        setEditFields(show);
    };
    return (
        <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {handleEditClick(row)}}
            color="inherit"
      />
    );
};

export default EditShowGridButton;
