import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useShowSales } from '../../Contexts/ShowSalesContexts/ShowSaleContext';
import { useShowHostComissions } from '../../Contexts/ShowContexts/ShowCommissions/ShowHostComissionsContext';
import { CreateShowSaleCommission } from '../../Contexts/ShowContexts/ShowCommissions/ShowComissionAPICalls';
import { useSingleShow } from '../../Contexts/ShowContexts/SingleShow/SingleShowContext';
import { useSnackbar } from '../../Contexts/SnackbarContext/SnackbarContext';
import { useUser } from '../CommonUtility/UserContext';
import { useShowSaleTypes } from '../../Contexts/ShowSalesContexts/ShowSaleTypesContext';

const SaleCommissionCreateButton = () => {
    const {show} = useSingleShow();
    const {selectedSaleType} = useShowSaleTypes();
    const {newSnackbarMessage} = useSnackbar();
    const {user} = useUser();
    const {getSelectedSales, addCommissions} = useShowSales();
    const {selectedShowHostCommissions} = useShowHostComissions();
    const [canCreate, setCanCreate] = React.useState(true);

    function attachSales() {
        const selectedSales = getSelectedSales()
        createShowSale(selectedSales, selectedShowHostCommissions)
    }
    function buttonDisabled() {
        return (selectedSaleType == null ) || (selectedShowHostCommissions.length == 0 || getSelectedSales().length == 0)
    }
    async function createShowSale(saleIds: any[], hostIds: any[]) {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreateShowSaleCommission(show, saleIds, hostIds, user);
                if (response.status == 200) {
                    const data = await response.json();
                    addCommissions(saleIds, data)
                    newSnackbarMessage("Successfully created new Sale Commission", 'success');
                } else if (response.status == 409) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating Sale Commisions, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating Sale Commissions, connection invalid please try again later", 'error');
            }

            setCanCreate(true)
        }
    }
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                attachSales()
            }}
            disabled={buttonDisabled()}
        >
            Add Selected Hosts to Selected Sales
        </Button>
    );
};

export default SaleCommissionCreateButton;