import React, { useRef } from 'react';

import { Typography, Box, Paper, Button} from '@mui/material';
import {  UploadFile } from '@mui/icons-material';
import { useFileUploader } from './FileUploaderContext';
import './fileUploader.css';

type FileUploaderProps = {
    children?: React.ReactNode;
    title?: string;
    selectText?: string;
    supportsText?: string;
    subText?: string;
    uploadHandledExternally?: boolean;
}

const SimpleFileUploader = ({title, selectText, supportsText, subText, uploadHandledExternally}: FileUploaderProps): React.ReactElement<FileUploaderProps> => {
    const {setFile, selectedFiles,  acceptsFileType, uploading, handleDrop, dragOver, setDragOver, setSelectedFiles, uploadToBackend} = useFileUploader();
    const ref: any = useRef(null);

    title = title || "Drag & Drop or";
    selectText = selectText || "browse";

    window.addEventListener("dragover",function(e){
        e.preventDefault();
      },false);
      window.addEventListener("drop",function(e){
        e.preventDefault();
      },false);

    if (uploading) {
        return (
        <Paper
            className='simpleFileUploaderContainer'
        >
            <Typography variant='h6'>File is Uploading/Processing</Typography>
        </Paper>
        )
    }
    
    if (dragOver) return (
        <Paper
            className='simpleFileUploaderContainer'
        >
            <Paper 
                sx={{ borderColor: 'secondary.main' }}
                className='simpleFileUploaderDashedContainer'
                onDragLeave={(e) => {
                    e.preventDefault();
                    setDragOver(false);
                }}
                onDrop={handleDrop}
                
                >
                {selectedFiles != null ? <Typography variant='h6'>Upload new file(s)</Typography> : <Typography variant='h6'>Upload files(s)</Typography>}

            </Paper>
        </Paper>
      )

      if (selectedFiles != null) {
        return (
            <Paper
            className='simpleFileUploaderContainer'
            >
                <Paper 
                sx={{ borderColor: 'divider' }}
                className='simpleFileUploaderDashedContainer'
                onDragOver={(e) => {
                    e.preventDefault();
                    setDragOver(true);
                }}
                onDrop={handleDrop}
                >
                    {selectedFiles.getAll('file').length == 1 ? 
                        <Typography variant='h6'>{selectedFiles.getAll('file')[0].name}</Typography> :
                        <Typography variant='h6'>{selectedFiles.getAll('file').length} Files Selected</Typography>
                    }
                    <Button onClick={() => setSelectedFiles(null)}>Clear files</Button>
                    {!uploadHandledExternally && <Button onClick={() => uploadToBackend()}>Upload</Button>}
                </Paper>
            </Paper>
        )
    }

    return (
        <Paper
            className='simpleFileUploaderContainer'
        >
            <Box 
                className='simpleFileUploaderDashedContainer'
                sx={{ borderColor: 'divider' }}
                onDrop={handleDrop}
                onDragOver={(e) => {
                    e.preventDefault();
                    setDragOver(true);
                }}
                >
                <UploadFile className='simpleUploadFilePicture' />

                <Box>
                    <Box sx={{
                    display: 'flex', flexDirection:'row'
                    }} >
                    <Typography variant="h6">{title}&nbsp;</Typography>
                        <Typography variant="h6" color='secondary.main' sx={{cursor: 'pointer'}}
                            onClick={() => {
                                if (ref.current) {
                                    ref.current?.click();
                                }
                            }}
                        >{selectText}</Typography>
                        <input 
                        type="file" multiple={true} name="file" hidden onChange={(e) => setFile(e)} 
                        accept={acceptsFileType}
                        ref={ref}
                        />
                    </Box>
                    <Typography variant="subtitle2" color="gray">{supportsText}</Typography>
                    <Typography variant="subtitle2" color="gray">{subText}</Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export default SimpleFileUploader;
