import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";

const fetchData = new FetchModule();

export default class GiveawayList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.item.name,
            customer: props.item.customer,
            disabled: true,
            removeDisabled: false,
        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    getSaveButton = () => {
        return (
            <td>
            <div>
                <Button color="danger" onClick={this.cancel} disabled={this.state.disabled}>Cancel</Button>
                <Button color="primary" onClick={this.save} disabled={this.state.disabled}>Save</Button>
            </div>
            </td>
        )
    }
    cancel = () =>{ 
        this.updateState("name", this.props.item.name)
        this.updateState("customer", this.props.item.customer)
        this.updateState("disabled", true)
    }
    save = async () => {
        this.updateState("disabled", true)
        this.updateState("removeDisabled", true)
        var body = {
            customer: this.state.customer,
            id: this.props.item.id,
            date: this.props.item.date,
            name: this.state.name,
            productId: this.props.item.productId,
            productName: this.props.item.productName,
            sessionId: this.props.item.sessionId
        }
        var response = await fetchData.promise("/Breaking/giveaways/update", true, "POST", body)
        if (response.status == 200) {
            this.props.item.name = this.state.name
            this.props.item.customer = this.state.customer
            this.updateState("disabled", true)
            this.updateState("removeDisabled", false)
        } else {
            this.updateState("removeDisabled", false)
            this.updateState("disabled", false)
        }
        
    }
    getNiceDateDisplay = (date) => {
        var dateSplit = date.split("T")
        var ymd = dateSplit[0].split("-")
        return ymd[1] +"/"+ymd[2] +"/"+ymd[0]
    }
    onTextChange = (event) => {
        this.updateState("disabled", false)
        this.updateState(event.target.id, event.target.value)
    }
    render() {
        return (
            <tr>
                <td>{this.props.item.productName}</td>
                <td>
                    <Input
                        id="name"
                        placeholder="Enter giveaway name"
                        value={this.state.name}
                        onChange={this.onTextChange}
                    />
                </td>
                <td>
                    <Input
                        id="customer"
                        placeholder="Enter customer username"
                        value={this.state.customer}
                        onChange={this.onTextChange}
                    />
                </td>
                <td>{this.getNiceDateDisplay(this.props.item.date)}</td>
                {this.getSaveButton()}
                <td><Button color="danger" disabled={this.state.removeDisabled} onClick={ () => {this.props.removeModal(this.props.item)}}>X</Button></td>
            </tr>
        )
    }

}
