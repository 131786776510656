import React from 'react';
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import ModalOpenerButton from '../Common/ModalOpenerButton';
import { useCreateShowHostCommission } from '../../Contexts/ShowContexts/ShowCommissions/CreateShowHostCommissionContext';
import SaleCommissionCreateButton from '../ShowSaleComponents/SaleCommissionCreateButton';

const ShowCommissionsDataGridToolbar: React.FC = ({}) => {
    const {setModalOpen} = useCreateShowHostCommission();

    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarDensitySelector />
            <ModalOpenerButton text="Add Show Host Commission" setOpen={setModalOpen}/>
            <SaleCommissionCreateButton />
        </GridToolbarContainer>
    );
};

export default ShowCommissionsDataGridToolbar;