import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useSinglePayPeriods } from "../../Contexts/PayPeriodContexts/SinglePayPeriodContext";
import PayPeriodShowsDataGrid from "./PayPeriodShowsDataGrid";
import KpiCard from "../../Components/Common/KpiCard";



const SinglePayPeriodLayout = () => {
    const {        
      assignedShows,
      assignedShowsLoading,
      assignedShowsError,
      unassignedShows,
      unassignedShowsLoading,
      unassignedShowsError,
      assignShow, unassignShow,
      selectedAssignedShows, setSelectedAssignedShows,
      selectedUnassignedShows, setSelectedUnassignedShows,
      assignedShowsGridApiRef,
      unassignedShowsGridApiRef,
      currentTotalCommissions,
      updating,
      payPeriod
    } = useSinglePayPeriods();

    const lowerDate = new Date(payPeriod?.days?.lowerBound)
    const upperDate = new Date(payPeriod?.days?.upperBound)
    function renderKpiCards() {
      return Object.values(currentTotalCommissions).map(
          (commission) => {
            const randomKey = commission.host + "-GridCard-" + Math.random().toString(36).substr(2, 9);
            return <Grid item xs={3} key={randomKey}>

                      <KpiCard 
                          title={commission.host +" commission"}
                          mainText={'$' + commission.totalCommission.toFixed(2) +" ($" + commission.breaksTotalCommission.toFixed(2) + " breaks, $" + commission.personalsTotalCommission.toFixed(2) +" personals)"} 
                          subText={commission.totalSales + " sales attached (" + commission.slotsCount + " slots from " + commission.totalBreaks + " breaks & " + commission.personalsCount + " personals)"}
                        />                    
                    </Grid>
          }
      )
  }

    return(
  <>
      <Box sx={{
        width: '100%',
        height: '40px',
        display: 'flex',

        flexDirection: 'row',
        }}>
        <Typography variant="h5" sx={{marginBottom: '3px', marginRight: '1em'}}>Pay Period</Typography>
        <Typography variant="h6" sx={{marginBottom: '3px', alignContent: 'center'}}>{lowerDate.toLocaleDateString() } - {upperDate.toLocaleDateString()}</Typography>

      </Box>
      <Divider sx={{borderBottomWidth: 3}}/>

      <Box
        sx={{display: 'flex', flexDirection: 'column'}}
      >
        <PayPeriodShowsDataGrid 
          shows={assignedShows} 
          showsError={assignedShowsError} 
          showsLoading={assignedShowsLoading} 
          selectedShows={selectedAssignedShows} 
          setSelectedShows={setSelectedAssignedShows}
          gridApiRef={assignedShowsGridApiRef}
        />
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Button onClick={() => assignShow()} disabled={selectedUnassignedShows.length <= 0 || updating}>Assign selected Shows</Button>
          <Button onClick={() => unassignShow()} disabled={selectedAssignedShows.length <= 0 || updating}>Unassign selected Shows</Button>
        </Box>
        <PayPeriodShowsDataGrid 
          shows={unassignedShows} 
          showsError={unassignedShowsError} 
          showsLoading={unassignedShowsLoading} 
          selectedShows={selectedUnassignedShows} 
          setSelectedShows={setSelectedUnassignedShows}
          gridApiRef={unassignedShowsGridApiRef}
        />
      </Box>
      <Grid container spacing={2}>
          {renderKpiCards()}
      </Grid>
  </>
);
}

export default SinglePayPeriodLayout;
