import React, { useState,useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useGridApiContext } from '@mui/x-data-grid';
import dayjs from 'dayjs';

const handleKeyDown = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    event.target.blur();
  }
};

const preventFutureDates = (date) => {
  return !dayjs(date).isBefore(dayjs(new Date()).add(7, 'day'));

}

const validateDate = (date)=>{
  date.replace('-','/')
  if(/[^0-9/]/.test(date)){
    return("BadData")
  }
  var arrayRep =date.split('/')
  //Check to see if there are 3 date segments(day.month,year)
  if(arrayRep.length <2 || arrayRep.length>3 ){
  
    return 'InvalidFormat'
  }

  if(preventFutureDates(date) && arrayRep.length === 3){
    return 'Invalid Future Date'
  }
  
  if(arrayRep.length === 2){
    
    arrayRep.push(dayjs(new Date()).year()+"")
    
  }
  
  //Auto add the 0 in front of the day if it is needed
  if(arrayRep[0].length === 1){
    arrayRep[0]= "0"+arrayRep[0]
  }
  //Auto add the 0 in front of the month if it is needed
  if(arrayRep[1].length === 1){
    arrayRep[1]= "0"+arrayRep[1]
  }
  //look at the year, if it is valid,  no mod, else, auto append the date
  if(!arrayRep[2].startsWith("202")&& arrayRep[2].length !== 4){
    arrayRep[2]= dayjs(new Date()).year()+"";

  }
  
  return arrayRep;
}
function convertArrayToDate(dateParts) {
  const [month, day, year] = dateParts;
  const dateString = `${month}/${day}/${year}`;
  const convDateVal =  dayjs(dateString, 'MM/DD/YYYY').toDate();
  return convDateVal
}

function CustomDateInput(props) {

  const {id, field,hasFocus,setSnackbar} = props;
  var dayRep = dayjs(props.value,dayjs.ISO_8601);
  var tempDate = dayRep.format("MM/DD/YYYY");

  if(tempDate === "Invalid Date" || tempDate === "Invalid Future Date" || tempDate === "BadData" || tempDate === "InvalidFormat"){
    tempDate = ""
  }
  
  const [stringValue,setStringValue] = useState(tempDate.toString())
  const apiRef = useGridApiContext()

  const handleChange = (event) => {
    const newValue = event.target.value;
    setStringValue(newValue)
  };

  const submitChange = ()=>{

    if (stringValue === "") {
      apiRef.current.setEditCellValue({ id, field, value: "" });
      return;
    }

    var val = validateDate(stringValue);
    if(typeof val === 'string'){
      setSnackbar({
        children: "Error processing data: "+val,
        severity: "error",
      });
      console.error("There seems to be an error in your data: ",val)
      return;
    }
    var formattedVal = dayjs(convertArrayToDate(val))
    // Update the local state
    
    setStringValue(formattedVal.format('MM/DD/YYYY'));
    if(formattedVal.isValid()){
      apiRef.current.setEditCellValue({ id, field, value: formattedVal.toISOString() });
    }
    else{
      apiRef.current.setEditCellValue({ id, field, value: "Bad Data" });
      setSnackbar({
        children: "Error processing data: You input an invalid date: " + formattedVal.format('MM/DD/YYYY'),
        severity: "error",
      });
    }
    
  };
  
  useEffect(() => {
    if (!hasFocus) {
        submitChange();
    }
  }, [hasFocus, stringValue, id, field, apiRef]);
  return (
    <TextField
      inputProps={{style: {fontSize: 14}}}
      
      size="small"
      fullWidth
      clearOnBlur
      hiddenLabel
      onKeyDown={handleKeyDown}
      value={stringValue} // Use the state value
      onChange={handleChange}
    />
  );
}

export default CustomDateInput;
