import { OperatorSetBuilder } from "./GenericServerSideComponents/OperatorsBuilder";

interface FilterProps {
  options: any[];
  field: any;
  updateFilterModel: (field:string,operator:string,value:any) => any;
  filterModel: any;
}

export const NewSkuFilter = ({ options }: FilterProps) => {
  const operatorBuilder = new OperatorSetBuilder();
  operatorBuilder.addAutocompleteSingleValue("equals", "equals", options, "label");
  operatorBuilder.addBasicOperator("contains", "contains");
  operatorBuilder.addBasicOperator("starts with", "startsWith");
  operatorBuilder.addBasicOperator("ends with", "endsWith");
  operatorBuilder.addNoValueFilter("is empty", "isEmpty");
  operatorBuilder.addNoValueFilter("is not empty", "isNotEmpty");
  operatorBuilder.addAutocompleteMultipleValue("is any of", "isAnyOf", options, "label");
  return operatorBuilder.build();
}