// RepackCreation.tsx
import React from "react";
import { BuildRepacksProvider } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import RepackCreationContent from "./RepackCreationContent";

export default function RepackCreation(props: any) {
  return (
    <BuildRepacksProvider>
      <RepackCreationContent {...props} />
    </BuildRepacksProvider>
  );
}
