import React from "react";
import { TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import propagateChanges from "../BulkCardsCreator/NumericPropogateChanges";

const handleKeyDown = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    event.target.blur();
  }
};

function CustomNumberInput({ id, value, field,row }) {
  const apiRef = useGridApiContext();

  const handleValueChange = async (event) => {
    const newValue = event.target.value;

    if(field === "cost" && (row.comp === 0 || row.comp === row.cost)) {
      await apiRef.current
        .setEditCellValue({
          id,
          field: "comp",
          value: Number(newValue),
        });
    }

    row[field] = Number(newValue);
    
    await apiRef.current.setEditCellValue({
      id,
      field: field,
      value: Number(newValue),
    });
    
  
    const updatedRow = apiRef.current.getRow(id);

    const rowsMap = apiRef.current.getRowModels();
    const allRows = Array.from(rowsMap.values());
    propagateChanges(updatedRow, row, allRows, true);
  };

  return (
    <TextField
      inputProps={{ style: { fontSize: 14 } }}
      size="small"
      fullWidth
      value={value}
      onChange={handleValueChange}
      onKeyDown={handleKeyDown}
      type="number"
    />
  );
}

export default CustomNumberInput;
