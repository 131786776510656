import React from 'react';
import { DataGridPremium, GridColDef} from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';

type PayPeriodHostCommissionsDataGridProps = {
    children?: React.ReactNode;
    row: any;
}

const PayPeriodHostCommissionsDataGrid = ({row} : PayPeriodHostCommissionsDataGridProps)  => {

    const columns= React.useMemo<GridColDef<any>[]>( () => [
        { field: 'displayName', headerName: 'Host', width: 100,
            valueGetter: (value: any) => {
                return value;
              },
        },
        { field: 'totalCommission', 
            headerName: 'Commission', 
            width: 125, 
            type: 'number',
            valueGetter: (params) => {
                return params ?? 0
            },
            renderCell: (params => {
                return <Typography sx={{alignContent: 'center', width: '100%', height: '100%'}} >${new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        {field: 'totalSales', headerName: 'Sales Count', width: 110, groupable: false},
        { field: 'personalsTotalCommission', 
            headerName: 'Personals Commission', 
            width: 180, 
            type: 'number',
            valueGetter: (params) => {
                return params ?? 0
            },
            renderCell: (params => {
                return <Typography sx={{alignContent: 'center', width: '100%', height: '100%'}} >${new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        { field: 'personalsCount', headerName: 'Personals Count', width: 150, groupable: false},
        { field: 'breaksTotalCommission', 
            headerName: 'Breaks Commission', 
            width: 150, 
            type: 'number',
            valueGetter: (params) => {
                return params ?? 0
            },
            renderCell: (params => {
                return <Typography sx={{alignContent: 'center', width: '100%', height: '100%'}} >${new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        {field: 'totalBreaks', headerName: 'Breaks Count', width: 100, groupable: false},
        {field: 'totalShows', headerName: 'Shows', width: 100, groupable: false},


    ],[])

    return(
        <>
           <DataGridPremium 
                rows={row}
                columns={columns}
                />
        </>
    )
}
  
  
  export default PayPeriodHostCommissionsDataGrid;
  