import React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
import { CreateShowChannel } from './ShowChannelAPICalls';
import { IPlatform } from '../Platforms/IPlatform';
import ShowChannelModal from '../../../Components/ShowChannelComponents/ShowChannelModal';

const ShowChannelContext = createContext<ShowChannelContextProps | null>(null)
interface ShowChannelContextProps {
    channelName: string, setChannelName: (name: string) => void, 
    channelNameValid: () => boolean,
    createChannel: () => void,
    platform: IPlatform | null, setPlatform: (pf: IPlatform | null) => void,
    platformValid: () => boolean,
    clearData: (name: boolean) => void,
    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createChannelModalOpen: boolean, 
    setCreateChannelModal: (name: boolean) => void,
    closeChannelModal: (name: boolean) => void,
}

type ShowChannelProviderProps = {
    children?: React.ReactNode;
}

export const ShowChannelProvider = ({children} : ShowChannelProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();

    const [channelName, setChannelName] = useState(""); 
    const [platform, setPlatform] = useState<IPlatform | null>(null);
  
    const [canCreate, setCanCreate] = useState(false);
    const [createChannelModalOpen, setCreateChannelModal] = useState(false);
    const {user} = useUser();
    
    async function createChannel() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreateShowChannel(channelName, platform, user);
                if (response.status == 200) {
                    const data = await response.json();
                    newSnackbarMessage("Successfully created Channel", 'success');
                    if (createChannelModalOpen) {
                        setCreateChannelModal(false);
                        clearData();
                        return data;
                    }
                } else if (response.status == 409) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating channel, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating channel, connection invalid please try again later", 'error');
            }
            setCanCreate(true)
        }
    }
    // Update the canCreate state based on the input fields for validation reasons
    useEffect(() => {
        if (channelNameValid() && platformValid()) {
            setCanCreate(true)
        } else {
            setCanCreate(false)
        }
    }, [channelName, platform])

    function clearData() {
        setChannelName("");
        setPlatform(null);
    }
    function closeChannelModal() {
        setCreateChannelModal(false);
        setCanCreate(false);
        clearData();
    }

    function channelNameValid() {
        return channelName.length > 0;
    }
    function platformValid() {
        return platform != null
    }
    const contextValues: ShowChannelContextProps = {
        channelName, setChannelName, channelNameValid,
        platform, setPlatform, platformValid,
        canCreate, setCanCreate, clearData,
        createChannel,
        createChannelModalOpen, setCreateChannelModal, closeChannelModal
    }
    return (
        <ShowChannelContext.Provider value={contextValues}>
            <ShowChannelModal />
            {children}
        </ShowChannelContext.Provider>
        )
}

export function useShowChannel() {
    const context = useContext(ShowChannelContext);
    if (!context) {
        throw new Error("Invalid ShowChannelContext, must be wrapped in a PlatformProvider")
    }
    return context;
}