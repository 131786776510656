import { Box } from "@mui/material";
import { SinglePayPeriodsProvider } from "../Contexts/PayPeriodContexts/SinglePayPeriodContext";
import SinglePayPeriodLayout from "../Dash/PayPeriodPage/SinglePayPeriodLayout";


const SinglePayPeriodPage = () => {
    
    const path = window.location.pathname.split("/")[3]
    return(
        <Box
            sx={{
                width: "100%",
                height: "100%",
                padding: "5px",
                overflowY: 'scroll'
            }}
        >     
            <SinglePayPeriodsProvider payPeriodId={path}>
                <SinglePayPeriodLayout />
            </SinglePayPeriodsProvider>
        </Box>
    );
}

export default SinglePayPeriodPage;
