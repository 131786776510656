import React, { Component } from 'react';
import {useNavigate} from "react-router-dom";
import { Button, Table, InputGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import SessionItem from './SessionItem'
import FetchModule from "../../Modules/FetchModule";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

class Session extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sessions: [],
            isOpen: false,
            startDate: null,
            disabled: false,
            channels: [
                { value: 'WTH1', label: 'WTH1' },
                { value: 'WTH2', label: 'WTH2' }
            ],
            channel: null
        }
        this.state.startDate = new Date()
        this.state.channel = this.state.channels[0].value
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed("/Breaking/sessions", "GET");
        const data = await response.json()
        this.updateState("sessions", data)
    }

    newSession = async () => {
        if (!this.state.disabled) { 
            this.updateState('disabled', true)
        var body = {
            date: this.state.startDate.toJSON(),
        }
            const response = await fetchData.promise("/Breaking/session/new/" + this.state.channel, true, "POST", body);
        if (response.status == 200) {
            const id = await response.json()
            let sessions = this.state.sessions
            sessions.unshift(id)
            this.updateState('sessions', sessions)
            this.updateState('isOpen', false)
            this.updateState('startDate', new Date())
        }
            this.updateState('disabled', false)
        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    textChange = (event) => {
        this.updateState('searchText', event.target.value)
    }
    goToSession = (id, item) => {
        if (item.saleId = "") {
            this.props.navigate("/dashboard/session/"+id+"/create")

        } else {
            this.props.navigate("/dashboard/session/"+id+"/breaks")

        }
    }
    getDisplayItem = (item) => {
        return (
            <SessionItem 
                key={item.id+"t"}
                item={item}
                goToSession={this.goToSession}
            />
        )
    }
    getData = () => {
        return this.state.sessions.map(sessions => this.getDisplayItem(sessions))
    }
    getSearchBody = () => {
        return (
            <div className="card-body">
                <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
                </InputGroup>
                <Table className="fixTable" style={{height: '92%'}}>
                        <thead>
                            <tr className="thead-fix">
                                <th>Session</th>
                                <th>Name</th>
                                <th>Sales Rep</th>
                                <th>Has Sales order</th>
                           </tr>
                        </thead>
                        <tbody className="tableBody">
                            {this.getData()}
                        </tbody>
                </Table>
            </div>
        )
    }
    toggleModal =() => {
        if (!this.state.disabled) {
            this.updateState("isOpen", !this.state.isOpen)
        }
    }
    setStartDate = async (date) => {
        this.updateState("startDate", date)
    }

    channelChanged = (val) => {
        this.updateState("channel", val.value)
    }


    getBody = () => {


        return (
            <div className="dashboard-body">
                <div className="card">
                    <div className="card-header">
                        <Button
                            onClick={this.toggleModal}
                            color="primary"
                        >+</Button>
                        <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
                            <ModalHeader toggle={this.toggleModal}>Create new session</ModalHeader>
                            <ModalBody>
                                Channel:
                                <Select
                                   // value={this.state.breakType}
                                    defaultValue={this.state.channels[0]}
                                    onChange={this.channelChanged}
                                    options={this.state.channels}
                                    //isClearable={false}
                                    //isSearchable={false}
                                />
                                Date:
                                <DatePicker selected={this.state.startDate} onChange={this.setStartDate} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color='danger' onClick={this.toggleModal} disabled={this.state.disabled}>Cancel</Button>
                                <Button
                                    disabled={this.state.disabled}
                                    onClick={this.newSession}
                                    color="primary"
                                >New Session</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    {this.getSearchBody()}
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {this.getBody()}
            </>
        )
    }

}

export default function(props) {
    const navigate = useNavigate();
    return <Session logout={props.logout} navigate={navigate}/>;
}
