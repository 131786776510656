const dev = 'https://localhost:7045';
const prod = 'https://product-api.wethehobby.com'
const devEnv = 'https://product-api-dev.wethehobby.com' // spun up using terraform

// Helper method to just return the link of
// Change this in order to choose which api link to go
// off of (from above)
const getLink = () => {
    var host = window.location.host;
    switch (host) {
        case 'localhost:3000':
            return dev;
        case 'dev.wethehobby.com':
            return devEnv;
        case 'product.wethehobby.com':
            return prod;
        default:
            return dev;
    }
}
// Returns the token that is saved for your account
const getToken = () => {
    const name = "jwtToken="
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res
}


exports.getLink = getLink;
exports.getToken = getToken;