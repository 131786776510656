import React from 'react';
import { Card, Typography } from '@mui/material';


interface KpiCardProps {
    title?: string;
    subTitle?: string;
    mainText: string;
    subText?: string;
    style?: any;
    children?: React.ReactNode;
}

const KpiCard: React.FC<KpiCardProps> = ({title, subTitle, mainText, subText, style, children}) => {

    if (style == null || style == undefined) {
        style = {
            padding: '10px',
            display: 'flex',
            flexDirection: 'column'
        }
    }

    return (
        <Card sx={style}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="subtitle1">{subTitle}</Typography>
            <Typography variant="subtitle2">{mainText}</Typography>
            <Typography variant="subtitle2">{subText}</Typography>
            {children}
        </Card>
    );
};

export default KpiCard;