import React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
import { CreateShowSlot } from './ShowSlotsAPICalls';
import ShowSlotModal from '../../../Components/ShowSlotComponents/ShowSlotModal';

const ShowSlotContext = createContext<ShowSlotContextProps | null>(null)
interface ShowSlotContextProps {
    showSlotName: string, setShowSlotName: (name: string) => void, 
    showSlotNameValid: () => boolean,
    createShowSlot: () => void,
    clearData: (name: boolean) => void,
    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createShowSlotModalOpen: boolean, 
    setShowSlotModal: (name: boolean) => void,
    closeShowSlotModal: (name: boolean) => void,
}

type ShowSlotProviderProps = {
    children?: React.ReactNode;
}

export const ShowSlotProvider = ({children} : ShowSlotProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();

    const [showSlotName, setShowSlotName] = useState(""); 
  
    const [canCreate, setCanCreate] = useState(false);
    const [createShowSlotModalOpen, setShowSlotModal] = useState(false);
    const {user} = useUser();
    
    async function createShowSlot() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreateShowSlot(showSlotName, user);
                if (response.status == 200) {
                    const data = await response.json();
                    newSnackbarMessage("Successfully created ShotSLot", 'success');
                    if (createShowSlotModalOpen) {
                        setShowSlotModal(false);
                        clearData();
                        return data;
                    }
                } else if (response.status == 403) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating showslot, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating showslot, connection invalid please try again later", 'error');
            }
            setCanCreate(true)
        }
    }
    // Update the canCreate state based on the input fields for validation reasons
    useEffect(() => {
        if (showSlotNameValid()) {
            setCanCreate(true)
        } else {
            setCanCreate(false)
        }
    }, [showSlotName])

    function clearData() {
        setShowSlotName("");
    }
    function closeShowSlotModal() {
        setShowSlotModal(false);
        setCanCreate(false);
        clearData();
    }

    function showSlotNameValid() {
        return showSlotName.length > 0;
    }

    const contextValues: ShowSlotContextProps = {
        showSlotName, setShowSlotName, showSlotNameValid,
        canCreate, setCanCreate, clearData,
        createShowSlot, closeShowSlotModal,
        createShowSlotModalOpen, setShowSlotModal,
    }
    return (
        <ShowSlotContext.Provider value={contextValues}>
            <ShowSlotModal />
            {children}
        </ShowSlotContext.Provider>
        )
}

export function useShowSlot() {
    const context = useContext(ShowSlotContext);
    if (!context) {
        throw new Error("Invalid ShowSlotContext, must be wrapped in a ShowSlotProvider")
    }
    return context;
}