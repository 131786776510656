import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Typography } from "@mui/material";

interface AddCardsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  response: any;
  type: string;
  number: number;
}

const BulkAddCardsSuccessModal: React.FC<AddCardsProps> = ({
  open,
  setOpen,
  response,
}) => {
  const toggleModal = () => {
    setOpen(!open);
  };
  
  

  const length = response?.length
  const firstId = response ? response[0] : 0
  const lastId = response ? response[length-1] : 0

  const sendToCardsPage = (inventoryId: number)=>{
    setOpen(!open);
    window.location.href = `/dashboard/cards?card=${inventoryId}`;
  }

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal} className="add-cards-modal">
        <ModalHeader toggle={toggleModal}>
          {response ? (
            <Typography variant="h5">Successfully Saved Cards </Typography>
          ) : (
            <Typography variant="h5">Failed to Save Cards</Typography>
          )}
        </ModalHeader>
        <ModalBody>
          {response ? (
            <Typography>
              Successfully created {length} card(s) in the inventory ID range {firstId} - {lastId}
              {response?.message}
            </Typography>
          ) : (
            <Typography>Failed to save cards</Typography>
          )}
        </ModalBody>
        <ModalFooter>
          {response  ? (
            <Button
              color="primary"
              className="jump-button"
              onClick={()=>sendToCardsPage(firstId)}
            >
              Jump
            </Button>
          ) : (
            <Button
              color="primary"
              className="jump-button"
              onClick={toggleModal}
            >
              Back
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BulkAddCardsSuccessModal;
