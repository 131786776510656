import { useEffect, useContext } from "react";
import { Box, useMediaQuery } from "@mui/material";
import DashboardSidebar from "./DashboardSidebar";
import { DashboardNavbar } from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { NavigateContext } from "../../Pages/ReactContexts/NavigateContext";
import { DashboardSidebarContext } from "../../Pages/ReactContexts/NavigateContext";
import DashboardSettings from "./DashboardSettings";
import { useUser } from "../CommonUtility/UserContext";
export const DashboardLayout = (props) => {
  const navigate = useNavigate();
  let { isLoggedIn } = useUser();
  useEffect(() => {
    try {
      if (
        window.location.pathname !== "/" ||
        window.location.pathname !== "/dashboard/barcode"
      ) {
        if (!isLoggedIn()) {
          navigate("/dashboard/login");
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [isLoggedIn, navigate]);

  const { children } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const page = "";
  const { isSidebarOpen, setSidebarOpen } = useContext(DashboardSidebarContext);
  const paddingTop = DashboardSettings.navbarHeight + "px";
  const drawerLeft = DashboardSettings.drawerWidth + "px";

  return (
    <NavigateContext.Provider value={{ page, navigate }}>
      <DashboardNavbar
        onSideBarOpen={() => setSidebarOpen(!isSidebarOpen)}
        open={isSidebarOpen}
      />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
      <Box
        className="basePage"
        sx={{
          flex: "1 1 auto",
          maxWidth: "100%",
          position: "absolute",
          height: "100%",
          width: "100%",
          paddingTop: paddingTop,
          paddingLeft: isSidebarOpen ? drawerLeft : lgUp ? "64px" : "0px",
        }}
      >
        {children}
      </Box>
    </NavigateContext.Provider>
  );
};

export default function (props) {
  return <DashboardLayout>{props.children}</DashboardLayout>;
}
