import React from 'react';
import { DataGridPremium, GridColDef} from '@mui/x-data-grid-premium';

type PayPeriodShowCommissionDataGridProps = {
    children?: React.ReactNode;
    saleCommissionData: any;
}

const PayPeriodShowCommissionDataGrid = ({saleCommissionData} : PayPeriodShowCommissionDataGridProps)  => {
    const columns= React.useMemo<GridColDef<any>[]>( () => [
        { field: 'hostDisplayName', headerName: 'Host', width: 200
        },
        { field: 'totalCommission', headerName: 'Commission', width: 150, groupable: false,
            valueGetter: (value) => {
                return "$" + value
              },
        },
        { field: 'totalSales', headerName: '# of Sales', width: 150, groupable: false},
    ],[])
    return(
        <>
           <DataGridPremium 
                rows={saleCommissionData}
                columns={columns}
                getRowId={(row) => row.hostId}
                />
        </>
    )
}
export default PayPeriodShowCommissionDataGrid;