import "../../Hamburger.css";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import BulkAddCards from "./BulkAddCardsModal";
import "../singles.css";
import React from "react";

interface ToolbarProps {
  type: string;
  user: any;
  rows: any;
  setRows: any;
  cols: any;
}

const BulkCardsToolbar: React.FC<ToolbarProps> = (props: any) => {
  const [cardsToAdd, setCards] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const { type, rows, setRows, cols } = props;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <BulkAddCards
        cardsToAdd={cardsToAdd}
        setCards={setCards}
        type={type}
        open={open}
        setOpen={setOpen}
        rows={rows}
        setRows={setRows}
        cols={cols}
      />
    </GridToolbarContainer>
  );
};

export default BulkCardsToolbar;
