import {Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Grid, FormControl, InputAdornment } from '@mui/material';
import { useCreateShowHostCommission } from '../../Contexts/ShowContexts/ShowCommissions/CreateShowHostCommissionContext';
import { useShowHosts } from '../../Contexts/ShowContexts/ShowHost/ShowHostsContext';
import ShowHostsAutocomplete from '../ShowHostComponents/ShowHostsAutocomplete';
import ModalOpenerButton from '../Common/ModalOpenerButton';
import { useShowHost } from '../../Contexts/ShowContexts/ShowHost/ShowHostContext';

type CreateShowHostComissionModalProps = {
    children?: React.ReactNode;
}

const CreateShowHostComissionModal = ({} : CreateShowHostComissionModalProps)  => {
    const {
        showHost, setHost,
        commission, setCommission,
        canCreate, 
        createShowHostCommission,
        modalOpen, setModalOpen, closeModal,
    } = useCreateShowHostCommission();
    
    const {hosts} = useShowHosts();
    const {setCreateHostModalOpen} = useShowHost();
    return(
        <>
           <Dialog
                open={modalOpen}
                onClose={setModalOpen}
           >
                <DialogTitle>Create new Show Host Commission</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField id="commission" 
                                    label="Commission" 
                                    value={commission} 
                                    variant="standard"
                                    type='number'
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                    onChange={(e: any) => {setCommission(e.target.value)}}/>
                          </Grid>
                          <Grid item xs={12}>
                            <ShowHostsAutocomplete 
                                hosts={hosts}
                                selectedHost={showHost}
                                setSelectedHost={setHost}
                                />
                                <ModalOpenerButton 
                                    text={"Create Host"}
                                    setOpen={setCreateHostModalOpen}
                                />
                          </Grid>
                          
                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    
                    <Button onClick={() => {closeModal(false)}}>Cancel</Button>
                    
                    <Button onClick={() => {createShowHostCommission()}} disabled={!canCreate}>Create</Button>
                    
                </DialogActions>
           </Dialog>
        </>
    )
}
export default CreateShowHostComissionModal;
  