import { GridSortModel } from "@mui/x-data-grid";
import { GetDefaultSort } from "./ServerSideDefaults";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";


export type SortProps = {
  localStorageKey: string;
}

export type SortContextProps = {
  sortModel: GridSortModel,
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>,
  resetSortModel: () => void
}

export const useSort = ({localStorageKey} : SortProps) => {
  
  const [sortModel, setSortModel] = useLocalStorage(localStorageKey, GetDefaultSort());
  
  const resetSortModel = () => {
    setSortModel(GetDefaultSort())
  }

  return {
    sortModel,
    setSortModel,
    resetSortModel
  } 

}




