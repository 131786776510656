import {  Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Grid, FormControl } from '@mui/material';
import { useShowSlot } from '../../Contexts/ShowContexts/ShowSlots/ShowSlotContext';

type ShowSlotModalProps = {
    children?: React.ReactNode;
}

const ShowSlotModal = ({} : ShowSlotModalProps)  => {
    const {
        showSlotName, setShowSlotName, showSlotNameValid,
        canCreate,
        createShowSlot, closeShowSlotModal,
        createShowSlotModalOpen,
    } = useShowSlot();

    return(
        <>
           <Dialog
                open={createShowSlotModalOpen}
                onClose={closeShowSlotModal}
           >
                <DialogTitle>Create new Show Slot</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField id="showslot" 
                                    label="Show Slot Name" 
                                    variant="standard"
                                    value={showSlotName} 
                                    error={!showSlotNameValid()}
                                    onChange={(e: any) => {setShowSlotName(e.target.value)}}/>
                          </Grid>
                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {closeShowSlotModal(false)}}>Cancel</Button>
                    
                    <Button onClick={() => {createShowSlot()}} disabled={!canCreate}>Create</Button>
                    
                </DialogActions>
           </Dialog>
        </>
    )
}
  
  
  export default ShowSlotModal;
  