import { Box} from '@mui/material';
import { LoginForm } from '../Components/LoginComponents/LoginForm';
import { useSnackbar } from '../Contexts/SnackbarContext/SnackbarContext';
import { useEffect } from 'react';

const LoginPage = (props) => {
    
    const {newSnackbarMessage} = useSnackbar();

    useEffect(() => {
        if(props.message){
            newSnackbarMessage("Welcome to the Login Page!","info")
        }
    },[])

    return(<Box sx={{
        width: '100%', 
        height: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}
        backgroundColor="background.dark.900"

        >
        <div>
        <LoginForm />
        </div>
        
              
    </Box>)
};
  
  
  export default LoginPage;
  