import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useShow } from '../../Contexts/ShowContexts/Shows/ShowContext';

const AddShowButton: React.FC = () => {
    const {setCreateShowModal} = useShow();
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                setCreateShowModal(true)
            }}
        >
            Add Show
        </Button>
    );
};

export default AddShowButton;