import React, { Component } from 'react';
import Select from 'react-select';
import { Button, InputGroup, InputGroupText, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";

const fetchData = new FetchModule();

export default class DisassemblyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: this.props.isOpen,
            saving: false,
            disassembly: null,
            options: [
                {value: "Parent", label: 'Parent Product'},
                {value: "SKU", label:'SKU'},
                {value: "Name", label:'Name'},
                {value: "ID", label:'Product ID'}
            ],
            reload: false,
            selected: null,
        }
        this.state.selected = this.state.options[0]
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    textChange =(event) => {
        this.props.disassembly[event.target.id] = event.target.value
        this.updateState("reload", false)
    }

    disassembleProduct = async () =>{ 
        if (!this.state.saving) {
            this.updateState("saving", true, async () => {
                var response = await fetchData.promise("/Disassembly/disassemble/"+this.props.disassembly.product.id, true, "POST", this.props.disassembly)
                var data = await response.json()
                if (data.task != "") {
                    window.open("https://inventory.dearsystems.com/Disassembly#"+data.task, '_blank').focus()
                }
                this.updateState("saving", false)
                this.props.toggle()
            })
        }
    }
    searchFor = async () => {
        if (!this.state.saving) {
            this.updateState('saving', true)
            if (this.state.selected.value == "Parent")  {
                
                var response = await fetchData.promise("/Disassembly/get/"+this.props.disassembly.product.id, true, "GET", "")
                if (response.status == 200 ) {
                    const data = await response.json()
                    this.props.disassembly.name = data.name;
                    this.props.disassembly.exists = data.exists;
                    this.props.disassembly.sku = data.sku;
                    this.props.disassembly.prodID = data.prodID;
                }
            } else {
                var value = ""
                if (this.state.selected.value == "ID") {
                    value = this.props.disassembly.prodID
                } else if (this.state.selected.value == "SKU") {
                    value = this.props.disassembly.sku
                } else if (this.state.selected.value == "Name") {
                    value = this.props.disassembly.name
                }
                var response = await fetchData.promise(
                    "/Disassembly/product/exists/"+this.props.disassembly.product.id+"/"+this.state.selected.value+"/"+value,
                    true, "GET", ""
                )
                if (response.status == 200) {
                    const data = await response.json();
                    this.props.disassembly.name = data.name;
                    this.props.disassembly.exists = data.exists;
                    this.props.disassembly.sku = data.sku;
                    this.props.disassembly.prodID = data.prodID;
                }

            }
            this.updateState("saving", false)
        }
    }
    changeSelect = (value) => {
        this.updateState("selected", value)
    }
    getBody = () => {
        return (
            <ModalBody>
                <InputGroup>
                    <Input value={this.props.disassembly.amtToDisassemble}
                        id='amtToDisassemble'
                        disabled={this.state.saving}
                        type="number"
                        onChange={this.textChange}
                    />&nbsp;boxes/cases to disassemble
                </InputGroup>
                <InputGroup>
                    <Input value={this.props.disassembly.quantityPerDisassemble}
                        disabled={this.state.saving}
                        id='quantityPerDisassemble'
                        type="number"
                        onChange={this.textChange}
                    />&nbsp;packes/boxes per box/case
                </InputGroup>
                <div style={{borderBottom:'1px', marginBottom: '10px'}}>Total packs/boxes to create</div>
                <InputGroup>
                    <InputGroupText style={{width: '22%'}}>Product ID</InputGroupText>
                    <Input 
                        disabled={this.state.saving}
                        value={this.props.disassembly.prodID}
                        id='prodID'
                        onChange={this.textChange}
                    />
                </InputGroup>                <InputGroup>
                    <InputGroupText style={{width: '22%'}}>SKU</InputGroupText>
                    <Input 
                        disabled={this.state.saving}
                        value={this.props.disassembly.sku}
                        id='sku'
                        onChange={this.textChange}
                    />
                </InputGroup>                <InputGroup>
                    <InputGroupText style={{width: '22%'}}>Name</InputGroupText>
                    <Input 
                        disabled={this.state.saving}
                        value={this.props.disassembly.name}
                        id='name'
                        onChange={this.textChange}
                    />
                </InputGroup>
                <InputGroup>
                    <Select 
                        onChange={this.changeSelect}
                        options={this.state.options} 
                        defaultValue={this.state.selected}
                    />
                    <Button onClick={this.searchFor}>Search</Button>
                </InputGroup>
                <div>Only change sku/name if the product is not found, check a product exists by searching, if it finds it
                    then the product exists and you can keep going.
                </div>
            </ModalBody>
        )
    }
    toggle =() => {
        if (!this.state.saving) {
            this.props.toggle()
        }
    }
    render() {
        if (this.props.disassembly != null) {
            return (
                <div>
                    <Modal isOpen={this.props.isOpen} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>Disassembly of {this.props.disassembly.product.name}</ModalHeader>
                        {this.getBody()}

                        <ModalFooter>
                            <Button 
                                disabled={this.state.saving}
                                onClick={this.toggle} 
                                color="danger"
                            >Cancel</Button>
                            <Button color="primary" onClick={this.disassembleProduct}
                            
                            disabled={this.state.saving}                           
                            >Disassemble</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            )
        }
        return (
            <div></div>
        )
    }

}
