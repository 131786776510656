import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, InputGroup, InputGroupText, Input} from 'reactstrap';
import {DataGrid, GridToolbar,GridActionsCellItem } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {Backdrop, CircularProgress} from '@mui/material';

import {useState, useEffect} from 'react';
import FetchModule from "../../Modules/FetchModule";
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert';
import Password from '@mui/icons-material/Password';

const fetchData = new FetchModule();

const useFetchGet = (url, type) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        fetchData.fetchResponseAuthed(url, type)
            .then((res) => res.json())
            .then((data) => setData(data));
  
    }, [url])
    return [data, setData]
  }
  
  const useFetchPromise = () => {
    return React.useCallback(
      (link, type, data) =>
        new Promise((resolve, reject) => {
          fetchData.muiFetch(link, type, data).then((response) => {
            if (response.status == 200 || response.status == 201) {
              resolve(response)
            } else {
              reject(new Error("Error when saving"))
            }
          })
        })
    )
  } 

export default function UserPage () {
    const [open, setOpen] = useState(false)
    const users = useFetchGet("/User", "GET")
    const [newUserModal, setNewUserModal] = useState(false)
    const[username, setUsername] = useState("")
    const[password, setPassword] = useState("")
    const fetchPromise = useFetchPromise()
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [newPassModal, setNewPassModal] = useState(false)
    const [newPassId, setNewPassId] = useState(0)
    const numToggle = () => setNewUserModal(!newUserModal)
    const passToggle = () => setNewPassModal(!newPassModal)
    function newUser() {
        setUsername("")
        setPassword("")
        setNewUserModal(true)
    }

    const columns = [
        {field: 'id', 
        headerName: 'ID', 
        width: 80
        },
        {field: 'username', 
        headerName: 'Username', 
        width: 200,
        },
        { field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        renderCell: ((params) => {
            var id = params.id
            return (
              <GridActionsCellItem
                icon={<Password />}
                label="Save"
                onClick={() => handleNewPassword(id)}
              />
            )
            })
        }
        
    ]
    function handleNewPassword(id) {

        setNewPassId(id)
        setPassword("")
        setNewPassModal(true)
    }

    function isDisabled() {
        if (username.length > 0 && password.length > 0) {
            return false
        }
        return true
    }
    function isNewPassDisabled() {
        if (password.length > 0) {
            return false
        }
        return true
    }
    async function saveNewPassword() {
        if (!open) {
            setOpen(true)
        var response = await fetchPromise("/User/update/"+newPassId, "PUT", {
            pw: password
        })

        if (response.status == 200) {
            setSnackbar({ children: "Updated new password", severity: 'success' });
        } else {
            setSnackbar({ children: "Failed to update password", severity: 'error' });
        }
        setNewPassModal(false)
        setOpen(false)
        }
    }
    async function createNewUser() {
        if (!open) {
            setOpen(true)
            var response = await fetchPromise("/User/new", "POST", {
                username: username,
                password: password
            })
            if (response.status == 201) {
                setSnackbar({ children: "Created new user", severity: 'success' });
            } else {
                setSnackbar({ children: "Failed to create user", severity: 'error' });
            }
            setNewUserModal(false)
            setOpen(false)
        }
    }

    function getBody() { 
        if (users != null) {
        return (
            <div style={{width: "100%", height: "95%"}}>
            <Button color="primary" onClick={newUser}>+ New User</Button>
                <div style={{width: "100%", height: "100%", display: 'flex'}}>
                    <Box
                        className="data-grid"
                        sx={{
                        height: '100%',
                        marginRight: '5px', 
                        width: '100%',
                            '& .actions': {
                                color: 'text.secondary',
                                },
                            '& .textPrimary': {
                                color: 'text.primary',
                            },
                        }}
                    >
                        <DataGrid
                            rows={users}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps:{debounceMs: 500}
                                }
                            }}
                        />
                    </Box>

                </div>
                <Modal isOpen={newUserModal} toggle={numToggle}>
                    <ModalHeader>Create new user</ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputGroupText>Username</InputGroupText>
                            <Input 
                                invalid={username.length == 0 }
                                placeholder="Enter in a username"
                                value={username} onChange={(event) => setUsername(event.target.value)}></Input>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>Password</InputGroupText>
                            <Input 
                                invalid={password.length == 0 }
                                placeholder="Enter in a password"
                                value={password} onChange={(event) => setPassword(event.target.value)}></Input>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={isDisabled()} onClick={createNewUser}>Create</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={newPassModal} toggle={passToggle}>
                    <ModalHeader>Set new password for user {newPassId}</ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputGroupText>Password</InputGroupText>
                            <Input 
                                invalid={password.length == 0 }
                                placeholder="Enter in a password"
                                value={password} onChange={(event) => setPassword(event.target.value)}></Input>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={isNewPassDisabled()} onClick={saveNewPassword}>Save Password</Button>
                    </ModalFooter>
                </Modal>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {!!snackbar && (
          <Snackbar open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={10000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        )}
            </div>

        )
        }
    }
    
    return (
        <>
            {getBody()}
        </>
    )
}
