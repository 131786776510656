import React, { Component } from 'react';
import {Button, Modal, ModalHeader, ModalFooter, Input, InputGroup} from 'reactstrap';
import './price.css';
import FetchModule from '../../Modules/FetchModule';
const fetchData = new FetchModule();

export default class PriSync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exists: false,
            urls: [],
            id: "",
            close: false,
            isOpen: false,
            index: 0,
            prodId: ""
        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }

    async componentDidMount(){
        this.setState({prodId: this.props.product.id})
        var response = await fetchData.fetchResponseAuthed("/PriSync/sync/"+this.props.product.id, 'GET')

        if(response.status == 200) {
            var body = await response.json();     
            this.setState({id: body})
            this.updateState('exists', true)
            var url = await fetchData.fetchResponseAuthed("/PriSync/urls/"+body, 'GET')
            var urlBody = await url.json(); 
            
            this.updateState('urls', urlBody)
        } else {
            this.updateState('exists', false)
        }
    }
    createRecord = async () => {
        var response = await fetchData.promise('/PriSync/newprisync', true, 'POST', this.props.product)
        if (response.status == 200) {
            this.updateState("exists", true)
            var body = await response.json();            
            this.setState({id: body})
        }
    }
    textChange = (event) => {
        let arr = this.state.urls
        arr[event.target.id] = event.target.value
        this.updateState("urls", arr)
        this.changed()
    }
    removeUrl = () => {
        let arr = this.state.urls
        arr.splice(this.state.index, 1)
        this.updateState("urls", arr)
        this.changed()
    }
    confirmDelete = () => {
        let arr = this.state.urls
        arr.splice(this.state.index, 1)
        this.updateState("urls", arr)
        this.updateState("isOpen", false)
        this.changed()
    }
    addUrl = () => {
        let arr = this.state.urls
        arr.push("")
        this.updateState("urls", arr)
        this.changed()
    }
    changed = () => {
        this.props.updateState("changed", true)
    }
    remove = (event) => {
        this.setState({index: event.target.value})
        this.setState({isOpen: !this.state.isOpen})
    }
    displayUrl = (url, index) => {
        return (
            <InputGroup>
                <Input 
                    id={index}
                    value={this.state.urls[index]}
                    placeholder='enter url to product'
                    onChange={this.textChange}
                    />
                <Button 
                    onClick={this.remove} 
                    value={index}
                    color="danger">X</Button>
            </InputGroup>
        )
    }
    getUrls = () => {
        return this.state.urls.map((url, index) => this.displayUrl(url, index))
    }
    render() {
        if (!this.state.exists) {
            return (
                <Button color='primary' onClick={this.createRecord}>Create Record</Button>
            )
        }
        return (
            <div>
                {this.getUrls()}
                <Button onClick={this.addUrl} color="primary">+</Button>
                <Modal isOpen={this.state.isOpen} toggle={this.remove} size="sm">
                    <ModalHeader toggle={this.remove}>Are you sure you want to delete?</ModalHeader>
                    <ModalFooter>
                        <Button onClick={this.confirmDelete} color='primary'>Confirm</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}