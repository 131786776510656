import React, { Component } from 'react';
import "./ToggleSwitch.css";
import Switch from "react-switch";

export default class ToggleSwitch extends Component {
  constructor(props) {
      super(props);
      this.state = {
        value: props.value,
        disabled: true,
        disableText: "disabled"
      }
  }
  
  onClick = async () => {
    var status = await this.props.fetchSetting(!this.state.value)
    if (status) {
      this.setState({value: !this.state.value})
    }
  }
  render() {
    return(
      <label>
        <span >{this.props.label}</span>
        <Switch 
          disabled={this.props.isDisabled}
          className="react-switch"
          onChange={this.onClick}
          checked={this.state.value}
        />
      </label>
    )
  }
}
