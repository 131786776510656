import React from 'react';
import { Box } from '@mui/system';
import ShowSaleDataGrid from './ShowSaleDataGrid';
import { useShowSaleTypes } from '../../Contexts/ShowSalesContexts/ShowSaleTypesContext';
import { Divider, Grid } from '@mui/material';
import ShowHostCommissionDataGrid from '../ShowCommissions/ShowHostCommissionDataGrid';
import { useShowSales } from '../../Contexts/ShowSalesContexts/ShowSaleContext';
import KpiCard from '../Common/KpiCard';
import DeleteSaleCommissionsButton from './DeleteSaleCommissionsButton';
type ShowSaleTypeTabBarProps = {
    children?: React.ReactNode;
}

const ShowSaleTypeTabBar = ({} : ShowSaleTypeTabBarProps)  => {
    const {saleTypesLoading, saleTypesError} = useShowSaleTypes();
    
    const {currentTotalCommissions, currentSelectedCommissions} = useShowSales();
    
    if (saleTypesLoading) return <div>Loading...</div>;
    if (saleTypesError) return <div>Error Loading</div>
    
    function renderKpiCards() {
        return Object.values(currentTotalCommissions).map(
            (commission) => {
                const selectedCommission = currentSelectedCommissions[commission.host.id];
                const randomKey = Math.random();
                if (selectedCommission) {
                    return (
                        <Grid item xs={3} key={"ShowSaleTypeTabBar-grid-"+randomKey} >
                            <KpiCard
                                key={"ShowSaleTypeTabBar-kpi-"+randomKey}
                                title={commission.host.displayName +" commission"}
                                mainText={'$' + commission.totalCommission.toFixed(2) +" total"} 
                                subText={'$' +selectedCommission.totalCommission.toFixed(2) +" selected"}
                                children={<DeleteSaleCommissionsButton hostId={commission.host.id} />}
                            />                    
                        </Grid>)
                }
            
                return <Grid item xs={3} key={"ShowSaleTypeTabBar-grid-"+randomKey}>
                                <KpiCard
                                key={"ShowSaleTypeTabBar-kpi-"+randomKey} 
                                title={commission.host.displayName +" commission"}
                                mainText={'$' + commission.totalCommission.toFixed(2)} 
                                />                    
                            </Grid>
            }
        )
    }

    return(
        <Box sx={{display: 'flex', height: '100%',flexDirection: 'column'}}>

           <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '800px'
           }}>
                <ShowSaleDataGrid />
                <Divider orientation="vertical" flexItem sx={{marginLeft: '10px', marginRight: '10px'}}/>
                <ShowHostCommissionDataGrid />
           </Box>
           <Box sx={{width: '100%', padding: '2px', marginBlock: '20px'}}>
                <Grid container spacing={2}>
                    {renderKpiCards()}
                </Grid>
                <br></br>
           </Box>

           
        </Box>
    )
}
  
  
  export default ShowSaleTypeTabBar;
  