import { PermissionRoles } from "../Components/CommonUtility/PermissionFlagsEnum"
import { useUser } from "../Components/CommonUtility/UserContext"
import DevSettings from "../Dash/Settings/DevSettings"
import SettingsMenu from "../Dash/Settings/SettingsMenu"

import {Box} from '@mui/material'

const SettingsPage = () => {
    const {hasPermission} = useUser()
    return(
    <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <SettingsMenu hasPermission/>
                {hasPermission(PermissionRoles.DEV) && <DevSettings/>}
    </Box>)
}

export default SettingsPage;
