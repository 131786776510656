import { DataGridPro, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import * as React from 'react';
import useFetchPromise from '../../Components/CommonUtility/useFetchPromise';
import { useEffect, useState } from 'react';


import Button from '@mui/material/Button';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material';


const SwapDialog = (props:any) => {

    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [filterText, setFilterText] = useState('');

    const getData = useFetchPromise();

    const handleRowSelection = (row: any) => {
      setSelectedRow(row);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(event.target.value);
    };

    const handleCancel = () => {
      props.onClose();
    };

    const handleAccept = () => {
      const badGuid = props.selectedRow.publicId;
      const goodGuid = selectedRow.publicId;
      const type = props.selectedRow.cardSkuType.name;
      const payload = {
        body:{
          "replaceGuid":badGuid,
          "withGuid":goodGuid,
          "type":type
        }
      }
      getData(`/Card/swap/badsku/`, "POST", payload).then(() => {
        props.setReload(!props.reload)
        });
        props.onClose();
    };

    const columns: GridColDef[] = [
      { field: 'label', headerName: 'Label', width: 150 },
      { field: 'value', headerName: 'Value', width: 150 },
    ];

    const filteredRows: GridRowsProp = props.rows.filter((row: any) =>
      row.label.toLowerCase().includes(filterText.toLowerCase())
    );

    const onSelectionChange = (newSelection: any) => {
      if(selectedRow && newSelection.length !== 0){
        const selectedRowId = newSelection.filter((e: any)=>e!==selectedRow.id)[0];
        const selectedRowTemp = props.rows.find((row: any) => row.id === selectedRowId);
        handleRowSelection(selectedRowTemp);
      }
      else{
        const selectedRowId = newSelection[0];
        const selectedRow = props.rows.find((row: any) => row.id === selectedRowId);
        handleRowSelection(selectedRow);
      }
    }

    return (
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>Repalcing a {props.selectedRow.cardSkuType.name} that was initially "{props.selectedRow.label}" </DialogTitle>
        <DialogContent>
          <TextField
            label="Filter by Label"
            value={filterText}
            onChange={handleFilterChange}
            fullWidth
            margin="normal"
          />
          <div style={{ height: 300, width: '100%' }}>
            <DataGridPro
              rows={filteredRows}
              columns={columns}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={onSelectionChange}
              rowSelectionModel={selectedRow ? [selectedRow.id] : []}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleAccept} variant="contained" color="primary" disabled={!selectedRow}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    );
  

}




const EditDialog = (props:any)=>{

    const [val, setVal] = React.useState('');
    const [labelVal, setLabelVal] = React.useState(props.row.label);

    const getData = useFetchPromise();

    const handleValueChange = (event:any) => {
      setVal(event.target.value);
    };

    const handleLabelChange = (event:any) => {
      setLabelVal(event.target.value);
    }

    const handleCancel = () => {
      props.setOpen(false);
    };

    const handleAccept = () => {
      const publicId = props.row.publicId;
      getData(`/Card/edit/badsku/`, "POST",
      {
        Guid: publicId,
        Body: {
          "type":props.row.cardSkuType.name,
          "value": val,
          "label": labelVal,
        },
      }).then(() => {
        props.setReload(!props.reload);
      });
      props.setOpen(false);
    };

    const verifyValue = (value:any) => {
      try{
        return props.skus.some((item:any) =>{ return item.value === value});
      }
      catch (error){
        console.error(error);
        return true;
      }
      
    }
    const hasOverlap = verifyValue(val);
    return (
      <Dialog open={props.isOpen} onClose={handleCancel}>
      <DialogTitle>Edit Dialog</DialogTitle>
      <DialogContent>
        <p>Type: {props.row.cardSkuType.name}</p>
        <TextField label="New label" value={labelVal} onChange={handleLabelChange} autoComplete='off'/>
        <p>Has Ovelap?: {!hasOverlap ? <span style={{ color: 'green' }}>No</span> : <span style={{ color: 'red' }}>Yes</span>}</p>
        <TextField label="New mapping" value={val} onChange={handleValueChange} autoComplete='off'/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleAccept} disabled={(hasOverlap) || (val==="")} variant="contained" color="primary">Accept</Button>
      </DialogActions>
      </Dialog>
    );
  
}










const SkuCorrection: React.FC = () => {
  const getData = useFetchPromise();
  const [badRows,setBadRows] = React.useState<any>([]);
  const [rows, setRows] = React.useState<any>([]);
  const [allSkus, setAllSkus] = React.useState<any>({});
  const [activeType, setActiveType] = React.useState<string>("Set");
  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [isEditDialog, setIsEditDialog] = React.useState(false);
  const [isSwapDialog, setIsSwapDialog] = React.useState(false);

  const [reload, setReload] = React.useState(false);

  useEffect(() => {
    const fetchBadData = async () => {
      const rawResponse = await getData("/Card/get/badskus", "GET");
      const data = await rawResponse.json();
      setBadRows(data);
    };

    fetchBadData();

    const fetchData = async () => {
      const rawResponse = await getData("/Card/all/skuitems", "GET");
      const data = await rawResponse.json();
      setAllSkus(data);
    };

    fetchData();
  }, [reload]);

  const badColumns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <Button size = "small" variant="contained" color="primary" style={{ marginRight: '10px', padding: '5px 10px', fontSize: '0.75rem' }} onClick={()=>{setIsEditDialog(true);setSelectedRow(params.row)}}>
            New
          </Button>
          <Button size = "small" variant="contained" color="secondary" style={{padding: '5px 10px', fontSize: '0.75rem'}}onClick={()=>{
            setIsSwapDialog(true);
            setSelectedRow(params.row)
            setActiveType(params.row.cardSkuType.name);
            }}>
            Replace
          </Button>
        </>
      ),
    },
    { 
      field: 'type', 
      headerName: 'Type', 
      width: 110,
      valueGetter: (params) => params.row.cardSkuType.name,
    },
    { field: 'label', headerName: 'Label', width: 150,
      renderCell: (params) => {
        const parts = params.value.split('  ');
        return (
          <div style={{ whiteSpace: 'pre' }}>
            {parts.map((part: any, index: number) => (
              <>
                {part}
                {index < parts.length - 1 && (
                  <span style={{ backgroundColor: 'yellow' }}>{'  '}</span>
                )}
              </>
            ))}
          </div>
        );
      },
    },
    {
      field:'doubleSpace',
      headerName: 'has double space',
      width: 100,
      valueGetter: (params) => params.row.label.includes('  ') ? 'Yes' : 'No',
    },
    {field: "Leading Space", headerName: 'Leading Space', width: 150, valueGetter: (params) => {
      if(params.row.label[0] === ' '){
        return 'Yes';
      }
      return 'No';
    }},
    {field: "Trailing Space", headerName: 'Trailing Space', width: 150, valueGetter: (params) => {
      if(params.row.label[params.row.label.length-1] === ' '){
        return 'Yes';
      }
      return 'No';
    }}

    
  ];

  const columns: GridColDef[] = [
    { 
      field: 'type', 
      headerName: 'Type', 
      width: 110,
      valueGetter: ()=> {return activeType},
    },
    { field: 'label', headerName: 'Label', width: 250 },
    { field: 'value', headerName: 'Value', width: 100 },
    
  ];


  const handleButtonClick = (type: string) => {
    setActiveType(type);
  };

  useEffect(() => {
    const fetchBadData = async () => {
      const rawResponse = await getData("/Card/get/badskus", "GET");
      const data = await rawResponse.json();
      setBadRows(data);
    };

    fetchBadData();

    const fetchData = async () => {
      const rawResponse = await getData("/Card/all/skuitems", "GET");
      const data = await rawResponse.json();
      setAllSkus(data);
    };

    fetchData();

  }, []);

  useEffect(() => {
    const copy = {...allSkus};
    if(Object.keys(copy).length === 0){
      return;
    }
    
    const newStuff  = copy[activeType].items.map((item: any, index: number) => {
      return { ...item, id: index + 1 };
    })
    setRows(newStuff );
  }, [activeType, allSkus]);

  const searchBox = (event:any)=>{
    const val = event.target.value;

    const copy = {...allSkus};
    if(Object.keys(copy).length === 0){
      return;
    }
    
    let newStuff  = copy[activeType].items.map((item: any, index: number) => {
      return { ...item, id: index + 1 };
    })
    newStuff = newStuff.filter((item:any) => {return item.label.toLowerCase().includes(val.toLowerCase())})
    setRows(newStuff );
  }

  const handleRowSelection = (row: any) => {
    if(row === null || row === undefined || row?.length === 0){return;}    

    const selectedRow = badRows.find((item:any) => item.id === row[0])

    

    const selectedType = selectedRow.cardSkuType.name;


    if(selectedType){
      setActiveType(selectedType);
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      
      {isEditDialog && <EditDialog reload = {reload} setReload = {setReload} isOpen={isEditDialog} setOpen={setIsEditDialog} row={selectedRow} skus = {allSkus[selectedRow.cardSkuType.name].items}/>}
      {isSwapDialog && <SwapDialog reload = {reload} setReload = {setReload} open={isSwapDialog} onClose={()=>{setIsSwapDialog(false)}} rows={rows} selectedRow={selectedRow}/>}
      <Box style={{ height: 'calc(100vh - 100px)', width: '50%', padding: '20px',border:'1px solid grey' }}>
        <div style={{ display: 'flex', justifyContent: 'center'}} >Bad SKUs</div>
        <DataGridPro rows={badRows} columns={badColumns} onRowSelectionModelChange={handleRowSelection} />
      </Box>

      <Box  style={{ height: 'calc(100vh - 100px)', width: '45%', padding: '20px',border:'1px solid grey' }}>
        
            <div style={{ display: 'flex', justifyContent: 'center'}} >Good SKUs for reference</div>
          
            <div>
              <TextField label="Search" onChange={searchBox}/>
            </div>
            <div>
              <Button variant={activeType === 'Year' ? 'contained' : 'outlined'} onClick={() => handleButtonClick('Year')}>Year</Button>
              <Button variant={activeType === 'Manufacturer' ? 'contained' : 'outlined'} onClick={() => handleButtonClick('Manufacturer')}>Mfgr</Button>
              <Button variant={activeType === 'Grade' ? 'contained' : 'outlined'} onClick={() => handleButtonClick('Grade')}>Grade</Button>
              <Button variant={activeType === 'Series' ? 'contained' : 'outlined'} onClick={() => handleButtonClick('Series')}>Series</Button>
              <Button variant={activeType === 'Set' ? 'contained' : 'outlined'} onClick={() => handleButtonClick('Set')}>Set / Variety</Button>
              <Button variant={activeType === 'League' ? 'contained' : 'outlined'} onClick={() => handleButtonClick('League')}>League</Button>
            </div>
              <DataGridPro style={{paddingBottom:'100px'}} rows={rows} columns={columns} />
            </Box>
  </div>
  );
};

export default SkuCorrection;