import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

type ShowHostMultiSelectProps = {
    hosts: ShowHost[],
    selectedHost?: (ShowHost[] ),
    setSelectedHost: (value: ShowHost[]) => void
}

const ShowHostMultiSelect = ({hosts, selectedHost, setSelectedHost} : ShowHostMultiSelectProps)  => {
  return (
    <Autocomplete
      multiple
      id="showhosts-autcomplete"
      value={selectedHost}
      onChange={(e: any, newValue: ShowHost[]) => {setSelectedHost(newValue)}}
      options={hosts}
      getOptionLabel={(option: ShowHost) => option.displayName}
      isOptionEqualToValue={(option: ShowHost, value: ShowHost) => option.publicId === value.publicId}
      renderInput={(params) => {
        return (
        <TextField
          {...params}
          variant="standard"
          label="Show Host"
        />
      )
    }
    
    }
    />
  );
}
export default ShowHostMultiSelect;