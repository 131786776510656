import { DataGridPro, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useShowHosts } from '../../Contexts/ShowContexts/ShowHost/ShowHostsContext';
import ShowHostDatagridToolbar from './ShowHostDatagridToolbar';
import { ShowHostProvider } from '../../Contexts/ShowContexts/ShowHost/ShowHostContext';
import React from 'react';
import { UpdateHost } from '../../Contexts/ShowContexts/ShowHost/ShowHostAPICalls';
import { useSnackbar } from '../../Contexts/SnackbarContext/SnackbarContext';
import { useUser } from '../CommonUtility/UserContext';
type ShowHostDatagridProps = {
    children?: React.ReactNode;
}
/* 
    ShowHostDatagrid component must be wrapped by a ShowHostsProvider to work properly
*/

const ShowHostDatagrid = ({} : ShowHostDatagridProps)  => {

    const {newSnackbarMessage} = useSnackbar();
    const {user} = useUser();
    const {hosts, updateHost, setHosts } = useShowHosts();
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      };
    
      const handleSaveClick = (id: GridRowId) => async () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      };
    
      const handleDeleteClick = (id: GridRowId) => () => {
        setHosts(hosts.filter((row) => row.publicId !== id));
      };
    
      const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

      };
      
    const processRowUpdate = async (newRow: ShowHost, oldRow: ShowHost) => {
        const response = await UpdateHost(newRow, user);
        if (response.status == 200) {
            setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.View } });
            newSnackbarMessage("Updated new host successfully", 'success');
            updateHost(newRow)
        } else if (response.status == 409) {
            const result = await response.json();
            newSnackbarMessage("Error: " + result.message, 'error');
            return oldRow
        } else {
            newSnackbarMessage("Error when updating new ShowHost", 'error');
            return oldRow
        }
        return newRow;
      };

      const handleProcessRowUpdateError = React.useCallback((error) => {
        newSnackbarMessage(error.message, 'error');
      }, []);

      const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
      };
    
    const columns: GridColDef[] = [
        //{ field: 'publicId', headerName: 'Public Id', width: 200 },
        { field: 'firstName', headerName: 'First Name', width: 200, editable: true },
        { field: 'lastName', headerName: 'Last Name', width: 200, editable: true },
        { field: 'displayName', headerName: 'Display Name', width: 200, editable: true },
        { field: 'isActive', headerName: 'Active', width: 200, type: 'boolean', editable: true },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                  return [
                    <GridActionsCellItem
                      key={"ShowHostDatagrid-Save-"+id}
                      icon={<SaveIcon />}
                      label="Save"
                      sx={{
                        color: 'primary.main',
                      }}
                      onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                      key={"ShowHostDatagrid-Cancel-"+id}
                      icon={<CancelIcon />}
                      label="Cancel"
                      className="textPrimary"
                      onClick={handleCancelClick(id)}
                      color="inherit"
                    />,
                  ];
                }
        
                return [
                  <GridActionsCellItem
                    key={"ShowHostDatagrid-Edit-"+id}
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                  />,
                  <GridActionsCellItem
                    key={"ShowHostDatagrid-Delete-"+id}
                    icon={<DeleteIcon />}
                    label="Delete"
                    disabled={true}
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                  />,
                ];
              },
        }
    ];

    return(
        <>
        <ShowHostProvider>
           <DataGridPro 
                rows={hosts}
                columns={columns}
                slots={{
                    toolbar: ShowHostDatagridToolbar
                }}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
           />
        </ShowHostProvider>
        </>
    )
}
  
  
  export default ShowHostDatagrid;
  