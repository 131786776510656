import * as React from 'react';
import {createContext, useContext} from 'react';
import useFetch from '../../../Components/CommonUtility/useFetch';
import { IShowHostComission } from './IShowHostCommission';
import { useSingleShow } from '../SingleShow/SingleShowContext';


const ShowHostComissionsContext = createContext<ShowHostComissionsContextProps | null>(null);
interface ShowHostComissionsContextProps {
    showHostCommissions: IShowHostComission[],
    setShowHostCommissions: (show: any) => void,
    shcLoading: boolean,
    shcError: boolean,
    addShowHostCommission: (showHostCommission: IShowHostComission) => void,
    setSelectedShowHostCommissions: (showHosts: any) => void,
    selectedShowHostCommissions: any[]
}

type ShowHostComissionsProviderProps = {
    children?: React.ReactNode;
    showHostEndpoint?: string;
}

export const ShowHostComissionsProvider = ({children} : ShowHostComissionsProviderProps) => {
    const {show} = useSingleShow();

    const [selectedShowHostCommissions, setSelectedShowHostCommissions] = React.useState([]);

    const {data: showHostCommissions, setData: setShowHostCommissions, loading: shcLoading, error: shcError}: 
        { data: IShowHostComission[], setData: any, loading: boolean, error: any }
        = useFetch("/ShowSale/commissions/showhost/"+show?.publicId, true, [])

    const contextValues = {
        showHostCommissions, setShowHostCommissions, shcLoading, shcError, addShowHostCommission,
        setSelectedShowHostCommissions, selectedShowHostCommissions
    }

    function addShowHostCommission(showHostCommission: IShowHostComission) {
        setShowHostCommissions([...showHostCommissions, showHostCommission])
    }
    
    return (
        <ShowHostComissionsContext.Provider value={contextValues}>
            {children}
        </ShowHostComissionsContext.Provider>
        )
}

export function useShowHostComissions() {
    const context = useContext(ShowHostComissionsContext);
    if (!context) {
        throw new Error("Invalid ShowHostComissionsContext, must be wrapped in a ShowHostComissionsProvider")
    }
    return context;
}