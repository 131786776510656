import * as React from 'react';
import {createContext, useContext} from 'react';
import FetchModule from '../../Modules/FetchModule';

const FeatureFlagContext = createContext<FeatureFlagContextProps | null>(null);
interface FeatureFlagContextProps {
    flags: any,
    setFlags: React.Dispatch<any>,
    updateFlags: () => void
}

type FeatureFlagProviderProps = {
    children?: React.ReactNode;
    defaultFlags?: any;
}

export const FeatureFlagProvider = ({children, defaultFlags} : FeatureFlagProviderProps) => {
    const [flags, setFlags] = React.useState<any>(defaultFlags);
    const fetchData = new FetchModule();
    
    function updateFlags() {
    fetchData.fetchResponseAuthed("/Settings/featureflags","GET")
        .then(res => {
            if (res.status === 401) {
                if (window.location.pathname !== "/" && window.location.pathname !== "/dashboard/barcode" && window.location.pathname !== "/dashboard/login"  ) {
                    window.location.pathname = "/dashboard/login";
                }
            } else {
                res.json().then(data => {
                    setFlags(data);
                });
            }
        })
    }
    const contextValues = {
        flags: flags,
        updateFlags: updateFlags,
        setFlags: setFlags
    }

    return (
        <FeatureFlagContext.Provider value={contextValues}>
            {children}
        </FeatureFlagContext.Provider>
        )
}

export function useFeatureFlags() {
    const context = useContext(FeatureFlagContext);
    if (!context) {
        throw new Error("Invalid FeatureFlagContext, must be wrapped in FeatureFlagProvider")
    }
    return context;
}