import {Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, Grid, FormControl } from '@mui/material';
import { useCreatePayPeriod } from '../../Contexts/PayPeriodContexts/CreatePayPeriodContext';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LocalizationProvider as DateProvider } from '@mui/x-date-pickers';
import { AdapterDayjs as DateAdapater } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker} from '@mui/x-date-pickers';

type PayPeriodModalProps = {
    children?: React.ReactNode;
}

const PayPeriodModal = ({}:PayPeriodModalProps)  => {
    const {
        canCreate,
        payoutDate, setPayoutDate,
        days, setDays,
        createPayPeriod,
        createPayPeriodModalOpen, closeCreatePayPeriodModal
    } = useCreatePayPeriod();
    
    return(
        <>
           <Dialog
                open={createPayPeriodModalOpen}
                onClose={closeCreatePayPeriodModal}
           >
                <DialogTitle>Create new Pay Period</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker 
                                label="Pay Period"
                                onChange={(newValue) => setDays(newValue)}
                                value={days}
                            />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <DateProvider dateAdapter={DateAdapater}>
                            <DatePicker
                                label="Payout Date"
                                value={payoutDate}
                                onChange={(newValue: any) => setPayoutDate(newValue)}
                                />
                                </DateProvider>
                          </Grid>
                          
                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {closeCreatePayPeriodModal(false)}}>Cancel</Button>
                    
                    <Button onClick={() => {createPayPeriod()}} disabled={!canCreate}>Create</Button>
                    
                </DialogActions>
           </Dialog>
        </>
    )
}
export default PayPeriodModal;
  