import { Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, Grid, FormControl, TextField } from '@mui/material';
import { useShow } from '../../Contexts/ShowContexts/Shows/ShowContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PlatformAutoComplete from '../PlatformComponents/PlatformAutocomplete';
import ShowChannelAutocomplete from '../ShowChannelComponents/ShowChannelAutocomplete';
import ShowSlotAutocomplete from '../ShowSlotComponents/ShowSlotAutocomplete';
import ShowHostMultiSelect from '../ShowHostComponents/ShowHostMultiSelect';
import { useShowHosts } from '../../Contexts/ShowContexts/ShowHost/ShowHostsContext';

type ShowModalProps = {
    children?: React.ReactNode;
}

const ShowModal = ({} : ShowModalProps)  => {
    const {
        title,
        displayTitle, setDisplayTitle,
        startTime, setStartTime,
        platform, setPlatform,
        canCreate, 
        createShow,
        resetFields,
        createShowModalOpen, closeShowModal,
        editMode, editShow,
        selectedHosts, setSelectedHosts
    } = useShow();
    const {hosts} = useShowHosts();
    return(
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

           <Dialog
                open={createShowModalOpen}
                onClose={closeShowModal}
           >
                <DialogTitle>{editMode ? "Edit" : "Create "} Show - {title}</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={5}>
                          <Grid item xs={6}>
                            <DateTimePicker label="Show Start"  value={startTime}  onChange={setStartTime}/>
                          </Grid>
                          <Grid item xs={6}>
                            <PlatformAutoComplete 
                                platform={platform}
                                setPlatform={setPlatform}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ShowChannelAutocomplete/>
                          </Grid>
                          <Grid item xs={6}>
                            <ShowSlotAutocomplete/>
                          </Grid>
                          <Grid item xs={12}>
                            <ShowHostMultiSelect hosts={hosts} selectedHost={selectedHosts} setSelectedHost={setSelectedHosts}/>
                          </Grid>
                          <Grid item xs ={12}>
                            <TextField 
                                label="Channel Display Title"
                                value={displayTitle}
                                variant="standard"
                                onChange={(e) => {setDisplayTitle(e.target.value)}}
                            />
                          </Grid>
                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {closeShowModal(false)}}>Cancel</Button>
                    {editMode ? <Button onClick={() => {resetFields()}}>Reset to Defaults</Button> : <></>}
                    {editMode ? 
                        <Button onClick={() => {editShow()}} disabled={!canCreate}>Save</Button> : 
                        <Button onClick={() => {createShow()}} disabled={!canCreate}>Create</Button>
                    }   
                </DialogActions>
           </Dialog>
           </LocalizationProvider>
        </>
    )
}
  
  
  export default ShowModal;
  