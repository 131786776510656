import FetchModule from '../../../Modules/FetchModule';
const fetchModule = new FetchModule();

export async function CreateHost(
    firstName: string, 
    lastName: string, 
    displayName: string, 
    isActive: boolean,
    user: any,
){
    const response = await fetchModule.postResponseBody("/show/host/new", "POST", 
    {
        UserId: user["id"],
        Body: {
            "FirstName": firstName,
            "LastName": lastName,
            "DisplayName": displayName,
            "IsActive": isActive
        }
    })
    return response    
}

export async function UpdateHost(host: Partial<ShowHost>, user: any) {
    const response = await fetchModule.postResponseBody("/show/host/update", "PUT", 
    {
        UserId: user["id"],
        Body: {
            "PublicId": host.publicId,
            "FirstName": host.firstName,
            "LastName": host.lastName,
            "DisplayName": host.displayName,
            "IsActive": host.isActive
        }
    })
    return response
}
