import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import { CancelOutlined } from "@mui/icons-material";
import { CheckCircleOutline } from "@mui/icons-material";
import "./RepackViewer.css";

interface RepackStatProps {
  totalRepacks: number;
  typeCounts: Record<string, number>; 
}

const InfoGrid: React.FC<RepackStatProps> = ({
  totalRepacks,
  typeCounts,
}) => {

  const renderIcon = (value: number) => {
    if (value > 20) {
      return <CheckCircleOutline color="success" />;
    } else if (value > 10) {
      return <WarningIcon color="warning" />;
    } else {
      return <CancelOutlined color="error" />;
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Grid container alignItems="center">
              <Grid item className="icon-container">
                {renderIcon(totalRepacks)}
              </Grid>
              <Grid item className="grid-item">
                <Typography variant="h6">Total Repacks</Typography>
                <Typography variant="subtitle1">{totalRepacks}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {Object.entries(typeCounts).map(([type, count]) => (
            <Grid key={type} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Grid container alignItems="center">
                <Grid item className="icon-container">
                  {renderIcon(count)}
                </Grid>
                <Grid item className="grid-item">
                  <Typography variant="h6">Remaining {type}</Typography>
                  <Typography variant="subtitle1">{count}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InfoGrid;
