import React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
import { CreatePlatform } from './PlatformAPICalls';
import PlatformModal from '../../../Components/PlatformComponents/PlatformModal';

const PlatformContext = createContext<PlatformContextProps | null>(null)
interface PlatformContextProps {
    platformName: string, setPlatformName: (name: string) => void, 
    platformNameValid: () => boolean,
    createPlatform: () => void,
    platformShorthand: string, setPlatformShorthand: (name: string) => void,
    platformShorthandValid: () => boolean,
    clearData: (name: boolean) => void,
    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createPlatformModalOpen: boolean, 
    setCreatePlatformModal: (name: boolean) => void,
    closePlatformModal: (name: boolean) => void,
}

type PlatformProviderProps = {
    children?: React.ReactNode;
}

export const PlatformProvider = ({children} : PlatformProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();

    const [platformName, setPlatformName] = useState(""); 
    const [platformShorthand, setPlatformShorthand] = useState("");
  
    const [canCreate, setCanCreate] = useState(false);
    const [createPlatformModalOpen, setCreatePlatformModal] = useState(false);
    const {user} = useUser();
    
    async function createPlatform() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreatePlatform(platformName, platformShorthand, user);
                if (response.status == 200) {
                    const data = await response.json();
                    newSnackbarMessage("Successfully created Platform", 'success');
                    if (createPlatformModalOpen) {
                        setCreatePlatformModal(false);
                        clearData();
                        return data;
                    }
                } else if (response.status == 409) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating platform, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating platform, connection invalid please try again later", 'error');
            }
            setCanCreate(true)
        }
    }
    // Update the canCreate state based on the input fields for validation reasons
    useEffect(() => {
        if (platformNameValid() && platformShorthandValid()) {
            setCanCreate(true)
        } else {
            setCanCreate(false)
        }
    }, [platformName, platformShorthand])

    function clearData() {
        setPlatformName("");
        setPlatformShorthand("");
    }
    function closePlatformModal() {
        setCreatePlatformModal(false);
        setCanCreate(false);
        clearData();
    }

    function platformNameValid() {
        return platformName.length > 0;
    }
    function platformShorthandValid() {
        return platformShorthand.length > 0;
    }
    const contextValues: PlatformContextProps = {
        platformName, setPlatformName, platformNameValid,
        platformShorthand, setPlatformShorthand, platformShorthandValid,
        canCreate, setCanCreate, clearData,
        createPlatform,
        createPlatformModalOpen, setCreatePlatformModal, closePlatformModal
    }
    return (
        <PlatformContext.Provider value={contextValues}>
            <PlatformModal/>
            {children}
        </PlatformContext.Provider>
        )
}

export function usePlatform() {
    const context = useContext(PlatformContext);
    if (!context) {
        throw new Error("Invalid PlatformContext, must be wrapped in a PlatformProvider")
    }
    return context;
}