import { useEffect, useState } from "react";
import packageJson from '../../../package.json';
import { mutate } from "./MutatorMapper";
import { LocalStorageKey } from './LocalStorageEnum';
const version = packageJson.version;
// Utility function to help with using states within local storage
// so that a specific state is saved locally and persistant within a browser.
// Only works with JSON Formatted variables and not functions.
// could be editied for further functionality by using (not intended use):
// valuestore in setValue = value instanceof Function ? value(storedValue) : value;



export const verifyKeys = (keys: any[], localStorage: any) => {
  keys.forEach((key) => {
    const item = localStorage.getItem(key)
    let parsedValue = JSON.parse(item || '{}');
    if(item){
      if(!isVersioned(parsedValue)){
        parsedValue = {version: "0.1.0", data: parsedValue}
        localStorage.setItem(key, JSON.stringify(parsedValue))
        
      }
      if(parsedValue.version !== version){
        const mutatedObject = mutate(parsedValue.version, key, parsedValue.data);
        const versionedMutatedObject = {version: version, data: mutatedObject}
        localStorage.setItem(key, JSON.stringify(versionedMutatedObject))
        return versionedMutatedObject.data
      }
    }
  });
}

export const verifyLocalStorage = () => {
  return verifyKeys(Object.values(LocalStorageKey), window.localStorage)
}


const isVersioned = (value: any) => {
  if (typeof value === 'string') {
    return false;
  }
  return Object.keys(value).includes('version') && Object.keys(value).includes('data');
};

const getInitialData = (key:string, initialValue: any) => {
  try {
    const item = window.localStorage.getItem(key)
    if (item === null || item === 'undefined') {
      const versionedObject = {version: version, data: initialValue}
      window.localStorage.setItem(key, JSON.stringify(versionedObject))
      return initialValue.data;
    }
 
    const parsedValue = JSON.parse(item || '{}');
    return parsedValue.data;
  } 
  catch (error) {
    console.error('Error reading from local storage:', error);
    return initialValue;
  }
}

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState<any>(() => {
    return getInitialData(key, initialValue);
  });
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    if (!isMounted) {
      const value = getInitialData(key, initialValue);
      setStoredValue(value);
      setMounted(true);
    }
  }, [])
  
  const setValue = (value: any | ((val:any) => any )) => {
    try {
      const item = window.localStorage.getItem(key)
      const setVersion = item? JSON.parse(item).version : version;
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify({version:setVersion,data:valueToStore}));
    } catch (error) {
      console.error('Error writing to local storage:', error);
    }
  };

  return [storedValue, setValue, isMounted] as [any, (value: any | ((val: any) => any)) => void, boolean];
}