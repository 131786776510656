import { DataGridPro, GridFilterModel, GridRowId } from "@mui/x-data-grid-pro";
import { useState, useEffect, useCallback, useMemo } from "react";
import FetchModule from "../../Modules/FetchModule";
import QRCode from 'qrcode.react';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface Product {
  Id:number;
  Name:string;
  SKU:string;

}

function ManageApiDelay(value:string, delay:number) {
  
  const [val, setVal] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVal(value);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]); 

  return val;
}

export default function BarcodePage(){
  const fetchData = new FetchModule();
  const [data, setData] = useState<Product[]>([]);
  useEffect(()=>{
    fetchData
        .fetchResponseAuthed("/Product/barcodes/init", "GET")
        .then((res) => res.json())
        .then((data) => setData(data));
  },[])
  const renderCode = (sku:any)=>{
    return (<>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <QRCode value={sku} />
        <p>{sku}</p>
      </div>
    </>);
  }

  

  const [searchText, setSearchText] = useState('');
  const delaySearchTerm = ManageApiDelay(searchText, 500);

  const search = (val:string)=>{
    const url = "/Product/barcodes/"+(val === ""?"init":val.toLowerCase());
    fetchData
        .fetchResponseAuthed(url, "GET")
        .then((res) => res.json())
        .then((data) => setData(data));
  }

  useEffect(() => {
    
      search(delaySearchTerm);
    
  }, [delaySearchTerm]);

  const [filterModel] = useState<GridFilterModel>({
    items: [],
  });

  

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
     
  },  []);

  const [currentOpen, setCurrentOpen] = useState< GridRowId[]>([]);
  const chaangeCurrentOpen = useCallback(
    (newIds: GridRowId[]) => {
      setCurrentOpen(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      );
    },
    [],
  );

  
  const cols = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        type: "text",
        editable: false,
        flex:1
      },
    ];
  }, []);

  const handleRowClick = useCallback((params) => {
    const rowId = params.id;
    const newOpen = currentOpen.includes(rowId) 
      ? currentOpen.filter(id => id !== rowId)
      : [rowId];
    setCurrentOpen(newOpen);
  }, [currentOpen]);

  return(
  <div style={{ height: '100vh', width: '100%' }}>
    <input type="text" value={searchText} onChange={handleSearchChange} placeholder="Search..." />
    <DataGridPro
     rows={data as any[]} 
     columns={cols as any} 
     filterModel={filterModel}
     getDetailPanelContent={({ row }) => {
      return renderCode(row.sku)
    }}
     getDetailPanelHeight={() => 'auto'}
     detailPanelExpandedRowIds={currentOpen}
     onDetailPanelExpandedRowIdsChange={chaangeCurrentOpen}
     onRowClick={handleRowClick}
     getRowHeight={() => 'auto'}
     sx={{
      '& .MuiDataGrid-row': {
        cursor: 'default'
      },
    }}
      slots={{
        detailPanelExpandIcon: ExpandMore,
        detailPanelCollapseIcon: ExpandLess,
      }}
     />
     
  </div>)
}