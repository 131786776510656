import { Box } from '@mui/material';
import Repack from '../Dash/RepackPage/RepackPage/RepackPage';
import { BuildRepacksProvider } from '../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext';

const RepackPage = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        ><BuildRepacksProvider>
        <Repack/>
        </BuildRepacksProvider>

    </Box>
  );
  
  
  export default RepackPage;