import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import FetchModule from "../../../Modules/FetchModule";

const fetchData = new FetchModule();

const useFetchGetSet = (url: string, type: string) => {
  const [data, setData] = useState<string[] | null>(null);
  useEffect(() => {
    fetchData
      .fetchResponseAuthed(url, type)
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch((error) => {
        // Handle errors here if needed
        console.error("Error fetching data:", error);
      });
  }, [type, url]);

  // Return both data and setData
  return [data, setData] as [string[] | null, React.Dispatch<React.SetStateAction<string[] | null>>];
};

const ViewChecklistButton: React.FC = ({}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>("");
  const [checklists, ] = useFetchGetSet("/Checklist/all", "GET");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filteredChecklists = checklists?.filter((checklist) =>
    checklist.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>View All Uploaded Checklists</DialogTitle>
        <DialogContent>
          <TextField
            label="Filter Checklists"
            variant="outlined"
            fullWidth
            margin="normal"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            <List>
              {filteredChecklists?.map((checklist, index) => (
                <ListItem key={index}>
                  <ListItemText primary={checklist} />
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen}>View Checklists</Button>
    </>
  );
};

export default ViewChecklistButton;
