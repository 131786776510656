import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

type ShowHostsAutocompleteProps = {
    hosts: ShowHost[],
    selectedHost?: (ShowHost | null),
    setSelectedHost: (value: ShowHost | null) => void
}

const ShowHostsAutocomplete = ({hosts, selectedHost, setSelectedHost} : ShowHostsAutocompleteProps)  => {
  return (
    <Autocomplete
      id="showhosts-autcomplete"
      value={selectedHost}
      onChange={(e: any, newValue: ShowHost | null) => {setSelectedHost(newValue)}}
      options={hosts}
      getOptionLabel={(option: ShowHost) => option.displayName}

      renderInput={(params) => {
        return (
        <TextField
          {...params}
          variant="standard"
          label="Show Host"
        />
      )
    }
    
    }
    />
  );
}
export default ShowHostsAutocomplete;