import React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
import { useShowHostComissions } from './ShowHostComissionsContext';
import { CreateShowHostCommission } from './ShowComissionAPICalls';
import { useSingleShow } from '../SingleShow/SingleShowContext';
import { ShowHostsProvider } from '../ShowHost/ShowHostsContext';
const CreateShowHostCommissionContext = createContext<CreateShowHostCommissionProps | null>(null)
interface CreateShowHostCommissionProps {
    showHost: ShowHost | null, setHost: (name: ShowHost | null) => void,
    commission: number, setCommission: (name: number) => void,
    commissionType: string, setCommissionType: (name: string) => void,

    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createShowHostCommission: () => void,
    clearData: (name: boolean) => void,
    modalOpen: boolean, 
    setModalOpen: (name: boolean) => void,
    closeModal: (name: boolean) => void,
}

type CreateShowHostCommissionProviderProps = {
    children?: React.ReactNode;
}

export const CreateShowHostCommissionProvider = ({children} : CreateShowHostCommissionProviderProps) => {
    // Might not need if we want the host modal to be somewhere else
    const {newSnackbarMessage} = useSnackbar();
    const { addShowHostCommission } = useShowHostComissions();
    const {show} = useSingleShow()
    const {user} = useUser();

    const [showHost, setHost] = useState<ShowHost | null>(null); 
    const [commission, setCommissionValue] = useState(0); 
    const [commissionType, setCommissionType] = useState("Percentage"); 
    const [canCreate, setCanCreate] = useState(true); 
    const [modalOpen, setModalOpen] = useState(false); 

    
    async function createHost() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreateShowHostCommission(show, showHost, commission, user);
                if (response.status == 200) {
                    const data = await response.json();
                    addShowHostCommission(data);
                    newSnackbarMessage("Successfully created new Host Commission", 'success');
                    if (modalOpen) {
                        setModalOpen(false);
                        clearData();
                    }
                } else if (response.status == 409) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating Host commission, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating Host commission, connection invalid please try again later", 'error');
            }

            setCanCreate(true)
        }
    }
    // Update the canCreate state based on the input fields for validation reasons
    useEffect(() => {
        if (hostValid()) {
            setCanCreate(true)
        } else {
            setCanCreate(false)
        }
    }, [showHost])

    function clearData() {
  
    }
    function closeModal() {
        setModalOpen(false);
        clearData();
    }

    function hostValid() {
        return showHost != null;
    }
    function setCommission(value: number) {
        if (value < 0) {
            setCommissionValue(0)
        } else {
            setCommissionValue(value)
        }
    }

    const contextValues: CreateShowHostCommissionProps = {
        showHost, setHost,
        commission, setCommission,
        commissionType, setCommissionType,
        canCreate, setCanCreate,
        createShowHostCommission: createHost,
        clearData,
        modalOpen, setModalOpen, closeModal,
    }
    return (
        <CreateShowHostCommissionContext.Provider value={contextValues}>
            <ShowHostsProvider>
                {children}
            </ShowHostsProvider>
        </CreateShowHostCommissionContext.Provider>
        )
}

export function useCreateShowHostCommission() {
    const context = useContext(CreateShowHostCommissionContext);
    if (!context) {
        throw new Error("Invalid CreateShowHostCommissionContext, must be wrapped in a CreateShowHostCommissionProvider")
    }
    return context;
}