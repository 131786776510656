interface DashboardConfig {
    order: string[];
    visibility: {
      [key: string]: boolean;
    };
    width: {
      [key: string]: number;
    };
  }
  
  export default function DashboardDefault(): DashboardConfig {
    return {
      order: [
        "actions", "inventoryId", "purchase", "received", "player", "year",
        "manufacturer", "series", "setVariety", "attributes", "serial", "grade",
        "gradeSent", "gradeRec", "certNumber", "cardNumber", "league", "cost",
        "fees", "gradingFees", "totalCost", "comp", "vendor", "teams", "description",
        "purchasedBy", "initials", "status", "flagged","isDear", "sku", "name"
      ],
      visibility: {
        id: false,
        player: true,
        purchase: true,
        received: true,
        year: true,
        manufacturer: true,
        series: true,
        setVariety: true,
        attributes: true,
        serial: true,
        grade: true,
        cardNumber: true,
        league: true,
        cost: true,
        fees: true,
        gradingFees: true,
        totalCost: true,
        comp: true,
        vendor: true,
        onFloor: true,
        sku: false,
        name: false,
        initials: true,
        status: true,
        isDear: true,
        gradeRec: true,
        gradeSent: true,
        purchasedBy: true,
        certNumber: true,
        inventoryId: true,
        description: true,
        flagged: true,
        teams: true,
      },
      width: {
        id: 50,
        inventoryId: 50,
        purchase: 90,
        received: 90,
        year: 90,
        manufacturer: 70,
        series: 100,
        attributes: 70,
        serial: 70,
        grade: 100,
        cardNumber: 70,
        league: 70,
        setVariety: 100,
        cost: 90,
        fees: 90,
        comp: 90,
        gradingFees: 90,
        totalCost: 90,
        vendor: 100,
        status: 100,
        uploadedPantheon: 100,
        productId: 100,
        sku: 100,
        name: 100,
        description: 100,
        actions: 100,
        initials: 70,
        players: 100,
        player: 50,
        teams: 50,
        isDear: 50,
        gradeRec: 90,
        gradeSent: 90,
        purchasedBy: 90,
        certNumber: 90,
        flagged:50
      }
    };
  }
  