import React, { useState } from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { useGridApiContext } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();
const AutocompleteAttributes = (props) => {
  const apiRef = useGridApiContext();
  const { id, field, options, isFilter, filterModel,setSelectedAttributeValue } = props;
  const [value, setValue] = useState(props.value);
  const [dataFields] = useState(options);
  const [renderedText, setRenderedText] = useState(props.value);
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = async (event, newValue) => {
    if (filterModel && filterModel.items) {
      const updatedFilterModel = {
        ...filterModel,
        items: filterModel.items?.map((item) => {
          if (item.field === field) {
            return {
              ...item,
              value: newValue ? newValue.label : "",
            };
          }
          return item;
        }),
      };
      props.setFilterModel(updatedFilterModel);
      setSelectedAttributeValue(newValue);
      setValue(newValue);
    }
    if (!filterModel || !filterModel.items) {
     
      if (newValue === null) {
        setValue({});
        return;
      }
      if (typeof newValue.value === "string") {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: newValue.value,
        });
        setRenderedText(newValue.value);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const inputValue = event.target.value;
      const options = dataFields;
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      if(inputValue === ""){
        handleChange(event, {label: "Empty", value: ""});
        event.target.blur();
      }
      else if (filtered.length > 0) {
        handleChange(event, filtered[0]);
        event.target.blur();
      }
    }
    else if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();

      const inputValue = event.target.value;
      const options = dataFields;
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filtered.length > 0) {
        handleChange(event, filtered[0]);
        event.target.blur();
      }
    }
  };

  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (params.inputValue !== "" && params.inputValue !== null && !isFilter) {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  return (
    <div className="autocomplete-container">
      <Autocomplete
        sx={{
          width: 150,
        }}
        value={
          !filterModel
            ? value
              ? value.label
              : ""
            : (dataFields && dataFields.find((a) => a.label === props.value)) ||
              null
        }
        size="small"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        filterOptions={handleFilterOptions}
        id="free-solo-dialog-demo attribute"
        options={dataFields ? dataFields : []}
        getOptionLabel={(option) => {
          if (!option) return "";
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        handleHomeEndKeys
        renderOption={(p2, option) => <li style={{fontSize: "12px", whiteSpace: "nowrap"}} {...p2}>{option.label}</li>}
        freeSolo
        PaperComponent={({ children }) => (
          <Paper style={{ border: "2px solid #5048E5", top:"-1000px"}}>{children}</Paper>
        )}
        renderInput={(params) => {
          params.inputProps.value = renderedText;
          return(
          <TextField
            {...params}
            id="grade"
            type="text"
            variant="outlined"
            size="small"
            style={{ fontSize: "14px" }}
            placeholder="Attribute"
            onChange={(e) => {setRenderedText(e.target.value)}}
          />
        )}}
      />
    </div>
  );
};

AutocompleteAttributes.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array,
};

export default AutocompleteAttributes;
