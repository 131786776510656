import FetchModule from '../../../Modules/FetchModule';
import { IPlatform } from '../Platforms/IPlatform';
const fetchModule = new FetchModule();

export async function CreateShowChannel(
    channelName: string, 
    platform: IPlatform | null,
    user: any,
){
    const response = await fetchModule.postResponseBody("/show/channel/new", "POST", 
    {
        UserId: user["id"],
        Body: {
            "platform": platform?.id,
            "channelName": channelName,
        }
    })
    return response    
}


export async function GetShowChannels(
    platformPublicId: string, 
    user: any,
){
    const response = await fetchModule.postResponseBody("/show/channels", "POST", 
    {
        UserId: user["id"],
        Body: {
            "platformPublicId": platformPublicId,
        }
    })
    return response    
}
