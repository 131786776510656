import * as React from 'react';
import {createContext, useContext} from 'react';
import { IPayPeriod } from './IPayPeriod';
import { useSnackbar } from '../SnackbarContext/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import usePaginationFiltering, { PaginationModel, SortModel } from '../../Components/CommonUtility/usePaginationFiltering';

const PayPeriodsContext = createContext<PayPeriodsContextProps | null>(null);
interface PayPeriodsContextProps {
    addPayPeriod: (PayPeriod: IPayPeriod) => void,
    updatePayPeriod: (PayPeriod: IPayPeriod) => void,
    PayPeriods: Array<IPayPeriod>,
    setPayPeriods: (hosts: Array<IPayPeriod>) => void,
    PayPeriodsLoading: boolean,
    PayPeriodsError: boolean,
    navigateToPayPeriod: (object: any) => void,
    paginationModel: PaginationModel, setPaginationModel: any, totalRows: number, 
    filteringModel: any, setFilteringModel: any,
    sortModel: SortModel, setSortModel: any, fetching: boolean
}

type PayPeriodsProviderProps = {
    children?: React.ReactNode;
}

export const PayPeriodsProvider = ({children} : PayPeriodsProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();
    const navigate = useNavigate();

    const defaultPaginationModel: PaginationModel = {page: 0, pageSize: 25} 
    const defaultFilteringModel = {items: [], quickFilterValues: []}
    const defaultSortModel: SortModel = null
    
    const {
        data: PayPeriods, 
        setData: setPayPeriods, 
        loading: PayPeriodsLoading, 
        error: PayPeriodsError,
        paginationModel, setPaginationModel,
        filteringModel, setFilteringModel,
        sortModel, setSortModel,
        fetching,
        totalRows
    }: {
        data: Array<IPayPeriod>, setData: any, loading: boolean, error: any, 
        paginationModel: PaginationModel, setPaginationModel: any, totalRows: number, 
        filteringModel: any, setFilteringModel: any,
        sortModel: SortModel, setSortModel: any, fetching: boolean
    } = usePaginationFiltering("/PayPeriod/all", null, defaultPaginationModel, defaultFilteringModel, defaultSortModel);

    function addPayPeriod(PayPeriod: IPayPeriod) {
        setPayPeriods([...PayPeriods, PayPeriod])
    }
    function updatePayPeriod(newPayPeriod: IPayPeriod) {
        const newPayPeriods: Array<IPayPeriod> = PayPeriods.map( (payPeriod: IPayPeriod) => (payPeriod.publicId === newPayPeriod.publicId) ? newPayPeriod : payPeriod)
        setPayPeriods(newPayPeriods);
        newSnackbarMessage("Updated new PayPeriod successfully", 'success');
    }
    function navigateToPayPeriod(object: any) {
        navigate("/dashboard/payperiod/"+object.publicId)
    }

    const contextValues = {
        addPayPeriod, updatePayPeriod, setPayPeriods, PayPeriods, PayPeriodsLoading, PayPeriodsError, navigateToPayPeriod,
        paginationModel, setPaginationModel,
        filteringModel, setFilteringModel,
        sortModel, setSortModel,
        fetching,
        totalRows
    }
    return (
        <PayPeriodsContext.Provider value={contextValues}>
            {children}
        </PayPeriodsContext.Provider>
        )
}

export function usePayPeriods() {
    const context = useContext(PayPeriodsContext);
    if (!context) {
        throw new Error("Invalid PayPeriodContext, must be wrapped in a PayPeriodProvider")
    }
    return context;
}