import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
  Autocomplete,
  TextField
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../../../Dash/SinglesPage/FilterComponents/DeleteConfirmationModal";
import FilterAndSaveButton from "./SaveFilter";
import "../../../../Dash/SinglesPage/FilterComponents/FilterStyles.css";
import useFetchPromise from "../../../CommonUtility/useFetchPromise";
import { useUser } from "../../../CommonUtility/UserContext";
import { FilterPresetTypeNames } from "./FilterPresetTypeNames"

interface FilterListProps {
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterModel: any;
  filterModel: any;
  deleteUrl: string; 
  saveUrl: string; 
  filterPresetName: FilterPresetTypeNames;
  filterList: any[];
}

const FilterList: React.FC<FilterListProps> = (props) => {
  const { user } = useUser();
  const fetchPromise = useFetchPromise();
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [optionToDelete, setOptionToDelete] = useState<any | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const filterList = props.filterList;
  const setFilterModel = props.setFilterModel;
  const filterModel = props.filterModel;


  const handleOpenModal = () => {
    if (filterList === undefined) {
      return;
    }
    setOptions(filterList);
    setModalOpen(true);
  };

  const handleCloseModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.outerText === "Cancel") {
      setModalOpen(false);
      return;
    }
    if (selectedOption === "Clear Selection") {
      setFilterModel(clearedFilter);
      props.setRefresh(!props.refresh);
      setModalOpen(false);
      return;
    }
    if (selectedOption?.filter !== undefined) {
      const parsedFilter = JSON.parse(selectedOption.filter);
      setFilterModel(parsedFilter);
      props.setRefresh(!props.refresh);
      setModalOpen(false);
      return;
    }
    setModalOpen(false);
  
  };

  const clearedFilter = {
    items: [],
    quickFilterValues: [],
  };

  const handleDeleteOption = (option: any) => {
    setOptionToDelete(option);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteOption = () => {
    if (optionToDelete) {
      const updatedOptions = options.filter((item) => item.publicId !== optionToDelete.publicId);
      fetchPromise(props.deleteUrl, "DELETE", 
        {
          Name: optionToDelete.name,
          Guid: optionToDelete.publicId,
          User: user["id"],
        }
      );
      setOptions(updatedOptions);
      setSelectedOption(null);
      setOptionToDelete(null);
      setDeleteConfirmationOpen(false);
    }
  };

  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        maxHeight: "100px",
        alignItems: "center",
      }}
    >
      <FilterAndSaveButton
        refresh={props.refresh}
        setRefresh={props.setRefresh}
        saveFilter={props.saveUrl}
        filterModel={filterModel}      
        filterPresetName={props.filterPresetName}
      />
      <Button style={{ fontSize: ".8251rem" }} onClick={handleOpenModal}>
        Select Premade Filters
      </Button>

      {optionToDelete && (
        <DeleteConfirmationModal
          open={deleteConfirmationOpen}
          onClose={() => setDeleteConfirmationOpen(false)}
          onDelete={confirmDeleteOption}
        />
      )}

      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm">
        <DialogTitle>Select a Filter</DialogTitle>
        <DialogContent>
          <FormControl
            style={{
              display: "inline-flex",
              margin: "20px",
              alignContent: "center",
            }}
          >
            <Autocomplete
              sx={{ width: "500px" }}
              options={options}
              value={selectedOption}
              onChange={(event, newValue: any) => {
                setSelectedOption(newValue);
              }}
              renderInput={(params) => {
                return <TextField {...params} label="Select a filter"/>
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography>{option.name}</Typography>
                  {option !== selectedOption && (
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteOption(option)}
                      style={{ marginLeft: "auto" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </li>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseModal} color="primary" disabled={selectedOption == null}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterList;
