import { useState, useRef, useEffect } from "react";
import { useUser } from "./UserContext";
import api from "../../Modules/APIModules";
const baseUrl = api.getLink()

export default function useFetch(url, getNew, defaultValue) {
  const {user} = useUser();
  const isMounted = useRef(false);
  const [data, setData] = useState(defaultValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isMounted.current = true;
    async function init() {
      try {
        const token = String(user['token']);
        const response = await fetch(baseUrl + url, {
          headers: new Headers({
            'Authorization': "Bearer " + String(token),
            'Content-Type': 'application/json',
            'user': localStorage.getItem('user')
          })
        });
        if (response.ok) {
          const json = await response.json();
          if (isMounted.current) setData(json);
        } else {
          throw response;
        }
      } catch (e) {
        if (isMounted.current) setError(e);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    }
    if (url != null) {
      init();
    }
    return () => {
      isMounted.current = false;
    };
  }, [url, getNew]);

  return { data, setData, error, loading };
}

