import React, { Component, useEffect } from 'react';
import {useLocation} from "react-router-dom";

const Observer = ({didUpdate}) => {
    const location = useLocation();
    useEffect (() => {
        didUpdate(location)
    }, [location])
    return null;
}
export default class LeftMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: "new",
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[this.props.location]
    }
    async componentDidMount() {

    }
    updated = (event) => {
        let page = event.pathname.split("/")[this.props.location+1]
        this.props.updateState("activePage", page)
    }
    onClick = (event) => {
        this.props.updateState("activePage", event.target.id, () => {
            this.props.navigate(event.target.id)
        })
    }
    getActive = (val) => {
        return this.props.activePage === val ? "active" : ""
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    goBack = () => {
        this.props.navigate(this.props.backPage)
    }
    displayNav = (item, index) => {
        return (
            <div
                key={index}
                className={this.getActive(item.link) + " dashboard-nav-left-item "}
                onClick={this.onClick}
                id={item.link}
                >{item.name}
            </div>
            
        )
    }
    getElements = () => {
        return this.props.navItems.map((item, i) => this.displayNav(item, i))
    }
    getBack = () => {
        if (this.props.backPage !== "none") {
            return (
                <button className="back-button" onClick={this.goBack}>Go Back</button>
            )
        }
    }
    render() {
        return (
            <div className={"dashboard-page-flip"}>
                <Observer value={this.props.activePage} didUpdate={this.updated}/>
                <div className="dashboard-nav topbar" 
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                >
                    {this.getBack()}
                </div>
                <div className="dashboard-nav-flip">
                    <div className="dashboard-nav-left-flip">
                        {this.getElements()}
                    </div>
                    {this.props.getBody()}
                </div>
                
            </div>
        )
    }

}
