import FetchModule from '../../../Modules/FetchModule';
const fetchModule = new FetchModule();

export async function CreatePlatform(
    platformName: string, 
    platformShorthand: string,
    user: any,
){
    const response = await fetchModule.postResponseBody("/show/platform/new", "POST", 
    {
        UserId: user["id"],
        Body: {
            "platformName": platformName,
            "platformShorthand": platformShorthand
        }
    })
    return response    
}
