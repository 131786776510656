import { Box, Divider, Typography } from '@mui/material';
import ShowHostDatagrid from '../Components/ShowHostComponents/ShowHostDatagrid';
import {ShowHostsProvider} from '../Contexts/ShowContexts/ShowHost/ShowHostsContext';


const ShowHostsPage = ()  => {
    
    
    return(
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        >
        <Typography variant="h4" sx={{marginBottom: '3px'}}>Hosts Manager Page</Typography>
        <Divider sx={{borderBottomWidth: 3}}/>
        <ShowHostsProvider>
            <ShowHostDatagrid/>
        </ShowHostsProvider>
    </Box>)
}
  
  
  export default ShowHostsPage;
  