import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';


interface ModalOpenerButtonProps {
    text: string;
    setOpen: any;
    icon?: string;
}

const ModalOpenerButton: React.FC<ModalOpenerButtonProps> = ({text, setOpen, icon}) => {
    if (icon == null || icon == undefined) {
        icon = "add";
    }
    const IconTypes: { [key: string]: JSX.Element } = {
        "add": <AddIcon />,
        "edit": <EditIcon />,
    };
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={IconTypes[icon]}
            onClick={setOpen}
        >
            {text}
        </Button>
    );
};

export default ModalOpenerButton;