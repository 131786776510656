import { Box, Divider, Typography } from '@mui/material';
import ModalOpenerButton from '../../Components/Common/ModalOpenerButton';
import { useCreatePayPeriod } from '../../Contexts/PayPeriodContexts/CreatePayPeriodContext';

const PayPeriodHeader = ()  => {
    const {setCreatePayPeriodModalOpen} = useCreatePayPeriod();

    return <>
            <Box sx={{
            width: '100%',
            height: '40px',
            display: 'flex',
            flexDirection: 'row',
            }}>
                <Typography variant="h5" sx={{marginBottom: '3px'}}>Pay Period</Typography>
                <ModalOpenerButton text="Create Pay Period" setOpen={() => setCreatePayPeriodModalOpen(true)} icon='add' />
            </Box>
            <Divider sx={{borderBottomWidth: 3}}/>
        </>
}
  
export default PayPeriodHeader;