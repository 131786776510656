import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import AddShowButton from './AddShowButton';
import AddPlatformButton from '../PlatformComponents/AddPlatformButton';
import { PlatformProvider } from '../../Contexts/ShowContexts/Platforms/PlatformContext';
import { ShowChannelProvider } from '../../Contexts/ShowContexts/ShowChannels/ShowChannelContext';
import AddShowChannelButton from '../ShowChannelComponents/AddShowChannelButton';
import { ShowSlotProvider } from '../../Contexts/ShowContexts/ShowSlots/ShowSlotContext';
import AddShowSlotButton from '../ShowSlotComponents/AddShowSlotButton';
import ShowHostQuicknavButton from '../ShowHostComponents/ShowHostQuicknavButton';
import ShowSaleDownloadModal from '../ShowSaleComponents/ShowSaleDownload';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

const ShowsDataGridToolbar: React.FC = ({}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <AddShowButton />
            <PlatformProvider>
                <AddPlatformButton />
            </PlatformProvider>
            <ShowChannelProvider>
                <AddShowChannelButton />
            </ShowChannelProvider>
            <ShowSlotProvider>
                <AddShowSlotButton />
            </ShowSlotProvider>
            <ShowHostQuicknavButton />
            <ShowSaleDownloadModal />
        </GridToolbarContainer>
        </LocalizationProvider>
    );
};

export default ShowsDataGridToolbar;