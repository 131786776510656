
interface FilterProps {
  label: any;
}

type FilterOptionsType = {
  [name: string]: any;
};

function Filter(props: FilterProps): FilterOptionsType {
  const { label } = props;
  const Operators = [
    {
        label: label["isEmpty"] ? label["isEmpty"] : "is empty",
        value: "isEmpty",
        InputComponent: null,
      },
      {
        label: label["isNotEmpty"] ? label["isNotEmpty"] : "is not empty",
        value: "isNotEmpty",
        InputComponent: null,
      },
  ];

  return {
    Filter,
    Operators,
  };
}

export default Filter;
