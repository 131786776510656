export class AutocompleteHelper {
  static verifyPath<T>(path: string, obj: T, parentPath: string): boolean {
    const flag = path.split('.').every(key => {
      if (obj && typeof obj === 'object') {
        obj = (obj as any)[key];
        return obj !== undefined;
      }
      return false;
    });
    if (!flag) {
      throw new Error(`Invalid object path: ${parentPath}`);
    }
    return flag;
  }

  static getLabel<T>(option: T, labelPath?: string): string | null {
    if (typeof option === 'string') {
      return option;
    }
    if (Array.isArray(option)) {
      return "";
    }
    if(option === null){
      return null
    }
    if (labelPath) {
      labelPath.split('.').forEach((path) => {
        if (this.verifyPath(path, option, labelPath)) {
          option = (option as any)[path];
        }
      });
      if (typeof option === 'string') {
        return option;
      } else {
        return "";
      }
    } 
    return "Unable to find a label";
  }

  static getGroup<T>(option: T, groupPath?: string): any {
    if (typeof option === 'string' || !groupPath) {
      return "";
    }
    groupPath.split('.').forEach((path) => {
      if (this.verifyPath(path, option, groupPath)) {
        option = (option as any)[path];
      }
    });
    return option;
  }

  static getLabelMultiple<T>(option: T, labelPath?: string):any[] {
    const labels: any[] = []
    if (Array.isArray(option)){

      option.forEach(e=>{
        if(typeof e == 'string'){
          labels.push(e)
        }
        else{
          labels.push(this.getLabel(e,labelPath))
        }
      })
    }
    return labels

  }

  static getLabelViaInfer<T>(option: T, labelPath?: string) {
    if(Array.isArray(option)){
      return this.getLabelMultiple(option,labelPath)
    }
    else{
      return this.getLabel(option,labelPath)
    }
  }
}