import FetchModule from '../../../Modules/FetchModule';
import { IShow } from '../Shows/IShow';
const fetchModule = new FetchModule();

export async function CreateShowHostCommission(
    Show: IShow | null, 
    ShowHost: ShowHost | null,
    Commission: number,
    user: any,
){
    const response = await fetchModule.postResponseBody("/ShowSale/ShowHostCommission/new", "POST", 
    {
        UserId: user["id"],
        ShowHostPublicId: ShowHost?.publicId,
        CommissionAmount: Commission,
        ShowPublicId: Show?.publicId,
    })
    return response    
}


export async function CreateShowSaleCommission(
    Show: IShow | null, 
    saleIds: any[],
    hostIds: any[],
    user: any,
){
    const response = await fetchModule.postResponseBody("/ShowSale/commission/new", "POST", 
    {
        UserId: user["id"],
        ShowSaleCommissionIds: saleIds,
        ShowHostComissionIds: hostIds,
        Guid: Show?.publicId,
    })
    return response    
}

export async function DeleteShowSaleCommission(
    commissionPublicId: string,
    user: any,
){
    const response = await fetchModule.postResponseBody("/ShowSale/commission", "DELETE", 
    {
        UserId: user["id"],
        Guid: commissionPublicId,
    })
    return response    
}

export async function BulkDeleteShowSaleCommission(
    showId: string,
    saleIds: any[],
    hostIds: any[],
    user: any,
){
    const response = await fetchModule.postResponseBody("/ShowSale/commission/bulk", "DELETE", 
    {
        UserId: user["id"],
        Guid: showId,
        ShowSaleCommissionIds: saleIds,
        ShowHostComissionIds: hostIds
    })
    return response    
}

export async function BulkAssignBreakIdsToShowSales(
    user: any,
    showSaleIds: any[],
    modifiedAttributes: any,
    showId: string,
) {
    const response = await fetchModule.postResponseBody("/ShowSale/assign/attributes", "PUT", 
    {
        Guid: showId,
        UserId: user["id"],
        ShowSaleIds: showSaleIds,
        ModifiedAttributes: modifiedAttributes
    })
    return response  
}