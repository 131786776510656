import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';


const PayPeriodDataGridToolbar: React.FC = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector/>
        </GridToolbarContainer>
    );
};

export default PayPeriodDataGridToolbar;