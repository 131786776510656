import React from "react";
import Button from "@mui/material/Button";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";

interface CartViewToolbarProps {
  displayType: 'cardsTable' | 'repackStorePreview';
}

export default function CartViewToolbar({ displayType }: CartViewToolbarProps) {
  const { addToSelectedBox, addToSelectedBoxTicket, clearRepackStore } = useBuildRepacks();

  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      {displayType === "repackStorePreview" ? (
        <>
          {/* disabled until I add support to select box type on this bad boy */}
          {/* <Button onClick={addToNewBox}>Add to new box</Button> */}
          <Button onClick={addToSelectedBox}>Add to selected box</Button>
          <Button className="btn" onClick={clearRepackStore}>
            Clear Selection
          </Button>
        </>
      ) : (
        <>
          {/* disabled until I add support to select box type on this bad boy */}
          {/* <Button onClick={addToNewBoxTicket}>Add to new box</Button> */}
          <Button onClick={addToSelectedBoxTicket}>Add to selected box</Button>
        </>
      )}
    </GridToolbarContainer>
  );
}