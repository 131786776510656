import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useShowSlot } from '../../Contexts/ShowContexts/ShowSlots/ShowSlotContext';

const AddShowSlotButton: React.FC = () => {
    const {setShowSlotModal} = useShowSlot();
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                setShowSlotModal(true)
            }}
        >
            Add Show Slot
        </Button>
    );
};

export default AddShowSlotButton;