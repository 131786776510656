import { Box, Card } from '@mui/material';
import ReportPage from '../Dash/PriceManager/PricePage';

const PriceManagerPage = () => (
        <Box sx={{
            width: '100%', 
            height: '200%', 
            padding: '2px'
        }}>
            <Card sx={{width: '100%', height: '100%'}}>
                <ReportPage />
            </Card>
                    
        </Box>
  );
  
  
  export default PriceManagerPage;
  