import React from 'react';
import {createContext, useContext, useState} from 'react';
import { usePayPeriods } from './PayPeriodsContext';
import { useSnackbar } from '../SnackbarContext/SnackbarContext';
import { useUser } from '../../Components/CommonUtility/UserContext';
import { CreatePayPeriod } from './PayPeriodAPICalls';
import PayPeriodModal from '../../Dash/PayPeriodPage/PayPeriodModal';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';

const CreatePayPeriodContext = createContext<CreatePayPeriodsContextProps | null>(null)
interface CreatePayPeriodsContextProps {
    payoutDate: Dayjs, setPayoutDate: (name: Dayjs ) => void,
    days: DateRange<Dayjs>, setDays: (name: DateRange<Dayjs>) => void,
    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createPayPeriod: () => void,
    clearData: (name: boolean) => void,
    createPayPeriodModalOpen: boolean, 
    setCreatePayPeriodModalOpen: (name: boolean) => void,
    closeCreatePayPeriodModal: (name: boolean) => void,
}

type CreatePayPeriodProviderProps = {
    children?: React.ReactNode;
}

export const CreatePayPeriodProvider = ({children} : CreatePayPeriodProviderProps) => {
    const {addPayPeriod} = usePayPeriods();
    const {newSnackbarMessage} = useSnackbar();

    const [payoutDate, setPayoutDate] = useState(dayjs(new Date())); 
    const [days, setDays] = useState<DateRange<Dayjs>>([dayjs(new Date()), dayjs(new Date())]);

    const [createPayPeriodModalOpen, setCreatePayPeriodModalOpen] = useState(false);
    const [canCreate, setCanCreate] = useState(true);
    const {user} = useUser();
    
    async function createPayPeriod() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreatePayPeriod(payoutDate.format("YYYY-MM-DD"), [days[0]?.format("YYYY-MM-DD"), days[1]?.format("YYYY-MM-DD")], user);
                if (response.status == 200) {
                    const data = await response.json();
                    addPayPeriod(data);
                    newSnackbarMessage("Successfully created PayPeriod", 'success');
                    if (createPayPeriodModalOpen) {
                        setCreatePayPeriodModalOpen(false);
                        clearData();
                    }
                } else if (response.status == 409) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating PayPeriod, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating PayPeriod, connection invalid please try again later", 'error');
            }

            setCanCreate(true)
        }
    }

    function clearData() {
        
    }
    function closeCreatePayPeriodModal() {
        setCreatePayPeriodModalOpen(false);
        clearData();
    }

    const contextValues = {
        canCreate, setCanCreate,
        payoutDate, setPayoutDate,
        days, setDays,
        createPayPeriod,
        clearData,
        createPayPeriodModalOpen, setCreatePayPeriodModalOpen, closeCreatePayPeriodModal
    }
    return (
        <CreatePayPeriodContext.Provider value={contextValues}>
            <PayPeriodModal />
            {children}
        </CreatePayPeriodContext.Provider>
        )
}

export function useCreatePayPeriod() {
    const context = useContext(CreatePayPeriodContext);
    if (!context) {
        throw new Error("Invalid PayPeriodContext, must be wrapped in a PayPeriodProvider")
    }
    return context;
}