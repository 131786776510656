import React, { Component } from 'react';
import {Button, InputGroup, Input, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import '../ReportManager/ProductList.css';
import './report.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";

import {faArrowUp, faArrowDown, faGripLines} from '@fortawesome/free-solid-svg-icons';
import './report.css'
import FetchModule from "../../Modules/FetchModule";
library.add(faArrowUp, faArrowDown, faGripLines);
const fetchData = new FetchModule();
export default class ReportProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wholeItem: props.wholeItem,
            originalPrice: props.wholeItem.product.PriceTier1,
            price: props.wholeItem.product.PriceTier1,
            hover: "",
            selected: "",
            average: 0,
            syncID: this.props.wholeItem.syncID,
            priceChanged: false,
            disabled: false,
            shortenedId: "",
            todayDate: 0,
            lastChangedDate: props.wholeItem.priceChangeDate,
            lastChangedBy: props.wholeItem.priceUser,
            pricesChanged: 0,
            currentValues: {},
        }

        let id = props.wholeItem.product.ID.replaceAll("-", "")
        this.state.shortenedId = id
    }

    getDate = (date, second) => {
        if (date === null) {
            return null;
        }
        var items = date.split(" ")
        var myDate = items[0].split("/")
        var dt =new  Date(myDate[2], myDate[0]-1, myDate[1]);
        var one_day = 1000 * 60 * 60 * 24
        var today = new Date();
        today.setHours(23, 59 ,59, 999);
        var days = Math.floor(today.getTime() / one_day - dt.getTime() / one_day);
        if (days === 0) {
            return "Today "+items[1]+items[2]
        } else if (days === 1) {
            return "Yesterday"
        }
        if (second) {
            return days +" days ago"
        }
        return [items[0], days];
    }
    async componentDidMount() {
        let prods = ["dacardworld.com", 
            "blowoutcards.com", 
            "steelcitycollectibles.com",
        ]
        if (this.props.wholeItem != null) {
            let avg = 0;
            let total = 0;
            prods.forEach(website => {
                if(this.props.wholeItem.syncUrls != null && this.props.wholeItem.syncUrls[website] != null) {
                    let amt = this.props.wholeItem.syncUrls[website].price
                    if (amt > 0 && this.props.wholeItem.syncUrls[website].stock) {
                        avg = avg + this.props.wholeItem.syncUrls[website].price
                        total = total + 1;
                    }
                }
            })
            if (avg > 0) {
                this.updateState("average", Math.round(avg/total * 100)/100);
            } else {
                this.updateState('average', 0)
            }

        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    mouseEnter = () => {
        this.updateState("hover", "darken") 
    }
    mouseLeave = () => {
        this.updateState("hover", "")
    }
    getPriceDate = (priceDate, priceDateText) => {
        if (priceDate == null) {
            return;
        }
        var items = priceDateText.split(',')
        if (items[1] != null) {
            priceDate = " " +items[1] + " days ago"
        }
        return (
            <div className="small-text toolTip">&#x394;{(priceDate)}
                <span className="tooltiptext">{items[0]}</span>    
            </div>
        )
    }
    getCompareToPrice = (urlToCompare) => {
        if (this.state.wholeItem.syncUrls[urlToCompare] != null) {
            let color = ''
            let comparePrice = this.state.wholeItem.syncUrls[urlToCompare].price
            let diff = Math.round((comparePrice-this.state.price) * 100)/100
            let amt = ''
            let strikeThrough = ''
            let mainText = "Price in PriSync."
            let percentageChange = ''
            let priceDate = this.getDate(this.state.wholeItem.syncUrls[urlToCompare].date, false)
            let stockDate = this.getDate(this.state.wholeItem.syncUrls[urlToCompare].dateStock, true)

            let priceDateText = "Price last updated."
            let stockDateText = "Stock updated " +stockDate;
            if (stockDate == null) {
                stockDateText = "";
            }
            if (priceDate == null) {
                priceDateText = "";
            } else if (!priceDate.includes("Today") && !priceDate.includes("Yesterday")) {
                priceDateText= "Last changed " +priceDate;
                priceDate = " days ago"
            } 
            if (comparePrice == null) {
                comparePrice = "-1"
            }
            if (this.state.price > comparePrice && comparePrice > 0) {
                color ='red'
                diff = diff * -1
                amt = "(-"+this.getPriceWithDecimals(diff)+")";
            } else if (this.state.price < comparePrice && comparePrice > 0) {
                color ='green'
                amt = "(+"+this.getPriceWithDecimals(diff)+")";
            } else {
                mainText = "No price in PriSync or URL not found."
                stockDateText = ""
            }
            if (this.state.wholeItem.oldSyncUrls !== null && this.state.wholeItem.oldSyncUrls[urlToCompare] !== null &&
                this.state.wholeItem.oldSyncUrls[urlToCompare].price !== this.state.wholeItem.syncUrls[urlToCompare].price
                ) {
                if(!this.state.wholeItem.syncUrls[urlToCompare].stock) {
                    strikeThrough = "strikethrough"
                    mainText = "Product out of stock."
                }
                let price = Math.round((comparePrice - this.state.wholeItem.oldSyncUrls[urlToCompare].price) * 100) / 100
                let icon = "grip-lines"
                let changeColor = ""
                if (price < 0) {
                    icon = "arrow-down"
                    changeColor = "red"
                    let t = Math.round(((comparePrice)/(comparePrice-price))*100)-100
                    percentageChange = "("+t+"%)"
                    price = (price * -1)
                } else if (price > 0) {
                    icon = "arrow-up"
                    let t = Math.round(comparePrice/(comparePrice-price)*100)-100
                    percentageChange = "(+"+t+"%)"
                    changeColor = "green"
                } else {
                    price = "nc"
                }
                return (
                    <td align={'left'} style={{padding: '.3rem'}}>
                        <a
                            className={"toolTip "+strikeThrough}
                            href={this.state.wholeItem.syncUrls[urlToCompare].url} 
                            referrerPolicy="noreferrer"
                            rel="noreferrer"
                            id={this.state.shortenedId+"price"}
                            target="_blank">{this.getPriceWithDecimals(comparePrice)}
                            <span className="tooltiptext">{mainText +" "+ stockDateText}</span>    
                        </a>
                        <div style={{color: color}} className="small-text toolTip"
                            >{amt}
                            <span className="tooltiptext">Price difference to ours</span>    
                        </div> 
                        {this.getPriceDate(priceDate, priceDateText)}
                        <div className="center small-text">
                            <InputGroup className="center">
                                <FontAwesomeIcon icon={icon} size='sm' />
                                <div style={{color: changeColor}} className="toolTip">&nbsp;{this.getPriceWithDecimals(price)}&nbsp;
                                    <span className="tooltiptext">Change in price</span>    
                                </div>
                                <div style={{color: changeColor}} className="toolTip">{percentageChange}
                                    <span className="tooltiptext">Percentage change in price</span>    
                                </div>
                            </InputGroup>
                        </div>
                    </td>
                )
            }
            if(!this.state.wholeItem.syncUrls[urlToCompare].stock) {
                strikeThrough = "strikethrough"
                mainText = "Product out of stock."
            }
            return (
                <td align={'left'} style={{padding: '.3rem'}}>
                    <a
                        referrerPolicy="noreferrer"
                        rel="noreferrer"
                        className={"toolTip "+strikeThrough}
                        href={this.state.wholeItem.syncUrls[urlToCompare].url} 
                        target="_blank">{this.getPriceWithDecimals(comparePrice)}
                        
                        <span className="tooltiptext">{mainText+" "+stockDateText}</span>        
                    </a>
                    <div style={{color: color}} className="small-text toolTip">{amt}
                        <span className="tooltiptext">Price difference to ours</span>    
                    </div>
                    {this.getPriceDate(priceDate, priceDateText)}       
                </td>
            )
        }
        return (
            this.getNa()
        )
    }
    getAverage = () => {
        let color = ''
        let p2 = ''
        let amt = Math.round((this.state.average - this.state.price) *100)/100;
        if (this.state.wholeItem.syncUrls == null) {
            return (
                <td align={'left'} style={{padding: '.3rem'}}>n/a</td>
            )
        }
        if (this.state.average > this.state.price) {
            color= 'green'
            p2 = "(+$"+amt +")"
        } else if (this.state.average < this.state.price && this.state.average > 0) {
            color = 'red'
            amt = amt * -1
            p2 = "(-$"+amt+")"
        }

        return (
            <td style={{padding: '.3rem'}}>
                <div className="toolTip">
                    {this.getPriceWithDecimals(this.state.average)}
                    <span className="tooltiptext">Average price based on da, blowout, and steelcity</span>    
                </div>
                <div style={{color: color}} className="small-text toolTip">{p2}
                    <span className="tooltiptext">Price difference to ours</span>    
                </div>     
                          
            </td>
        )
    }
    createRecord = async () => {
        this.setState({disbaled: true})
        let product = this.state.wholeItem.product
        if (product.Name.includes("PREORDER ")) {
            product.Name = product.Name.replace("PREORDER ", "")
        }
        
        var response = await fetchData.promise('/PriSync/newprisync', true, 'POST', this.state.wholeItem.product)
        if (response.status === 200) {
            var body = await response.json();
            var emptyResponse = await fetchData.promise('/Report/emptyUrls/'+body, true, 'GET')
            var emptyUrls = await emptyResponse.json();
            var wholeBody = this.state.wholeItem
            wholeBody.syncUrls = emptyUrls
            wholeBody.syncID = body;
            this.setState({wholeItem: wholeBody})
            this.edit()
        }
        this.updateState("disabled", false)
    }
    priceChange = (event) => {
        this.props.updateState("hasChanged", true)
        this.setState({priceChanged: true})
        
        this.setState({price: event.target.value})
    }
    getPriceWithDecimals = (price) => {
        if (price < 0) {
            return "n/sync"
        } else if (price === "nc") {
            return "nc"
        }
        if (price != null) {
            var num = (Math.round(Number(price) * 100)/100).toFixed(2);
            // Puts commas on the number string
            return "$" + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "$" + price;
    }
    getExtraText = () => {
        let amt = ''
        let color = ''
        let avg = this.state.average
        let diff = Math.round((this.state.price-avg) * 100) / 100
        if (this.state.price > avg && avg > 0) {
            color ='green'
            amt = "(+$"+diff+")";
        } else if (this.state.price < avg && avg > 0) {
            color ='red'
            diff = diff * -1
            amt = "(-$"+diff+")";
        }
        return (
            <div style={{color: color}} className="small-text toolTip">{amt}
                <span className="tooltiptext">Difference compared to average</span>    
            </div>
        )
    }
    cancel = async () => {
        this.setState({price: this.state.originalPrice})
        var status = await this.setPriceTiers()
        if (status) {
            
            this.closePriceTiers()
        }
    }
    save = async () => {
        if (this.state.priceChanged) {
            this.props.updateState("inProgress", true)
            this.setState({disabled: true})
            var prod = this.props.wholeItem.product
            prod.PriceTier1 = this.state.price;
            prod.PriceTier2 = this.getAmt(this.state.priceTiers["PriceTier2"])
            prod.PriceTier3 = this.getAmt(this.state.priceTiers["PriceTier3"])
            prod.PriceTier4 = this.getAmt(this.state.priceTiers["PriceTier4"])
            prod.PriceTier5 = this.getAmt(this.state.priceTiers["PriceTier5"])
            prod.PriceTier6 = this.getAmt(this.state.priceTiers["PriceTier6"])
            prod.PriceTier7 = this.getAmt(this.state.priceTiers["PriceTier7"])
            prod.PriceTier8 = this.getAmt(this.state.priceTiers["PriceTier8"])
            prod.PriceTier9 = this.getAmt(this.state.priceTiers["PriceTier9"])
            prod.PriceTier10 = this.getAmt(this.state.priceTiers["PriceTier10"])

            let link = "/PriceModifiers/product"
            var response = await fetchData.promise(link, true, 'PUT', prod)
            if (response.status === 200) {
                this.setState({originalPrice: this.state.price})
                let data = await response.json()
                if (data != null) {
                    this.setState({lastChangedBy: data.user})
                    this.setState({lastChangedDate: data.date})
                }
                var response2 = await fetchData.promise('/PriceModifiers/update/'+prod.ID, true, 'PUT', Object.values(this.state.priceTiers))
                if (response2.status === 200) {
                    this.closePriceTiers()
                }
                this.props.updateState("hasChanged", false)
                this.setState({priceChanged: false})
            }
            this.setState({disabled: false})
            this.props.updateState("inProgress", false)
        }
    }

    getLastChangedPrice = () => {
        if (this.state.lastChangedDate !== null && this.state.lastChangedBy !== null) {
            var date = this.getDate(this.state.lastChangedDate, true)
            return (
                <div className="small-text">
                    <div>&#x394;&nbsp;{date}</div>
                    <div>By {this.state.lastChangedBy}</div>
                </div>
            )
        }
    }
    getPriceTier1 = () => {
        if (this.state.priceChanged) {
            return (
                <td style={{padding: '.3rem'}}>
                    <Input
                        disabled={this.props.inProgress}
                        onChange={this.priceChange}
                        type="number" 
                        value={this.state.price}
                        onFocus={this.openMenu}
                    ></Input>
                    {this.getExtraText()}
                    {this.getAverageCost()}
                    {this.getLastChangedPrice()}
                    <InputGroup>
                        <Button color='danger' onClick={this.cancel} disabled={this.state.disabled}>Cancel</Button>
                        <Button color='primary' onClick={this.save} disabled={this.state.disabled}>Save</Button>
                    </InputGroup>
                </td>
            )
        }
        return (
            <td style={{padding: '.3rem'}}>
                <Input 
                    disabled={this.props.inProgress}
                    onChange={this.priceChange} 
                    type="number"
                    onFocus={this.openMenu}
                    value={this.state.price}
                    style={{fontSize: ".95em"}}
                ></Input>
                {this.getExtraText()}
                {this.getAverageCost()}
                {this.getLastChangedPrice()}
            </td>
        )
    }
    openMenu = async () => {
        if (this.props.inProgress === false) {
            await this.setPriceTiers()
            if (!this.props.hasChanged) {
                this.props.updateState('currentProds', this.props.wholeItem.product.ID)
            }
        }
    }
    edit = () => {
        this.props.toggleModal(this);
    }
    getNa = () => {
        return (
            <td align={'left'} style={{padding: '.3rem'}}>

                <div className="toolTip">n/a
                <span className="tooltiptext">No url</span>    

                </div>
            </td>
        )
    }
    getAverageCost = () => {
        let color = ""
        let price = Math.round(this.state.wholeItem.product.AverageCost * 100)/100
        let diff = Math.round((this.state.price-price) * 100)/100
        if (diff < 0) {
            color = "red"
            diff = this.getPriceWithDecimals( diff * -1)
            diff = "(-"+diff+")"
        } else {
            color = "green"
            diff ="(+$"+diff+")"
        }
        return (
            <div>
                <div style={{color: color}} className="small-text toolTip">Avg Cost: ${price}
                    <span className="tooltiptext">Average cost for how much we are buying/selling this for</span>    
                </div>
                <div style={{color: color}} className="small-text toolTip">Margin: {diff}
                    <span className="tooltiptext">Profit Margins for how much we are making on this product on average</span>    
                </div>
            </div>
            )
    }
    getLastChanged = () => {
        let syncItem = this.props.wholeItem

        if (syncItem.user != null && syncItem.date != null) {
            let date = this.getDate(this.props.wholeItem.date, true)
            return (
                <div className="small-text">
                    <div className="toolTip">&#x394; {date} </div>
                    <div>By {this.props.wholeItem.user} </div>
                </div>
            )
        }
    }
    changeInput = (event) => {
        var values = this.state.priceTiers
        values[event.target.id].amount = event.target.value
        this.updateState('priceTiers', values)
        this.updateState("priceChanged", true)
        this.props.updateState("hasChanged", true)
    }
    dropClick = (event) => {
        var values = this.state.priceTiers
        if (event.target.id === "global") {
            values[event.target.value].markupType = this.props.globalData[event.target.value].markupType
            values[event.target.value].amount = this.props.globalData[event.target.value].amount
            values[event.target.value].productId = "default"
        } else {
            values[event.target.value].markupType = event.target.id
            values[event.target.value].productId = this.props.wholeItem.product.ID
        }
        this.updateState('priceTiers', values)
        this.updateState("priceChanged", true)
        this.props.updateState("hasChanged", true)
    }
    // Filler function to make react stop screaming. Doesn't rlly matter
    dropToggle = () => {}

    getCalculatedPrice = () => {

    }
    getPriceTier = (tier) => {
        if (this.state.priceTiers == null) {
            return;
        }
        var display = this.state.priceTiers[tier].markupType
        var globalText = display[0].toUpperCase() +display.substring(1)
        var disabled = false
        if (this.state.priceTiers[tier].productId === "default") {
            if (display === "percentage") {
                globalText = "%"
            }
            globalText = "Glb (" +globalText +")"
            disabled = true;
        }
        return (
            <div className="priceColFlex">
                    <div>{tier}</div>
                    <Input 
                        id={tier}
                        type="number"
                        disabled ={disabled ? "disabled" : ""}
                        value={this.state.priceTiers[tier].amount}
                        onChange={this.changeInput}>
                    </Input>
                    <div>Current: {this.getPriceWithDecimals(this.props.wholeItem.product[tier])}</div>
                    <div>New    : {this.getPriceWithDecimals(this.getAmt(this.state.priceTiers[tier]))}</div>
                    <Dropdown className="lower dropdown" toggle={this.dropToggle}>
                        <DropdownToggle className="dropbtn">
                            {globalText}
                        </DropdownToggle>
                        <DropdownMenu onClick={this.dropClick} className="dropdown-content">
                            <DropdownItem id="global" value={tier}>Global</DropdownItem>
                            <DropdownItem id="fixed" value={tier}>Fixed</DropdownItem>
                            <DropdownItem id="markup" value={tier}>Markup</DropdownItem>
                            <DropdownItem id="percentage" value={tier}>Percentage</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
            </div>
        )
    }
    getAmt = (priceModifier) => {
        switch (priceModifier.markupType) {
            case 'percentage':
                let p1 = Number(this.state.price)
                let amt = 1;
                if (p1 <= 100) {
                    amt = 1;
                } else if (p1 <= 1000) {
                    amt = 5;
                } else if (p1 <= 10000) {
                    amt = 10
                } else {
                    amt = 25;
                }
                let percent = (Number(priceModifier.amount)+100)/100
                let total = p1 * percent / amt
                return priceModifier.amount >= 0 ? Math.ceil(total)*amt : Math.round(total) * amt
                //return Math.round(Math.round(((Number(priceModifier.amount)+100)/100) * p1 *100)/100 / 5) * 5
            case 'fixed':
                return Number(priceModifier.amount);
            case 'markup':
                return Number(this.state.price) + Number(priceModifier.amount)
            default: 
                return 0
        }
    }
    setPriceTiers = async () => {
        var response = await fetchData.fetchResponseAuthed("/PriceModifiers/get/"+this.props.wholeItem.product.ID, "GET")
        if (response.status === 200) {
            const data = await response.json()
            this.setState({priceTiers: data})
            return true
        }
        return false
    }
    changeMenuState = async () => {
        if(this.getDisplayType() === "none") {
            this.openMenu()
        } else {
            this.closePriceTiers()
        }
    }
    closePriceTiers = () => {
        this.props.updateState("currentProds", "")
        this.updateState("priceChanged", false)
        this.props.updateState("hasChanged", false)
    }
    getDisplayType = () => {
        if (this.props.currentProds === this.props.wholeItem.product.ID) {
            return "table-row"
        }
        return "none"
    }
    render() {
        if(this.state.wholeItem.syncID == null) {
            return [
                <tr
                key={this.props.wholeItem.product.id+"l"}
                id={this.props.wholeItem.product.id} 
                className={this.state.hover+this.state.selected}
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
            >
                <td align={'left'} style={{padding: '.3rem'}}
                    onClick={this.changeMenuState}

                >{this.state.wholeItem.product["Name"]}</td>
                    {this.getPriceTier1()}
                    {this.getAverage()}
                    {this.getNa()}
                    {this.getNa()}
                    {this.getNa()}
                    {this.getNa()}
                    {this.getNa()}
                    {this.getNa()}
                    {this.getNa()}
                    {this.getNa()}

                    <td style={{padding: '.3rem'}}>
                        <Button color="danger" 
                            onClick={this.createRecord}
                            disabled={this.state.disabled}
                        >Create</Button>
                    </td>
                </tr>,
                <tr 
                    key={this.props.wholeItem.product.id+"g"}
                    className="hidden" 
                    style={{display: this.getDisplayType()}}
                >
                    <td colSpan='12'>
                        <div className="columnHolder smallText">
                            {this.getPriceTier('PriceTier2')}
                            {this.getPriceTier('PriceTier3')}
                            {this.getPriceTier('PriceTier4')}
                            {this.getPriceTier('PriceTier5')}
                            {this.getPriceTier('PriceTier6')}
                            {this.getPriceTier('PriceTier7')}
                            {this.getPriceTier('PriceTier8')}
                            {this.getPriceTier('PriceTier9')}
                            {this.getPriceTier('PriceTier10')}

                        </div>
                    </td>
                </tr>
            ]
        }
        // Removed close button that's below pricetier10 from the 4-5 lines above this text
        // <Button onClick={this.closePriceTiers} color="danger">Close</Button>
        return [
            <tr
                key={this.props.wholeItem.product.id}
                id={this.props.wholeItem.product.id}
                className={this.state.hover+this.state.selected}
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
            >
                <td align={'left'} 
                    style={{padding: '.3rem'}}
                    onClick={this.changeMenuState}
                >{this.state.wholeItem.product["Name"]}
                </td>
                {this.getPriceTier1()}
                {this.getAverage()}
                {this.getCompareToPrice("blowoutcards.com", false)}
                {this.getCompareToPrice("steelcitycollectibles.com", false)}
                {this.getCompareToPrice("dacardworld.com", false)}
                {this.getCompareToPrice("midwestcards.com", false)}
                {this.getCompareToPrice("diamondcardsonline.com", false)}
                {this.getCompareToPrice("giantsportscards.com", false)}
                {this.getCompareToPrice("chicagolandsportscards.com", false)}
                {this.getCompareToPrice("rbicru7.com", false)}
                <td style={{padding: '.3rem'}}>
                    <Button color="primary" onClick={this.edit}>Edit Urls</Button>
                    {this.getLastChanged()}
                </td>
            </tr>,
            <tr 
                key={this.props.wholeItem.product.id+"p"}
                className="hidden" style={{display: this.getDisplayType()}}>
                <td colSpan='12'>
                    <div className="columnHolder smallText">
                        {this.getPriceTier('PriceTier2')}
                        {this.getPriceTier('PriceTier3')}
                        {this.getPriceTier('PriceTier4')}
                        {this.getPriceTier('PriceTier5')}
                        {this.getPriceTier('PriceTier6')}
                        {this.getPriceTier('PriceTier7')}
                        {this.getPriceTier('PriceTier8')}
                        {this.getPriceTier('PriceTier9')}
                        {this.getPriceTier('PriceTier10')}
                    </div>
                </td>
            </tr>
        ] //                         <Button onClick={this.closePriceTiers} color="danger">Close</Button>
        // get rid of button underneath pricetier10
    }
}