import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useShowHost } from '../../Contexts/ShowContexts/ShowHost/ShowHostContext';

const ShowHostAddHostButton: React.FC = () => {

    const {
        setCreateHostModalOpen
    } = useShowHost()
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                setCreateHostModalOpen(true)
            }}
        >
            Add Host
        </Button>
    );
};

export default ShowHostAddHostButton;