import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useShowChannel } from '../../Contexts/ShowContexts/ShowChannels/ShowChannelContext';

const AddShowChannelButton: React.FC = () => {
    const {setCreateChannelModal} = useShowChannel();
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                setCreateChannelModal(true)
            }}
        >
            Add Show Channel
        </Button>
    );
};

export default AddShowChannelButton;