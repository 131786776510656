import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useUser } from '../CommonUtility/UserContext';
const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['isActive', 'isCollapsed', 'nested'].includes(prop),
})(({ theme, isCollapsed, nested, isActive }) => ({
  color: isActive ? theme.palette.secondary.main : 'white',
  justifyContent: isCollapsed ? 'center' : 'flex-start',
  padding: nested ? '5px 16px 8px 32px' : '8px 16px',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  '& .MuiButton-startIcon': {
    color: isActive ? theme.palette.secondary.main : 'white',
    minWidth: '30px',
    justifyContent: 'center',
    marginRight: isCollapsed ? 'auto' : theme.spacing(2),
    marginLeft: isCollapsed ? 'auto' : theme.spacing(1),
  },
}));

export const NavItem = ({
  icon,
  title,
  href,
  feature,
  flags,
  role,
  nested = false,
  onClick,
  isCollapsed = false,
}) => {
  const location = useLocation();
  const isActive = href ? location.pathname === href : false;
  const { hasPermission } = useUser();

  if (!flags[feature] ) {
    return null;
  }
  if (role !== undefined && !hasPermission(role)) {
    return null;
  }
  return (
    <StyledButton
      component={RouterLink}
      startIcon={icon}
      isActive={isActive}
      to={href}
      disableRipple
      onClick={onClick}
      sx={{
        backgroundColor: isActive ? 'rgba(255,255,255,0.08)' : 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.08)',
        },
      }}
      isCollapsed={isCollapsed}
      nested={nested}
    >
      {!isCollapsed && title}
    </StyledButton>
  );
};