import { Box } from "@mui/material";
import CardsHome from "../Dash/SinglesPage/CardsHome";
import { BuildRepacksProvider } from "../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { AddPlayerModalProvider } from "../Dash/SinglesPage/AutocompleteAddModals/AddPlayerModalContext";
import { CardDashboardProvider } from "../Dash/SinglesPage/CardDashboardContext";
import { AddSkuModalProvider } from "../Dash/SinglesPage/AutocompleteAddModals/AddSkuModalContext";
import { CardsProvider } from "../Contexts/CardsContext/CardsContext";
// here
const CardsPage = (props) => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      padding: "5px",
    }}
  >
    <CardsProvider baseurl="/Card/all/" goToURL="/Card/getlastcardpage">
      <CardDashboardProvider>
        <BuildRepacksProvider>
          <AddPlayerModalProvider>
            <AddSkuModalProvider>
              <CardsHome {...props} />
            </AddSkuModalProvider>
          </AddPlayerModalProvider>
        </BuildRepacksProvider>
      </CardDashboardProvider>
    </CardsProvider>
  </Box>
);

export default CardsPage;
