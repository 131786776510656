import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useDebounce from "../../../../Components/CommonUtility/useDebounce";
 
import { GridFilterItem, useGridApiContext } from "@mui/x-data-grid-pro";
interface BasicFilterInputProps {
  field: string;
  operator: string;
  item:GridFilterItem
}

const BasicFilterInput = ({ item } : BasicFilterInputProps) => {
  const initialValue = item.value ?? ""
  const [inputValue, setInputValue] = useState(initialValue)

  const debouncedValue = useDebounce(inputValue, 250);
  
  const gridApi = useGridApiContext();

  useEffect(() => {
    if(debouncedValue !== undefined && debouncedValue !== null){
      gridApi.current.upsertFilterItem({...item, value:debouncedValue})
    }
  }, [debouncedValue]);


  return (
    <TextField
      autoComplete="off"
      margin="dense"
      id={item.field + "-" + item.operator + "-" + "TextField"}
      label={item.field}
      type="text"
      variant="standard"
      value={inputValue}
      onChange={(e)=>{
        setInputValue(e.target.value)
      }}
      className="filter"
      autoFocus
    />
  );
};

export default BasicFilterInput;
