import React, { useState } from "react";

import { useGridApiContext } from "@mui/x-data-grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";
import FetchModule from "../../../Modules/FetchModule";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import "../singles.css";
import { matchSorter } from "match-sorter";

const fetchData = new FetchModule();

/*
  Class used to have the autocomplete render cell within the card break.
  Is the generic use case of the autocomplete for most cells.

*/
export function AutocompletePlayer(props) {
  const { id, field, isFilter, width, players } = props;
  const { user } = useUser();
  const [, setNewValue] = useState(false);
  const apiRef = useGridApiContext();
  const [value, setValue] = useState(props.value || {});
  const [open, toggleOpen] = useState(false);
  const filterModel = props.filterModel;
  const [renderText, setRenderText] = useState(props.value?.label ? props.value.label : "");

  var displayFilter = []

  const handleClose = () => {
    setDialogValue({
      label: "",
      value: "",
      publicId: "",
    });

    toggleOpen(false);
  };
  const [dialogValue, setDialogValue] = React.useState({
    label: "",
    value: "",
    publicId: "",
  });

  const handleSubmit = async () => {
    const newPlayer = dialogValue.label;
    const postData = { name: newPlayer };
    const response = await fetchData.promise("/Card/new/player", true, "POST", {
      UserId: user.Id,
      Body: postData,
    });
    const newPlayerJson = await response.json();
    const newValue = {
      label: newPlayerJson["name"],
      publicId: newPlayerJson["publicId"],
    };
    setRenderText(newPlayer);
    handleChange(null, newValue);
    toggleOpen(false);
  };

  const handleChange = async (event, newValue) => {
    if (filterModel && filterModel.items) {
      const updatedFilterModel = {
        ...filterModel,
        items: filterModel.items?.map((item) => {
          if (item.field === "player") {
            return {
              ...item,
              value: newValue ? newValue.label : "",
            };
          }
          return item;
        }),
      };
      setValue(newValue);
      props.setFilterModel(updatedFilterModel);
    }
    if (newValue === null) {
      setValue({});
      setRenderText("");
      await apiRef.current.setEditCellValue({ id, field, value: {label:""} });
      return;
    }

    if (typeof newValue === "string") {
      setTimeout(async () => {
        const newVal = {
          label: newValue.inputValue,
          value: "",
        };
        setDialogValue(newVal);
        await apiRef.current.setEditCellValue({ id, field, value: newVal });
        toggleOpen(true);
      });
    } else if (newValue && newValue.inputValue) {
      const newVal = {
        label: newValue.inputValue,
        value: "",
      };
      setDialogValue(newVal);
      await apiRef.current.setEditCellValue({ id, field, value: newVal });
      toggleOpen(true);
    } else {
      if (newValue.value === null) {
        setNewValue(true);
        setDialogValue({ ...newValue });
        toggleOpen(true);
        await apiRef.current.setEditCellValue({ id, field, value: newValue });
      }
      setValue(newValue);
      await apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
    setValue(newValue);
    setRenderText(newValue.label);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const inputValue = event.target.value;
      const options = players;
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      if(displayFilter.length > 0) {
        handleChange(event, displayFilter[0]);
        event.target.blur();
      }

      else if (filtered.length > 0) {
        handleChange(event, filtered[0]);
        event.target.blur();
      }
    } else if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();

      const inputValue = event.target.value;
      const options = players;
      var filtered =  matchSorter(options, inputValue, {keys: ['label']}).splice(0, 15);

      if(displayFilter.length > 0) {
        handleChange(event, displayFilter[0]);
        event.target.blur();
      }

      else if (filtered.length > 0) {
        handleChange(event, filtered[0]);
        event.target.blur();
      }
    }
  };

  const handleFilterOptions = (options, params) => {
    var filtered =  matchSorter(options, params.inputValue, {keys: ['label']}).splice(0, 15);
    if (params.inputValue !== "" && params.inputValue !== null && !isFilter) {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }
    displayFilter = filtered;
    return filtered;
  };
  return (
    <React.Fragment>
      <div
        className="autocomplete-container"
        style={{ width: width ? width : "150px" }}
      >
        <Autocomplete
          sx={{
            width: width ? width : "150px",
          }}
          variant="outlined"
          value={
            !filterModel
              ? value
                ? value.label
                : ""
              : (players &&
                players.find((a) => a.label === props.value)) ||
                null
          }
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          filterOptions={handleFilterOptions}
          id="free-solo-dialog-demo player"
          options={players || []}
          getOptionLabel={(option) => option?.label || ""}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(p2, option) => (
            <li
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                width: width ? width : "150px",
              }}
              {...p2}
            >
              {option.label}
            </li>
          )}
          freeSolo
          PaperComponent={({ children }) => (
            <Paper style={{ border: "2px solid #5048E5", top: "-1000px" }}>
              {children}
            </Paper>
          )}
          renderInput={(params) => {
            params.inputProps.value = renderText;
            return (
              <TextField
                {...params}
                onChange={(e) => {
                  setRenderText(e.target.value);
                }}
                placeholder="John Smith"
                id="player"
                type="text"
                style={{ fontSize: "14px", width: width ? width : "150px" }}
              />
            );
          }}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new Player</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add your new Player in the prompt below
          </DialogContentText>
          <TextField
            autoFocus
            id="label"
            value={dialogValue.label ? dialogValue.label : ""}
            onChange={(event) => {
              setDialogValue({
                ...dialogValue,
                label: event.target.value,
              });
            }}
            label="Player"
            type="text"
            variant="outlined"
            style={{ fontSize: "0.75rem" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} type="submit">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
