
import React from 'react'; 
import {Button} from 'reactstrap';
import Box from '@mui/system/Box';
import styled from '@mui/system/styled';
import { DataGrid } from '@mui/x-data-grid';

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'center',
  }));

export default function ShippingJournalItem(props) {
    const columns = [
        {field: "invoice",
         headerName: "Invoice",
         renderCell: ((params) => {
            var id = params.row.id
            if (id.length > 0) {
                return (
                    <a target="_blank" href={"https://inventory.dearsystems.com/Sale#"+id} rel="noreferrer">{params.row.invoice} </a>
                )
            } else {
            return (
                <div>{params.row.date} </div>
            )
            }
         })
        }, 
        { field: "fees",
            headerName: "Fees"
        },
        { field: "resolve",
            headerName: "Resolved",
            renderCell: ((params) => {
              if (params.row.resolved) {
                return <div>Yes</div>
              }
                return (
                    <Button disabled={params.row.resolve} onClick={() => props.resolve(params)}>Resolve</Button>
                )
            })
        }
    ]

    return (
        <Item sx={{gridRow: '1', height: 1}}>
            <Box sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'medium', borderBottom: 1 }}>
                {props.title}
            </Box>
            <DataGrid
                sx={{
                    height: .95
                }}
                rowHeight={35}
                columns={columns}
                rows={props.rows}
            />
        </Item>
    )   
}
