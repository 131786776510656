import { Box } from "@mui/material";
import RepackTicketManager from "../Dash/RepackPage/RepackTicketManager/RepackTicketManager";
import { BuildRepacksProvider } from "../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackTicketProvider } from "../Contexts/RepackContexts/RepackTicketContexts/RepackTicketContext";

const TicketPage = () => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      padding: "5px",
    }}
  >
    <BuildRepacksProvider>
      <RepackTicketProvider>
        <RepackTicketManager />
      </RepackTicketProvider>
    </BuildRepacksProvider>
  </Box>
);

export default TicketPage;
