import { Box, Typography } from '@mui/material';
import { useShowSales } from '../../Contexts/ShowSalesContexts/ShowSaleContext';
import { useSingleShow } from '../../Contexts/ShowContexts/SingleShow/SingleShowContext';

const ShowSaleDataGridFooter = ()  => {
    const {getSelectedSales, sales, selectedBreaks, currentTotals} = useShowSales();
    const {show} = useSingleShow();
    
    return (
      <Box sx={{ p: 2, display: 'flex' }}>
        <Typography variant="body2" sx={{px: 2}}>{getSelectedSales().length} selected sales</Typography>
        <Typography variant="body2" sx={{px: 2}}>{Object.keys(selectedBreaks).length} selected breaks</Typography>
        <Typography variant="body2" sx={{px: 2}}># of Sales: {sales.length}</Typography>
        <Typography variant="body2" sx={{px: 2}}>${show?.showTotalsView?.totalSales | 0} total sales</Typography>
        <Typography variant="body2" sx={{px: 2}}>${currentTotals} total filtered sales</Typography>

      </Box>
    );
}

  export default ShowSaleDataGridFooter;
