import React, { useRef } from 'react';

import { Typography, Box, Paper, Button} from '@mui/material';
import {  UploadFile } from '@mui/icons-material';
import { useFileUploader } from './FileUploaderContext';
import './fileUploader.css';

enum FileUploadDisplayTypes {
    SIMPLE = 'simple',
    BIG = 'big',
}

type FileUploaderProps = {
    children?: React.ReactNode;
    title?: string;
    selectText?: string;
    supportsText?: string;
    subText?: string;
    displayType?: FileUploadDisplayTypes;
}

const BigFileUploader = ({title, selectText, supportsText, subText}: FileUploaderProps): React.ReactElement<FileUploaderProps> => {
    const {setFile, selectedFiles, acceptsFileType, uploading, handleDrop, dragOver, setDragOver, setSelectedFiles, uploadToBackend} = useFileUploader();
    const ref: any = useRef(null);

    title = title || "Drag & Drop or ";
    selectText = selectText || "browse";

    window.addEventListener("dragover",function(e){
        e.preventDefault();
      },false);
      window.addEventListener("drop",function(e){
        e.preventDefault();
      },false);

    if (uploading) {
        return (
        <Paper
            className='uploaderMainContainer'
        >
            <Typography variant='h5'>File is Uploading/Processing</Typography>
        </Paper>
        )
    }
    
    if (dragOver) return (
        <Paper
            className='uploaderMainContainer'
        >
            <Paper 
                sx={{ borderColor: 'secondary.main' }}
                className='uploaderDashedContainer'
                onDragLeave={(e) => {
                    e.preventDefault();
                    setDragOver(false);
                }}
                onDrop={handleDrop}
                
                >
                {selectedFiles != null ? <Typography variant='h5'>Upload new file(s)</Typography> : <Typography variant='h5'>Upload files(s)</Typography>}

            </Paper>
        </Paper>
      )

      if (selectedFiles != null) {
        return (
            <Paper
            className='uploaderMainContainer'
            >
                <Paper 
                sx={{ borderColor: 'divider' }}
                className='uploaderDashedContainer'
                onDragOver={(e) => {
                    e.preventDefault();
                    setDragOver(true);
                }}
                onDrop={handleDrop}
                >
                    {selectedFiles.getAll('file').length == 1 ? <Typography variant='h6'>{selectedFiles.getAll('file')[0].name}</Typography> :
                                                                <Typography variant='h6'>{selectedFiles.getAll('file').length} Files Selected</Typography>
                    }                    <Button onClick={() => setSelectedFiles(null)}>Clear files</Button>
                    <Button onClick={() => uploadToBackend()}>Upload</Button>
                </Paper>
            </Paper>
        )
    }

    return (
        <Paper
            className='uploaderMainContainer'
        >
            <Box 
                className='uploaderDashedContainer'
                sx={{ borderColor: 'divider' }}
                onDrop={handleDrop}
                onDragOver={(e) => {
                    e.preventDefault();
                    setDragOver(true);
                }}
                >
                <UploadFile sx={{width:"100px", height: '120px'}}/>
                <Box sx={{
                    display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center'
                }} >
                    <Typography variant="h5">{title}&nbsp;</Typography>
                
                    <Typography variant="h5" color='secondary.main' sx={{cursor: 'pointer'}}
                        onClick={() => {
                            if (ref.current) {
                                ref.current?.click();
                            }
                        }}
                    >{selectText}</Typography>
                    <input 
                    type="file" multiple={true} name="file" hidden onChange={(e) => setFile(e)} 
                    accept={acceptsFileType}
                    ref={ref}
                    />
                </Box>
                <Typography variant="subtitle1" color="gray">{supportsText}</Typography>
                <Typography variant="subtitle1" color="gray">{subText}</Typography>
            </Box>
        </Paper>
    );
};

export default BigFileUploader;
