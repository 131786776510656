// RepackCreationContent.tsx
import React, { useCallback, useEffect } from "react";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import SeriesInfoPanel from "./SeriesInfoPanel";
import TicketsTable from "./TicketView";
import RepackStorePreview from "./RepackStorePreview";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import StagedRepackPreview from "./StagedRepackPreview";

import { RepackBoxType } from "../Types/RepackBoxType";



export default function RepackCreationContent() {
  // hooks
 
  const {
    boxTypes,
    selectedType,
    setSelectedType,
    removeFromRepackStoreRepackVersion,
    
    setId,
  } = useBuildRepacks();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromUrl = urlParams.get("id");
    setId(idFromUrl);

    if (idFromUrl) {
    }
  }, [setId]);

  

  //functional components

  const TicketView = useCallback(() => {
    return (
      <TicketsTable />
    );
  }, [removeFromRepackStoreRepackVersion]);

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box padding={1}> 
      <Typography variant="h4" className="repack-creation-header">
        Repack Creation
      </Typography>
      <Box className="repack-container" padding={1}> 
        <Grid container spacing={1} direction={isXSmallScreen ? 'column' : 'row'}>
          {/* Left side - RepackStorePreview and TicketView */}
          <Grid item xs={12} md={6}>
            <Box className="repack-store-preview" padding={1}> 
              <RepackStorePreview />
              <TicketView />
            </Box>
          </Grid>

          {/* Right side - StagedRepackPreview and SeriesInfoPanel */}
          <Grid item xs={12} md={6}>
            <Box className="repacks" padding={1}> 
              <StagedRepackPreview />
              <SeriesInfoPanel
                boxTypes={boxTypes as RepackBoxType[]}
                setSelectedType={setSelectedType}
                selectedType={selectedType}
                boxes={[]}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
