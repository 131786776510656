import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import { useSkuCorrection } from './SkuCorrectionContext';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { ModalHeader } from 'reactstrap';



const SkuCorrectionModal = () => {

  const {open,setOpen,datafields,setDatafields,checkSku,submit} = useSkuCorrection();

  const onClose = ()=>{
    setOpen(false)
  }

  const columns = [
    { field: 'label', headerName: 'Label', width: 250, editable: false },
    { field: 'value', headerName: 'Value', width: 200, editable: true },
    { field: 'ranking', headerName: 'Ranking', width: 150, editable: true },
    { field: 'conflict', headerName: 'Duplicate Name', width: 200, editable: false }
  ];

  

  const processRowUpdate = (newRow:any, oldRow:any) => {
    const updatedRows = datafields.map((row:any) =>{
      if(row.publicId !== oldRow.publicId){
        return row;
      }
      else{
        checkSku(newRow,()=>{})
        return newRow;
      }
    }
    );

    

    setDatafields(updatedRows);
    return newRow;
  };
  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ onClick: (event) => event.stopPropagation() }}  disableEscapeKeyDown>
      
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4 }}>
        <ModalHeader>SKU Correction</ModalHeader>
        <DataGridPremium
          rows={datafields}
          columns={columns}
          getRowId={(row) => datafields.findIndex((x:any) => x.publicId === row.publicId)}
          processRowUpdate={processRowUpdate}
          isCellEditable={(params) => {
            if(params.row.type === "grade"){
              return true;
            }
            return params.field !== "ranking"
          }
          }
        />
        <Button
          onClick={submit}
          disabled={
            datafields.some((data: any) => data.value === '') ||
            datafields.some((data: any) => data.conflict)
          }
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default SkuCorrectionModal;