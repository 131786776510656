import { AddPlayerModalProvider } from "../Dash/SinglesPage/AutocompleteAddModals/AddPlayerModalContext"
import { AddSkuModalProvider } from "../Dash/SinglesPage/AutocompleteAddModals/AddSkuModalContext"
import BulkCardsCreator from "../Dash/SinglesPage/BulkCardsCreator/BulkCardsCreator"
import { CardDashboardProvider } from "../Dash/SinglesPage/CardDashboardContext"

export const BulkCardCreatorPage = () => {
  return(
  <CardDashboardProvider>
    <AddSkuModalProvider>
      <AddPlayerModalProvider>
        <BulkCardsCreator/>
      </AddPlayerModalProvider>
    </AddSkuModalProvider>
  </CardDashboardProvider>)
}