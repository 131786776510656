import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { Box, Checkbox, Typography } from '@mui/material';
import { DataGridPremium, DataGridPremiumProps, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { IShow } from '../../Contexts/ShowContexts/Shows/IShow';
import PayPeriodDataGridToolbar from './PayPeriodDataGridToolbar';
import { ManageAccounts } from '@mui/icons-material';
import { useSinglePayPeriods } from '../../Contexts/PayPeriodContexts/SinglePayPeriodContext';
import PayPeriodShowCommissionDataGrid from './PayPeriodShowCommissionDataGrid';
import utc from 'dayjs/plugin/utc';

type PayPeriodsDataGridProps = {
    children?: React.ReactNode;
    shows: IShow[];
    showsLoading: boolean;
    showsError: any;
    selectedShows: GridRowSelectionModel;
    setSelectedShows: (shows: GridRowSelectionModel) => void;
    gridApiRef: any;
}

dayjs.extend(utc)

const PayPeriodShowsDataGrid = ({shows, showsLoading, showsError, setSelectedShows, gridApiRef} : PayPeriodsDataGridProps)  => {
    const {navigateToShow} = useSinglePayPeriods();

    const columns: GridColDef[] = [
        { field: 'title', headerName: 'Title', width: 300},
        { field: "showCommissionView", headerName: "Commissions?", width: 120,
            valueGetter:(value) => {
                return value
            },
            renderCell: (params) => {
                return <Checkbox checked={params.value.length > 0} disabled/>
            }
        },
        { field: 'showChannel', headerName: 'Channel & Platform', width: 250,
            renderCell: (params) => (
                <Typography>{params.value?.channelName} - {params.value?.platform.platformName}</Typography>
            )
        },
        { field: 'startTime', headerName: 'Start  Time', width: 200,
            renderCell: (params) => {
                return <Typography>{dayjs(params.value).format('YYYY-MM-DD HH:mm')}</Typography>
            }
        },
        {
            field: 'totalSales', headerName: 'Total Sales', width: 100,
            valueGetter: (value, row) => {
                if (row.showTotalsView == null) return 0
                return row.showTotalsView?.totalSales | 0
            },
            renderCell: (params => {
                return <Typography >${new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        {
            field: 'saleCount', headerName: 'Sales', width: 100,
            valueGetter: (value, row) => {
                if (row.showTotalsView == null) return 0
                return row.showTotalsView?.totalSaleCount | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        {
            field: 'totalPersonals', headerName: 'Personals', width: 100,
            valueGetter: (value, row) => {
                if (row.showTotalsView == null) return 0
                return row.showTotalsView?.totalPersonals | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value)}</Typography>
            })
        },
        {
            field: 'totalBreaks', headerName: 'Breaks', width: 100,
            valueGetter: (value, row) => {
                if (row.showTotalsView == null) return 0
                return row.showTotalsView?.totalBreakSlots | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value)}</Typography>
            })
        },
        {
            field: 'totalGiveaways', headerName: 'Giveaways', width: 100,
            valueGetter: (value, row) => {
                if (row.showTotalsView == null) return 0
                return row.showTotalsView?.totalGiveaways | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value)}</Typography>
            })
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key={"PayPeriodShowsDataGrid-Actions-Manage-"+id}
                        icon={<ManageAccounts />}
                        label="Manage"
                        className="textPrimary"
                        onClick={() => navigateToShow(row)}
                        color="inherit"
                    />
                ];
              },
        }
    ];

    const detailPanelContent = useCallback((row: any) => {
        return <PayPeriodShowCommissionDataGrid saleCommissionData={row.row.showCommissionView}/>;
      }, []);

      const getDetailPanelHeight = React.useCallback<
      NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
    >(() => 'auto' as const, []);

    if (showsLoading) return <div>Loading...</div>;
    if (showsError) return <div>Error Loading</div>

    return(
        <Box
            sx={{height: '350px'}}
        >
           <DataGridPremium
                rows={shows}
                apiRef={gridApiRef}
                columns={columns}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedShows(newRowSelectionModel);
                }}
                getDetailPanelContent={detailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                density="compact"
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                  }
                slots={{
                    toolbar: PayPeriodDataGridToolbar
                }}
                checkboxSelection
           />
        </Box>
    )
}
  
  
  export default PayPeriodShowsDataGrid;
  