import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import FetchModule from "../../../Modules/FetchModule";
import { useCardDashboard } from "../CardDashboardContext";
import { useAddPlayerModal } from "./AddPlayerModalContext";
export type AddPlayerModalProps = {
  open:boolean;
  toggleOpen: (open:boolean) => void;
  addToDatagridFunction:any;
  refocus:any

}
export type DialogValue = {
  label:string;
  value:string;
  publicId:string;
}

const fetchData = new FetchModule();
export const AddPlayerModal = ({open,toggleOpen,addToDatagridFunction,refocus}:AddPlayerModalProps)=>{
  const {updatePlayers} = useCardDashboard();
  const {setDialogValue,dialogValue} = useAddPlayerModal();
  
  const { user } = useUser();

  const handleClose = () => {
    setDialogValue({
      label: "",
      value: "",
      publicId: "",
    });

    toggleOpen(false);
  };

  const handleSubmit = async (event:any) => {
    event.preventDefault();
    const newPlayer = dialogValue.label;
    const postData = { name: newPlayer };
    const response = await fetchData.promise("/Card/new/player", true, "POST", {
      UserId: user["id"],
      Body: postData,
    });
    const newPlayerJson = await response.json();
    const newValue = {
      label: newPlayerJson["name"],
      publicId: newPlayerJson["publicId"],
    };
    updatePlayers(newValue);
    addToDatagridFunction(newValue);
    if(refocus){
      refocus();
    }
    toggleOpen(false);
    
  };

  return(
    <Dialog 
      open={open} 
      onClose={handleClose} 
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      disableEscapeKeyDown
      autoFocus={true}
    >
        <DialogTitle>Add a new Player</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add your new Player in the prompt below
          </DialogContentText>
          <TextField
            autoFocus
            id="label"
            value={dialogValue.label ? dialogValue.label : ""}
            onChange={(event) => {
              setDialogValue({
                ...dialogValue,
                label: event.target.value,
              });
            }}
            label="Player"
            type="text"
            variant="outlined"
            style={{ fontSize: "0.75rem" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} type="submit">
            Add
          </Button>
        </DialogActions>
      </Dialog>
  )
}