import { Box } from '@mui/material';
import RepackViewer from '../Dash/RepackPage/RepackViewer/RepackViewer';
import { BuildRepacksProvider } from '../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext';

const RepackPage = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        ><BuildRepacksProvider>
            <RepackViewer/>
        </BuildRepacksProvider>

    </Box>
  );
  
  
  export default RepackPage;