import * as React from 'react';
import {createContext, useContext, useState} from 'react';
import useFetch from '../../Components/CommonUtility/useFetch';
import { useSnackbar } from '../SnackbarContext/SnackbarContext';
import { useUser } from '../../Components/CommonUtility/UserContext';
import { IShowSale, IShowSaleModified } from '../ShowSalesContexts/IShowSale';
import ShowSaleEditModal from '../../Dash/SingleShowSalePage/ShowSaleEditModal';
import { BulkAssignBreakIdsToShowSales } from '../ShowContexts/ShowCommissions/ShowComissionAPICalls';
import { useShowSaleTypes } from '../ShowSalesContexts/ShowSaleTypesContext';
import { useShowSales } from '../ShowSalesContexts/ShowSaleContext';
import { useSingleShow } from '../ShowContexts/SingleShow/SingleShowContext';


const ShowSaleModifiedContext = createContext<ShowsSaleModifiedContextProps | null>(null);
interface ShowsSaleModifiedContextProps {
    selectedShowSale: any | undefined,
    setSelectedShowSale: (showSale: IShowSale) => void,
    modifiedAttributes: any,
    setModifiedAttributes: (modifiedAttributes: IShowSaleModified | null) => void,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    open: boolean, setOpen: (open: boolean) => void,
    openModal: (showSale: any) => void,
    validFields: { [key: string]: boolean },
    modifyShowSale: () => any,
    saleTypes: any, saleTypesLoading: boolean, saleTypesError: boolean,
    saleStatuses: any, statusLoading: boolean, statusError: any,
}

type ShowSaleModifiedProviderProps = {
    children?: React.ReactNode;
}

export const ShowSaleModifiedProvider = ({children} : ShowSaleModifiedProviderProps) => {
    const {changeShowSales} = useShowSales();
    const {show} = useSingleShow();
    const [selectedShowSale, setSelectedShowSale] = useState<IShowSale>()
    const [modifiedAttributes, setModifiedAttributes] = useState<IShowSaleModified | null>(null)
    const [open, setOpen] = useState(false)
    const {newSnackbarMessage} = useSnackbar();
    const {user} = useUser();
    const {saleTypes, saleTypesError, saleTypesLoading} = useShowSaleTypes();
    const {data: saleStatuses, loading: statusLoading, error: statusError} = useFetch("/ShowSale/statuses", true, [])
    
    const validFields = {
        saleType: true,
        saleStatus: true,
        breakId: true,
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setModifiedAttributes(
            { ...modifiedAttributes, 
                [name]: value 
            } 
        );
    }
    function openModal (showSale: any)  {
        const modified = showSale.modifiedAttributes
        if (modified) {
            let newAttributes = {}
            if (modified["breakId"] != null) {
                newAttributes = {...newAttributes, "breakId": modified["breakId"]}
            }
            if (modified["saleType"] != null) {
                newAttributes = {...newAttributes, "saleType": modified["saleType"].id}
            }
            if (modified["saleStatus"] != null) {
                newAttributes = {...newAttributes, "saleStatus": modified["saleStatus"].id}
            }
            setModifiedAttributes(newAttributes)
        } else {
            setModifiedAttributes(null)
        }
        setSelectedShowSale(showSale)
        setOpen(true)
    }
    async function modifyShowSale() {
        try {
            const saleIds: any[] = [selectedShowSale?.id || []];
            const response = await BulkAssignBreakIdsToShowSales(user, saleIds, modifiedAttributes, show?.publicId || "")
            if (response.status == 200) {
                const data = await response.json()
                changeShowSales(data.sales, data.showTotals);
                newSnackbarMessage("Successfully updated Show Sales", 'success');
            } else if (response.status == 409) {
                const result = await response.json();
                newSnackbarMessage("Error: " +result.message, 'error');
            } 
            else {
                newSnackbarMessage("Error changing show sales, see logs for detailed error message", 'error');
            }
        } catch (error) {
            console.error(error)
            newSnackbarMessage("Error modifying selected sales, please try again later", 'error')
        }
    }
    
    const contextValues: ShowsSaleModifiedContextProps = {
        selectedShowSale, setSelectedShowSale,
        modifiedAttributes, setModifiedAttributes,
        handleChange,
        open, setOpen,
        validFields,
        openModal,
        modifyShowSale,
        saleTypes, saleTypesLoading: saleTypesLoading, saleTypesError,
        saleStatuses, statusLoading, statusError
    }
    return (
        <ShowSaleModifiedContext.Provider value={contextValues}>
            <ShowSaleEditModal />
            {children}
        </ShowSaleModifiedContext.Provider>
        )
}

export function useShowSaleModified() {
    const context = useContext(ShowSaleModifiedContext);
    if (!context) {
        throw new Error("Invalid ShowSaleModifiedContext, must be wrapped in a ShowSaleModifiedProvider")
    }
    return context;
}