import React from 'react';
import { Button,Input,  } from 'reactstrap';
import '../Hamburger.css';
import Select from 'react-select';
import FetchModule from "../../Modules/FetchModule";
import {useState, useEffect} from 'react';
const fetchData = new FetchModule();

const useFetchGet = (url) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        fetchData.fetchResponseAuthed(url, "GET")
            .then((res) => res.json())
            .then((data) => setData(data));

    }, [url])
    return [data]
}
const PersonalsItem = (props) => {
    const [customer, setCustomer] = useState(props.item.customer)
    const [name, setName] = useState(props.item.name)
    const [price, setPrice] = useState(props.item.price)
    const [pantheonSerial, setPantheonSerial] = useState({value: props.item.pantheonSerial, label: props.item.pantheonSerial})
    const [allSerials] = useFetchGet("/Pantheon/get/all/serials")

    function isDisabled() {
        if (props.updating) {
            return true
        }
        if (customer != props.item.customer || name != props.item.name 
            || pantheonSerial.value != props.item.pantheonSerial || price != props.item.price) {
            return false
        }
        return true
    }
    function cancel() {
        setCustomer(props.item.customer)
        setName(props.item.name)
        setPantheonSerial({value: props.item.pantheonSerial, label: props.item.pantheonSerial})
    }

    async function save() {
        var success = await props.save(props.item, name, customer, pantheonSerial.value, price)
        if (success) {
            props.item.name = name
            props.item.customer = customer
            props.item.pantheonSerial = pantheonSerial.value
            props.item.price = price
        }
    }
    function getPantheon() {
        if (props.item.isPantheon && allSerials !== null) {
            return (
                <Select 
                    options={allSerials[props.item.productId]}
                    onChange={(value) => {setPantheonSerial(value)}}
                    value={pantheonSerial}
                />
                //<Input value={pantheonSerial} onChange={(event) => setPantheonSerial(event.target.value)} />
            )
        }
        return (
            <div>n/a</div>
        )
    }
    return (
        <tr>
            <td>{props.item.productName}</td>
            <td><Input value={name} onChange={(event) => setName(event.target.value)}/></td>
            <td><Input value={customer} onChange={(event) => setCustomer(event.target.value)}/></td>
            <td><Input value={price} onChange={(event) => setPrice(event.target.value)}/></td>
            <td>{getPantheon()}</td>
            <td>
                <Button color="danger" disabled={isDisabled()}
                    onClick={cancel}
                >Cancel</Button>
                <Button color="primary" disabled={isDisabled()}
                    onClick={save}
                >Save</Button>
            </td>
            <td><Button color="danger" 
                disabled={props.disabled}
                onClick={() => props.remove(props.index, props.item)}>X</Button></td>
        </tr>
    )
}
export default PersonalsItem

