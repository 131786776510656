import React, { Component, useEffect } from 'react';
import {useLocation} from "react-router-dom";
import { Button, Input, Table, InputGroup} from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import IndividualBreakItem from './IndividualBreakItem';
import IndividualBreakProducts from './IndividualBreakProducts';
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();
const Observer = ({didUpdate}) => {
    const location = useLocation();
    useEffect (() => {
        didUpdate(location)
    }, [location])
    return null;
}
export default class IndividualBreak extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: "products",
            data : [],
            currentProducts: {},
            product: null,
            total: 0,
            productsToShow: [],
            searchText: "",
            breakId: 0,
            options: {
            },
            order: [],
            localSessionID: 0,
            saving: false,
            slots: 1,
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.breakId = id[4];
        this.state.localSessionID = id[3]
        var item = localStorage.getItem('breaks')
        
        this.state.activePage = item === null ? 'products' : item
        
    }
 // STUFF FOR PRODUCT UPDATES:
    async componentDidMount() {
        await this.mount()
        //this.interval = setInterval(() => this.mount(), 5000)
    }

    textChange = (event) => {
        this.setState({searchText: event.target.value})
        this.updateProductDisplay(event.target.value);
    }
    clearText = () => {
        this.setState({searchText: ""}, () =>{
            this.updateProductDisplay(this.state.searchText, this.state.currentSortBy);
        })
    }
    updateProductDisplay = (text) => {
        this.setState({productsToShow:
            Object.values(this.state.products)
                .filter(product => {
                    var containsAll = true;
                    text.split(' ').forEach(word => {
                        if (!product.name.toLowerCase().includes(word.toLowerCase())) {
                            containsAll = false;
                        }
                    })
                    return containsAll ? product : null;
                })
        })
    }
    getOptions = () => {
        return this.state.options
    }
    // UPDATES PRODUCT DISPLAY ON RENDERS
    displayProduct = (slot) => {
        var index = slot.slotNumber
        return (
            <IndividualBreakItem 
                key={index}
                amt={this.state.product.breakItem.leagueCount} 
                options={this.getOptions()}
                updateState={this.updateState}
                slot={slot}
                index={index}
                order={this.state.order}
            />
                
        )
    }
    getData = () => {
        return this.state.product.slots.map((product) => this.displayProduct(product))
    }
    addProduct = (event) => {
        var prod = this.state.products[event.target.value]
        var prods = this.state.currentProducts
        prods[event.target.value] = {
            product: prod,
            quantity: 1,
        }
        this.updateState("currentProducts", prods)
    }
 // STUFF FOR COMPONENTS
    mount = async () => {
        const response = await fetchData.fetchResponseAuthed("/Breaking/get/"+this.state.breakId, "GET");
        const data = await response.json()
        this.updateState("slots", data.slots.length)
        let total = 0;
        data.products.forEach(prod => {
            total += prod.price
        })
        this.updateState("total", total)
        this.updateState("product", data)
        //this.updateProductDisplay(this.state.searchText);
        let options = {}
        Object.values(data.breakItem.teams).forEach(team => {
            options[team] = {value: team, label: team}
        })
        data.slots.forEach(item => {
            item.teams.forEach(team => {
                if (options[team] !== undefined) {
                    delete options[team]
               }
            })
        })
        this.updateState('data', data.slots)
        this.updateState('order', this.state.product.breakItem.leagues)
        this.updateState("options", options);
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    getHeaders = () => {
        let elements = []
        //let leagues = this.state.product.breakItem.leagues
        //for(var i=0; i < leagues.length; i++) {
            elements.push(<th >teams</th>)
        //}
        return <th >Team</th>;
    }
    isSessionDisabled = () => {
        if (this.state.saving) return true
        let path = window.location.pathname
        let id = path.split('/')
        return this.state.localSessionID ===  id[3]
    }
    changeSession = (event) => {
        this.updateState('localSessionID', event.target.value)
    }
    cancelSession = () => {
        let path = window.location.pathname
        let id = path.split('/')
        this.updateState('localSessionID', id[3])
    }
    saveNewSession = async () => {
        if (!this.state.saving) {
            this.updateState("saving", true)
            let path = window.location.pathname
            let id = path.split('/')
            const response = await fetchData.promise('/breaking/change/session/'+this.state.localSessionID+"/"+id[4], true, 'PUT', "")
            if (response.status === 200) {
                this.props.navigate('/dashboard/breaks/'+this.state.localSessionID +"/"+id[4]+"/settings")
            } else {
                console.error(await response.text())
            }
            this.updateState('saving', false)
        }
    }
    getSettings = () => {
        return (
            <div className={"dashboard-body"}>
                <div className="card">
                    <div className="card-header">
                        Settings
                    </div>
                    <div className="card-body">
                        <div className="row-display">
                            Session: &nbsp;
                            <InputGroup style={{width: '250px'}} >
                                <Input type='number' 
                                    value={this.state.localSessionID} 
                                    onChange={this.changeSession}/>
                            <Button color="danger" disabled={this.isSessionDisabled()}
                                onClick={this.cancelSession}
                                >Cancel</Button>
                            <Button color="primary"
                                onClick={this.saveNewSession}
                                 disabled={this.isSessionDisabled()}>Save
                            </Button>
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    getSlotTotal = () => {
        var total = 0
        this.state.data.forEach(item => {
            total += item.price
        })
        return total
    }
    getBody = () => {
        if(this.state.product !== null) {
            if (this.state.activePage === "products") {
                return (
                    <IndividualBreakProducts 
                        updateState={this.updateState}
                        breakItem={this.state.product.breakItem}
                        products={this.state.product.products}
                    />
                )
            } else if (this.state.activePage === "settings") {
                return this.getSettings()
            }
        return (            
            <div className={"dashboard-body"}>
                <div className="card" style={{height: '90%'}}>
                    <div className="card-header">
                        {this.state.product.breakItem.breakSKU}
                        <div>
                            Retail Price: ${this.state.total}
                        </div>
                        <div>
                            Slots: ${(this.state.total / this.state.slots).toFixed(2)}
                        </div>
                        <div>
                            Price Total: ${this.getSlotTotal()}
                        </div>
                        <a href={"https://personals.wethehobby.com/break/"+this.state.breakId}>
                            Customer Display
                        </a>
                    </div>
                    <div className="card-body" style={{overflow: 'hidden'}}>
                        <Table className="fixTable">
                            <thead>
                                <tr className="thead-fix">
                                    <th>Slot</th>
                                    <th>Customer</th>
                                    {this.getHeaders()}
                                    <th>Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {this.getData()}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>)
        }
    }
    onClick = (event) => {
        this.props.navigate(event.target.id)
        localStorage.setItem('breaks', event.target.id)
        this.updateState("activePage", event.target.id)
    }
    getActive = (val) => {
        return this.state.activePage === val ? "active" : ""
    }
    updated = (event) => {
        let page = event.pathname.split("/")[5]
        this.updateState("activePage", page)
    }
    goBack = () => {
        this.props.navigate("/dashboard/session/"+this.state.localSessionID+"/breaks")
    }
    render() {
        return (
            <div className={"dashboard-page-flip"}>
                <Observer didUpdate={this.updated}/>
                <div className="dashboard-nav topbar" 
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                >
                    <button className="back-button" onClick={this.goBack}>Go Back</button>
                </div>
                <div className="dashboard-nav-flip">
                    <div className="dashboard-nav-left-flip">
                        <div className={this.getActive("products") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="products"
                            >Products</div>
                        <div className={this.getActive("slots") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="slots"
                            >Slots</div>
                        <div className={this.getActive("settings") + " dashboard-nav-left-item "}
                            onClick={this.onClick}
                            id="settings"
                            >Settings</div>
                    </div>
                    {this.getBody()}
                </div>
                
            </div>
        )
    }

}
