import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackBox } from "../Types/RepackBox";
import { RepackBoxType } from "../Types/RepackBoxType";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { RepackItem } from "../Types/RepackItem";
import {
  DeleteItem,
  CreateNewBox,
  DeleteRepackBox,
  UpdateRepackBox,
  UpdateItem,
} from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksAPICalls";
import { RepackItemType } from "../Types/RepackItemType";

const StagedRepackPreview = () => {
  const [searchParams] = useSearchParams();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const seriesId = searchParams.get("id") || "";
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();
  const { setSelectedForRepack, setTickets } = useBuildRepacks();
  const {
    boxTypes,
    setSelectedBox,
    selectedBox,
    itemTypes,
    boxes,
    setBoxes,
    setSelectedSeriesId,
    box,
    setBox,
    boxLoading,
  } = useBuildRepacks();
  const [selectedBoxType, setSelectedBoxType] = useState<any>(
    boxTypes[0]?.id | 1
  );
  const handleSelectedBoxTypeChange = (event: SelectChangeEvent<any>) => {
    boxTypes.forEach((type: RepackBoxType) => {
      if (type.id === event.target.value) {
        setSelectedBoxType(type.id);
      }
    });
  };
  const [selectedItemTypeName, setSelectedItemTypeName] = useState("");
  const [selectedItemType, setSelectedItemType] = useState<RepackItemType>({
    id: 0,
    name: "",
  });

  const handleUpdateRepackBox = async (updatedRow: RepackBox) => {
    try {
      const b = updatedRow ?? [];
      const items = b.items ?? [];
      const response = await UpdateRepackBox(updatedRow, items, user);

      if (response.ok) {
        newSnackbarMessage("Repack box updated successfully!", "success");
        const updatedBoxes = boxes.map((rb: RepackBox) =>
          rb.id === updatedRow.id ? { ...rb, ...updatedRow } : rb
        );
        return updatedBoxes;
      } else {
        newSnackbarMessage("Failed to update repack box.", "error");
      }
    } catch (error) {
      console.error("Failed to update repack box:", error);
      newSnackbarMessage("Error updating repack box.", "error");
    }
  };

  const handleUpdateRepackItems = async (updatedRow: RepackItem,) => {
    try {
      const row = {
        ...updatedRow,
        repackItemTypeId: selectedItemType?.id,
        repackItemType: selectedItemType,
      };
      const response = await UpdateItem(user, row);

      if (response.ok) {
        newSnackbarMessage("Repack Item updated successfully!", "success");
        const rData = await response.json();
        const rDataResult = await rData.result;

        return rDataResult;
      } else {
        newSnackbarMessage("Failed to update repack item.", "error");
      }
    } catch (error) {
      console.error("Failed to update repack item:", error);
      newSnackbarMessage("Error updating repack item.", "error");
    }
  };

  const handleCreateBox = async () => {
    if (!selectedBoxType) {
      newSnackbarMessage("Please select a repack type.", "error");
      return;
    }

    try {
      const type = boxTypes.find(
        (type: RepackBoxType) => type.id === selectedBoxType
      );
      if (!type) {
        newSnackbarMessage("Invalid repack type selected.", "error");
        return;
      }
      const response = await CreateNewBox(user, type, seriesId);

      if (response.ok) {
        newSnackbarMessage("Box created successfully!", "success");
        const newBox = await response.json();
        const newBoxes = [...boxes, newBox.result];
        setBoxes(newBoxes);
      } else {
        newSnackbarMessage("Failed to create box.", "error");
      }
    } catch (error) {
      console.error("Failed to create box:", error);
      newSnackbarMessage("Error creating box.", "error");
    }
  };

  const handleDeleteRepackBox = async () => {
    const prevBox = selectedBox;
    if (!prevBox) {
      newSnackbarMessage("No box selected.", "error");
      return;
    }
    const response = await DeleteRepackBox(selectedBox, user);
    if (response.ok) {
      const removedItemsJson = await response.json();

      newSnackbarMessage("Box deleted successfully!", "success");

      if (removedItemsJson && typeof removedItemsJson === "object") {
        const {
          CardIds: removedCardIds = [],
          TicketIds: removedTicketIds = [],
        } = removedItemsJson;
        setSelectedForRepack((prev) => [...prev, ...removedCardIds]);
        setExpandedRowIds([]);
        setSelectedBox(null);
        setTickets((prev: any) => [...prev, ...removedTicketIds]);
        setBoxes(boxes.filter((b: RepackBox) => b.id !== prevBox.id));
      } else {
        console.error(
          "removedItems is undefined or not an object",
          removedItemsJson
        );
      }
    } else {
      newSnackbarMessage("Failed to delete box.", "error");
    }
  };

  const mapRepackTypeName = (b: RepackBox) => {
    const type = boxTypes.find(
      (type: RepackBoxType) => type.id === b.repackBoxTypeId
    );
    return type ? type.name : "Unknown Type 1";
  };

  const handleRowClick = (params: GridRowParams) => {
    if (params.row.id === selectedBox?.id) {
      setExpandedRowIds([]);
      setSelectedBox(null);
      setBox(null);
      return;
    }

    const b = boxes.find((rb: RepackBox) => rb.publicId === params.id);
    if (!b) return;
    setSelectedBox(params.row); 
    setExpandedRowIds([params.id]);
  };

  useEffect(() => {
    setSelectedSeriesId(seriesId);
  }, [expandedRowIds]);

  const handleRowExpandChange = (ids: GridRowId[]) => {

    if (ids.length === 1) {
      const expandedId = ids[0];
      const b = boxes.find((rb: RepackBox) => rb.publicId === expandedId);

      setExpandedRowIds([expandedId]);
      if (b) {
        setSelectedBox(b);
        setBox(b);
      }
    } else if (ids.length === 0) {
      setExpandedRowIds([]);
      setSelectedBox(null);
      setBox(null);
    } else if (ids.length === 2) {
      const expandedId = ids[1];
      const b = boxes.find((rb: RepackBox) => rb.publicId === expandedId);

      setExpandedRowIds([expandedId]);
      newSnackbarMessage(
        "Please close the other box before opening this one.",
        "warning"
      );
      if (b) {
        setSelectedBox(b);
        setBox(b);
      } else {
        console.error("Box not found");
        newSnackbarMessage(
          "Box not found.",
          "error"
        );
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 150,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "repackBoxTypeId",
      headerName: "Repack Type",
      width: 200,
      valueGetter: (params) => mapRepackTypeName(params.row),
    },
    {
      field: "datesActive",
      headerName: "Dates Active",
      width: 200,
      valueGetter: (params) => {
        const dateStr = params.row.datesActive?.lowerBound;
        if (!dateStr) return "";
        const date = new Date(dateStr);
        return date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
    { field: "containsUnicorn", headerName: "Contains Unicorn", width: 200 },
  ];

  const mapRepackItemTypeName = (item: RepackItem) => {
    const type = itemTypes.find(
      (type: RepackItemType) => type.id === item.repackItemTypeId
    );
    return type ? type.name : "Unknown Type 2";
  };

  const mapRepackItemName = (item: RepackItem) => {
    if (item.repackCard) {
      return item.repackCard ? item.repackCard.name : "Unknown Card";
    } else if (item.repackTicket) {
      return item.repackTicket.repackTicketType ? item.repackTicket.repackTicketType?.name : "Unknown Ticket";
    } else {
      return "Unknown Item";
    }
  };

  const mapRepackItemId = (item: RepackItem) => {
    if (item.repackCard) {
      return item.repackCard ? item.repackCard.inventoryId : 0;
    } else if (item.repackTicket) {
      return item.repackTicket.id ?? 0;
    } else {
      return 0;
    }
  };

  const mapRepackItemComp = (item: RepackItem) => {
    if (item.repackCard) {
      return item.repackCard ? item.repackCard.comp : 0;
    } else if (item.repackTicket) {
      return item.repackTicket.cost ?? 0;
    } else {
      return 0;
    }
  };

  const mapRepackItemCost = (item: RepackItem) => {
    if (item.repackCard) {
      return item.repackCard ? item.repackCard.cost : 0;
    } else if (item.repackTicket) {
      return item.repackTicket.cost ?? 0;
    } else {
      return 0;
    }
  };

  const mapRepackItemDifference = (item: RepackItem) => {
    return mapRepackItemCost(item) - mapRepackItemComp(item);
  };

  const handleDeleteItem = async (item: any, user: any) => {
    const response = await DeleteItem(item, user);
    if (response.ok) {
      const deletedItem = await response.json();
      const deletedItemId = deletedItem.result.id;
      const updatedItems = box?.repackItems.filter((b: any) => b.id !== deletedItemId);
      setBox({ ...box, repackItems: updatedItems });
      setSelectedBox({ ...selectedBox, repackItems: updatedItems });
      newSnackbarMessage("Item deleted successfully!", "success");
    } else {
      newSnackbarMessage("Failed to delete item.", "error");
    }
  };



  const itemColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Item Id",
      width: 100,
      valueGetter: (params) => mapRepackItemId(params.row),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      valueGetter: (params) => mapRepackItemName(params.row),
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: true,
      valueGetter: (params) => mapRepackItemTypeName(params.row),
      renderEditCell: (params) => {
        return (
          <Autocomplete
            fullWidth
            value={selectedItemType}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedItemType(newValue as RepackItemType);
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newValue,
                });
              }
            }}
            inputValue={selectedItemTypeName}
            onInputChange={(event, newInputValue) => {
              setSelectedItemTypeName(newInputValue);
            }}
            options={itemTypes}
            getOptionLabel={(option) => {
              return typeof option === "string" ? option : option.name;
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" fullWidth />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                {typeof option === "string" ? option : option.name}
              </li>
            )}
            freeSolo
            filterOptions={(options, params) => {
              const filtered = options.filter((option) =>
                option.name
                  .toLowerCase()
                  .includes(params.inputValue.toLowerCase())
              );

              if (
                params.inputValue !== "" &&
                !filtered.some((item) => item.name === params.inputValue)
              ) {
                filtered.push({ id: 0, name: `Add "${params.inputValue}"` });
              }

              return filtered;
            }}
            onBlur={() => handleUpdateRepackItems(params.row)}
          />
        );
      },
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 90,
      valueGetter: (params) => mapRepackItemComp(params.row),
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 90,
      valueGetter: (params) => mapRepackItemCost(params.row),
    },
    {
      field: "difference",
      headerName: "Difference",
      width: 90,
      valueGetter: (params) => mapRepackItemDifference(params.row),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 50,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => handleDeleteItem(params.row, user)}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];


  const getDetailPanelContent = () => {
    const rowItems = box?.repackItems ?? [];

    if (!rowItems.length) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="flex"
          flexDirection="column"
        >
          <Grid item padding={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50%"
            >
              {/* <CircularProgress /> if loading */}
              {boxLoading && <CircularProgress />}
              {!boxLoading && (
                <Typography variant="h6" color="textSecondary">
                  There are no items in this box yet
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={handleDeleteRepackBox}>
              <DeleteIcon />
              Delete Box
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid>
        <DataGridPro
          getRowId={(row) => row.publicId || row.id}
          rows={rowItems}
          columns={itemColumns}
          processRowUpdate={(newRow) => {
            const updatedRow = handleUpdateRepackItems(newRow);
            return updatedRow;
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing item update:", error);
            newSnackbarMessage("Error processing item update.", "error");
          }}
        />
        <Divider />
        <Button variant="text" onClick={handleDeleteRepackBox}>
          <DeleteIcon />
          Delete Box
        </Button>
      </Grid>
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Select
            fullWidth
            id="select-repack-box-type"
            value={selectedBoxType}
            onChange={handleSelectedBoxTypeChange}
          >
            {boxTypes.map((type: RepackBoxType) => (
              <MenuItem key={type.publicId} value={type.id}>
                {type.name || "Unknown Type"}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={handleCreateBox}>
            Add New Box
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  const transformedRows = boxes.map((b: RepackBox) => ({
    ...b,
    id: b.publicId || b.id,
  }));

  return (
    <div style={{ height: "auto", minHeight: "400px", width: "100%" }}>
      <DataGridPro
        getRowId={(row) => row.id}
        rows={transformedRows}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 250}
        detailPanelExpandedRowIds={expandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
        disableMultipleRowSelection={true}
        onRowClick={handleRowClick}
        processRowUpdate={(newRow) => {
          return handleUpdateRepackBox(newRow);
        }}
        onProcessRowUpdateError={(error) => {
          console.error("Error processing box update:", error);
          newSnackbarMessage("Error processing box update.", "error");
        }}
        components={{ Toolbar: CustomToolbar }}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default StagedRepackPreview;
