import React, { useState } from "react";
import {
  Button,
} from "@mui/material";

import { FileUploaderProvider } from "../FileUploader/FileUploaderContext";
import CardImportModalContent from "./CardImportModalContent";
import { useCardDashboard } from "../../Dash/SinglesPage/CardDashboardContext";

export default function CardImportButton() {
  const [purchasedBy, setPurchasedBy] = useState("");
  const [open, setOpen] = useState(false);
  const {users} = useCardDashboard();

  const handleClickOpen = () => {
    setOpen(true);
  };

 

  
  return (
    <>
      <FileUploaderProvider endpoint={`/Card/import/${purchasedBy}`} accepts='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' maxFiles={1}>
        <CardImportModalContent open={open} setOpen={setOpen} users = {users} setPurchasedBy = {setPurchasedBy} purchasedBy = {purchasedBy}/>
      </FileUploaderProvider>
      <Button onClick={handleClickOpen}>Import Cards</Button>
    </>
  );
}
