import * as React from 'react';
import {createContext, useContext, useState} from 'react';
import useFetch from '../../Components/CommonUtility/useFetch';
import { IShowSaleType } from './IShowSaleType';
import { useSingleShow } from '../ShowContexts/SingleShow/SingleShowContext';


const ShowSaleTypesContext = createContext<ShowSaleTypesContextProps | null>(null);
interface ShowSaleTypesContextProps {
    selectedSaleType: IShowSaleType | null,
    setSelectedSaleType: (saleType: IShowSaleType | null) => void,
    saleTypes: Array<IShowSaleType>,
    setSaleTypes: (saleTypes: Array<IShowSaleType>) => void,
    saleTypesLoading: boolean,
    saleTypesError: boolean,
    endpoint: string,
}

type ShowSaleTypesProviderProps = {
    children?: React.ReactNode;
}

export const ShowSaleTypesProvider = ({children} : ShowSaleTypesProviderProps) => {
    const {show} = useSingleShow();
    const [selectedSaleType, setSelectedSaleType] = useState<IShowSaleType | null>(null);
    const [endpoint, setEndpoint] = useState("");

    const {data: saleTypes, setData: setSaleTypes, loading: saleTypesLoading, error: saleTypesError}: 
        { data: Array<IShowSaleType>, setData: any, loading: boolean, error: any }
        = useFetch("/ShowSale/types", true, [])
    React.useEffect(() => {
        if (selectedSaleType == null && saleTypes.length > 0) {
            setSelectedSaleType(saleTypes[0])
        }
    }, [saleTypes])

    React.useEffect(() => {
        setEndpoint("/ShowSale/all/" + show?.publicId +"/" +selectedSaleType?.publicId)
    }, [selectedSaleType])

    const contextValues = {
        selectedSaleType, setSelectedSaleType,
        saleTypes, setSaleTypes, saleTypesLoading, saleTypesError, endpoint
    }
    return (
        <ShowSaleTypesContext.Provider value={contextValues}>
            {children}
        </ShowSaleTypesContext.Provider>
        )
}

export function useShowSaleTypes() {
    const context = useContext(ShowSaleTypesContext);
    if (!context) {
        throw new Error("Invalid ShowSaleTypesContext, must be wrapped in a ShowSaleTypesProvider")
    }
    return context;
}