import FilterList from "../../../Components/CommonUtility/ServerSide/FilterPresets/FilterList";
import BulkChangeStatus from "./BulkChangeStatus";
import "../../Hamburger.css";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridFilterModel,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import "../singles.css";
import { Card } from "../Types/CardType";
import CardExport from "../CardExport";
import { FilterPresetTypeNames } from "../../../Components/CommonUtility/ServerSide/FilterPresets/FilterPresetTypeNames";
import { useCardDashboard } from "../CardDashboardContext";

interface CardsToolbarProps {
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  snackbar: any;
  setSnackbar: React.Dispatch<React.SetStateAction<any>>;
  onFilterChange: (filter: any) => void;
  premadeFilter: GridFilterModel;
  selectedRows: Card[];
  colOrder: string[];
  colVis: any;
  changeStatusOnLiveCells: any;
  filterModel: any;
  setFilterModel: any;
}

const CardsToolbar: React.FC<CardsToolbarProps> = ({
  refresh,
  setRefresh,
  selectedRows,
  colOrder,
  colVis,
  changeStatusOnLiveCells,
  filterModel, setFilterModel
}) => {
  
  const {filterList} = useCardDashboard();

  const handleClearFilters = () => {
    setFilterModel({
      items: [],
      quickFilterValues: [],
    });
    setRefresh(!refresh);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <Button
        startIcon={<DeleteIcon />}
        onClick={handleClearFilters}
      >
        Clear Filters
      </Button>
      <FilterList
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterModel={setFilterModel} 
        filterList={filterList}
        filterModel={filterModel}
        deleteUrl="/Filter/delete"
        saveUrl="/Filter/save"
        filterPresetName={FilterPresetTypeNames.CARD_FILTER_FOR_OPTIONS}  
      />
      <BulkChangeStatus 
        selectedRows={selectedRows} 
        changeStatusOnLiveCells={changeStatusOnLiveCells}
      />
      <CardExport filterModel={filterModel} colOrder={colOrder} colVis={colVis} />
    </GridToolbarContainer>
  );
};

export default CardsToolbar;
