const compareObjects = (o1: any, o2: any) => {
  return JSON.stringify(o1) === JSON.stringify(o2);
}

const propogateChanges = (newRow: any, oldRow:any, rows:any, isBulk:boolean=false) => {

  const changedFields: string[] = [];
  const refOldRow = {...oldRow};
  const refNewRow = {...newRow};

  for (const field in newRow) {
    if (newRow[field] !== refOldRow[field]) {
      changedFields.push(field);
    }
  }

  const updatedRows = rows.map((row: any) => {
    if (row.id !== oldRow.id && !isBulk) {
      return row;
    }
    changedFields.forEach((field: string) => {
      if(!row[field] || compareObjects(row[field], refOldRow[field])){
        row[field] = refNewRow[field];
      }
    });
    return row;
  });

  return updatedRows;
}


export default propogateChanges;