
import React, {useState} from 'react';
import {Button, Input, InputGroup} from 'reactstrap';
import FetchModule from "../../Modules/FetchModule";
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import JournalItem from './JournalItem';
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const fetchData = new FetchModule();

const useFetchPromise = () => {
    return React.useCallback(
      (link, type, data) =>
        new Promise((resolve, reject) => {
          fetchData.muiFetchFormData(link, type, data).then((response) => {
            if (response.status == 200) {
              resolve(response)
            } else {
              reject(new Error("Error when saving"))
            }
          })
        })
    )
  }
export default function SaleJournalMain(props) {

    const [data, setData] = React.useState({
        fail: [],
        partial: [],
        success: []
    });
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);

    }
    const [selectedFile, setSelectedFile] = useState(null);
    const fetchPromise = useFetchPromise()
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [open, setOpen] = useState(false)
    React.useEffect(async function loadRows() {
        var response = await fetchData.fetchResponseAuthed("/ManualJournal/all", "GET")
        .then((res) => res.json())
        .then((data) => {return data});
        setData(response)
      }, [])
    async function addFile() {
        if (!props.open) {
            setOpen(true)
            const response = await fetchPromise("/BulkUpload/newManualJournals", "POST", selectedFile)
            if (response.status == 200 ) {
                var newData = await response.json()
                var combinedData = data
                combinedData["fail"] = [...combinedData["fail"], ...newData["fail"]]
                combinedData["partial"] = [...combinedData["partial"], ...newData["partial"]]
                combinedData["success"] = [...combinedData["success"], ...newData["success"]]
                setData(combinedData)
                setSnackbar({ children: "Uploaded files", severity: 'success' });

            } else {
                setSnackbar({ children: "Something went wrong", severity: 'error' });
            }
            setOpen(false)
        }
    }
    async function NewResolve(params) {
      if (!open) {
        setOpen(true)
        const response = await fetchPromise("/ManualJournal/resolved/"+params.row.saleId, "PUT")
        if (response.status === 200) {
          const newData = await response.json()
          setData(newData)
        }
        setOpen(false)
      }
    }
    function getBody() {
        return (
            <Box sx={{flexGrow: 1}}>
                <Grid>
                    <InputGroup>
                        <Input type="file" name="file" onChange={changeHandler} />
                        <Button onClick={addFile} color="primary" disabled={selectedFile === null}>Upload File</Button>
                    </InputGroup>
                </Grid>
                <Grid container space={3} sx={{flexGrow: 0, height: .95}}>
                    
                    <Grid xs={4}>
                        <JournalItem title="Failed to Post" rows={data.fail} resolve={NewResolve}/>
                    </Grid>
                    <Grid xs={4}>
                        <JournalItem title="Failed, has manual journal" rows={data.partial} resolve={NewResolve}/>
                    </Grid>
                    <Grid xs={4}>
                        <JournalItem title="Uploaded" rows={data.success}  resolve={NewResolve}/>
                    </Grid>

                </Grid>
                {!!snackbar && (
          <Snackbar open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={10000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            </Box>
        )
    }
      return getBody()
    
    /*
    return (
        <MenuNavigation props={props}
          name="dashboard-body"
            body={getBody()}
        />
    )   */
}
