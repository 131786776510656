import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import { Button } from "reactstrap";
import { useCardDashboard } from "../CardDashboardContext";
import { useAddSkuModal } from "./AddSkuModalContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import FetchModule from "../../../Modules/FetchModule";
import { useState } from "react";

export type AddSkuModalProps = {
  open:boolean;
  toggleOpen: (open:boolean) => void;
  addToDataGridFunction:any;

}

const fetchData = new FetchModule();

export const AddSkuModal = ({open,toggleOpen,addToDataGridFunction}:AddSkuModalProps) => {
  const {updateSkus} = useCardDashboard();
  const {dialogValue,setDialogValue,field}  = useAddSkuModal();

  const { user } = useUser();
  const [duplicate, setDuplicate] = useState<any>("");

  const handleClose = () => {
    setDialogValue({
      label: "",
      value: "",
      publicId: "",
    });

    toggleOpen(false);
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

      const data = {
        label: dialogValue.label,
        value: dialogValue.value.toUpperCase(),
        cardSkuItemPublicId: dialogValue.publicId,
        publicId: null
      };
      const response = await fetchData.promise("/Card/create/skuitem", true, "POST", {
        UserId: user["Id"],
        Body: data,
      });
      if (response.status === 200) {
        const skuField = field === "setVariety" ? "Set" : capitalizeFirstLetter(field);
        const guid = await response.json();
        data.publicId = guid;
        addToDataGridFunction(data);
        updateSkus(skuField, data);
        handleClose();
      }

    
  };

  const shouldDisable = () => {
    if(dialogValue.value.length > 12){
      return true;
    }

    if (field === "grade") {
      return dialogValue.label === "" || dialogValue.value === "" || !dialogValue.rank || duplicate;
    }
    return dialogValue.label === "" || dialogValue.value === "" || duplicate;
  };

  return(
  <Dialog open={open} onClose={handleClose}>
    <form onSubmit={handleSubmit}>
      <DialogTitle>Add a new attribute and SKU relations</DialogTitle>
      <DialogContent>
        {field !== "grade" && (
          <DialogContentText style={{ marginBottom: "10px" }}>
            Missing any {field}? Please add it with associated SKU.
          </DialogContentText>
        )}
        {field === "grade" && (
          <DialogContentText style={{ marginBottom: "10px" }}>
            Missing a {field}? Please add it with associated SKU and rank.
            Ranks are created in ascending order.
          </DialogContentText>
        )}
        <TextField
          autoFocus
          id="label"
          value={dialogValue.label || ""}
          onChange={(event) => {
            setDialogValue({
              ...dialogValue,
              label: event.target.value,
            });
          }}
          label="Name"
          type="text"
          variant="outlined"
          size="small"
          style={{
            fontSize: "0.75rem",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        />
        <TextField
          id="value"
          value={dialogValue.value || ""}
          onChange={(event) =>
            setDialogValue({
              ...dialogValue,
              value: event.target.value,
            })
          }
          onBlur={() => {
            const url = "/Card/test/sku/" + field + "/" + dialogValue.value;
            fetchData.fetchResponseAuthed(url, "GET").then((res) => {
              if (res.status === 200 || res.status === 204) {
                res.text().then((data) => {
                  setDuplicate(data);
                });
              }
            });
          }}
          label="SKU"
          type="text"
          variant="outlined"
          size="small"
          style={{
            fontSize: "0.75rem",
            marginBottom: "10px",
            marginTop: "10px",
            marginLeft: "10px",
          }}
        />
        {field === "grade" && (
          <TextField
            value={dialogValue.rank}
            onChange={(event) =>
              setDialogValue({ ...dialogValue, rank: event.target.value })
            }
            label="Rank"
            type="number"
            variant="outlined"
            size="small"
          />
        )}
        {duplicate && <p style={{ color: "red" }}>Duplicate SKU: {duplicate}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={shouldDisable()} type="submit">
          Add
        </Button>
      </DialogActions>
    </form>
  </Dialog>)
}