import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid";
import { GetDefaultFilter } from "./ServerSideDefaults";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";

export type FilterProps = {
  localStorageKey: string;
  gridApiRef?: any;
}

export type FilterContextProps = {
  filterModel: GridFilterModel,
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>,
  updateFilterModel: (field:string,operator:string,value:any) => void
  getPreprocessedFilterModel: () => GridFilterItem[]
}

export const useFilter = ({localStorageKey, gridApiRef} : FilterProps) => {
  const [filterModel, setLocalFilterModel] = useLocalStorage(localStorageKey,GetDefaultFilter());

  const compareOperators = (item1: string, item2: string) => {
    const normalize = (str: string) => str.replace(/\s+/g, '').toLowerCase();
    return normalize(item1.toLowerCase()) === normalize(item2.toLowerCase());
  }
  
  function setFilterModel(model: any) {
    if (gridApiRef !== undefined) {
      gridApiRef.current.setFilterModel(model);
    }
    setLocalFilterModel(model);
  }

  const updateFilterModel = (field:string,operator:string,value:any) => {
    const updatedFilterModel = {
      ...filterModel,
      items: filterModel.items.map((item: any) => {
        if (item.field === field && compareOperators(item.operator, operator)) { 
          return {
            ...item,
            value: value,
          };
        }
        return item;
      }),
    };
    setFilterModel(updatedFilterModel);
  }

  const getPreprocessedFilterModel = ()=>{
    const filteredItems = filterModel.items.filter((filterItem: GridFilterItem) => {
      if (
        filterItem.operator === "isEmpty" ||
        filterItem.operator === "isNotEmpty" ||
        filterItem.operator === "isGraded" ||
        filterItem.operator === "isRaw"
      ) {
        return true;
      }
    
      if (filterItem.field === 'inventoryId' && filterItem.value === "") {
        return false;
      }
      return !(filterItem.value === undefined || filterItem.value === null);
    });
    
    return {
      ...filterModel,
      items: filteredItems,
    };
  }
  return {
    filterModel,
    setFilterModel,
    updateFilterModel,
    getPreprocessedFilterModel
}
}




