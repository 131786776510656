import { Box, Typography } from '@mui/material';

const ErrorPage = () => (
    <Box
        sx={{
            backgroundColor: 'neutral.900',
            width: '100%',
            height: '100%',
        }}
        >
        <Typography
            align="center"
            color="background.paper"
            variant="h2"
        >
            404: Page not found
        </Typography>

    </Box>
  );
  
  
  export default ErrorPage;
  