export const updateVisibilityWithNewColumn = (visibility:any,newKey:string,val:boolean) =>{
  const newVisibility = {...visibility};
  newVisibility[newKey] = val;
  return newVisibility;
}

export const updateWidthWithNewColumn = (width:any,newKey:string,val:number) =>{
  const newWidth = {...width};
  newWidth[newKey] = val;
  return newWidth;
}

export const updateOrderWithNewColumnAfterField = (order:string[],newKey:string,field:string) =>{
  const fieldIndex = order.indexOf(field);
  if (fieldIndex === -1) {
    throw new Error(`Field ${field} not found in order array`);
  }
  const newOrder = [...order];
  newOrder.splice(fieldIndex + 1, 0, newKey);
  return newOrder;
}

