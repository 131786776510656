import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useUser } from '../CommonUtility/UserContext';
import { useShow } from '../../Contexts/ShowContexts/Shows/ShowContext';
import { IShowChannel } from '../../Contexts/ShowContexts/ShowChannels/IShowChannel';
import { GetShowChannels } from '../../Contexts/ShowContexts/ShowChannels/ShowChannelAPICalls';
import { Tooltip } from '@material-ui/core';

export default function ShowChannelAutocomplete() {
  const {user} = useUser()
  const {platform, channel, setChannel, platformValid} = useShow()
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<IShowChannel[]>([]);
  const loading = open && options.length === 0;

  async function refreshShowChannelData() {
    const response = await GetShowChannels(platform?.publicId, user)
    if (response.status == 200) {
        const data = await response.json()
        setOptions(data)
    }
}

    React.useEffect(() => {

    if (!loading) {
      return undefined;
    }

    (async () => {
      await refreshShowChannelData()
    })();

  
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={!platformValid()}
      value={channel}
      onChange={(e: any, newValue: IShowChannel | null) => {setChannel(newValue)}}
      isOptionEqualToValue={(option: IShowChannel, value: IShowChannel) => option.id === value.id}
      getOptionLabel={(option: IShowChannel) => option.channelName}
      options={options}
      loading={loading}
      renderInput={(params) => {
        return (
          <Tooltip title="Select a platform first" placement="top">
        <TextField
          {...params}
          label="Show Channel"
          disabled={!platformValid()}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
        </Tooltip>
      )
    }
    
    }
    />
  );
}
