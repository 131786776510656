import React, { useState } from "react";
import {
  Button,
} from "@mui/material";

import { FileUploaderProvider } from "../../../../Components/FileUploader/FileUploaderContext";
import UploadChecklistModal from "./UploadChecklistModal";

interface UploadChecklistButtonProps {
  dataFields: any;
  setDataFields: React.Dispatch<React.SetStateAction<any>>;
}

const UploadChecklistButton: React.FC<UploadChecklistButtonProps> = () => {
  
  const [open, setOpen] = useState<boolean>(false);


  const handleClickOpen = () => {
    setOpen(true);
  };




  return (
    <>
      <FileUploaderProvider endpoint={`/Checklist/new`} accepts='text/csv' maxFiles={1}>
        <UploadChecklistModal open={open} setOpen={setOpen} />
      </FileUploaderProvider>
      <Button onClick={handleClickOpen}>Upload Checklist</Button>
    </>
  );
};

export default UploadChecklistButton;
