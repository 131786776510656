import React from 'react';
import {createContext, useContext} from 'react';
import FetchModule from '../../../Modules/FetchModule';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import useFetchPromise from '../../../Components/CommonUtility/useFetchPromise';
const fetchData = new FetchModule();

const SkuCorrectionContext = createContext<SkuCorrectionProps | null>(null)
interface SkuCorrectionProps {
    open: boolean,
    setOpen: (name: boolean) => void,
    datafields: any,
    setDatafields: any,
    checkSku:any,
    submit:any
}

type SkuCorrectionProviderProps = {
  datafields: any;
  setDatafields:any;
  children: React.ReactNode;
  open: boolean;
  setOpen: (name: boolean) => void;
  updateSku: any;
  url?: any;
  body?: any;
  toggle?: any;
}


export const SkuCorrectionProvider = ({datafields,setDatafields,open,setOpen,children, updateSku,url,body,toggle} : SkuCorrectionProviderProps) => {
    
    const { user } = useUser();
    const fetchPromise = useFetchPromise();
    
    const updateRow = (rowRef:any,label:string,deleteFlag:boolean) => {
        const updatedRows = datafields.map((row:any) =>{
            if(row.publicId == rowRef.publicId){
                if(deleteFlag){
                    delete row.conflict
                    delete rowRef.conflict
                    return rowRef;
                }
                else{
                    rowRef.conflict = label
                    return rowRef;
                }
                
            }
            else{
                return row;
            }
        });
        setDatafields(updatedRows);
    } 

    const checkSku = (row:any) => {
        const url = "/Card/test/sku/" + row.type + "/" + row.value;
        fetchData.fetchResponseAuthed(url, "GET").then((res) => {
          if (res.status === 200 || res.status === 204) {
            res.text().then((data) => {
                if(data){
                    updateRow(row,data,false)
                }
                else{
                    updateRow(row,"",true)
                }
                
            });
          }
        });
    }

    const submit = async (event:any) =>{
        event.preventDefault();
        await datafields.forEach(async (row:any) => {
            const sData = {
                UserId: user["id"],
                Body: row,
                PublicId: row.publicId,
                rank: row.rank,
            };
            const response = await fetchData.promise("/Card/update/skuitem", true, "PUT", sData);
            if (response.status === 200) {
                let type: string = row.type;
                if(type.includes("set")){
                    type = "set";
                }
                const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
                updateSku(capitalizedType, row);
                if(url && body){

                    await fetchPromise(url.url, url.method, body); 
                }
            }
        })
        if(toggle){
            toggle();
        }
        else{
            setOpen(false)
        }
    }


    const contextValues: SkuCorrectionProps = {
        datafields,setDatafields,open,setOpen,checkSku,submit
    }
    return (
        <SkuCorrectionContext.Provider value={contextValues}>
            {children}
        </SkuCorrectionContext.Provider>
        )
}

export function useSkuCorrection() {
    const context = useContext(SkuCorrectionContext);
    if (!context) {
        throw new Error("Invalid SkuCorrection, must be wrapped in a SkuCorrectionContext")
    }
    return context;
}