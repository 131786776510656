import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ManageAccounts } from '@mui/icons-material';

const ShowHostQuicknavButton: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<ManageAccounts />}
            onClick={() => {
                navigate("/dashboard/showhosts")
            }}
        >
            Manage Hosts
        </Button>
    );
};

export default ShowHostQuicknavButton;