import PantheonMainPage from "../Dash/PantheonPage/PantheonMainPage"
import { Box } from '@mui/material';




export const PantheonPage = (props) => {



    return (
        <Box sx={{
            width: '100%', 
            height: '100%', 
            }}>
            <PantheonMainPage type={props.type}/>
                    
        </Box>

    )
}