import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import {
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "./CustomToolbar.css";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";
import useFetchPromise from "../../../Components/CommonUtility/useFetchPromise";
import FetchModule from "../../../Modules/FetchModule";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";

interface Cardlist {
  label: string;
  id: number;
  cards: any[];
}

const fetchData = new FetchModule();
const useFetchGetSet = (url: string, type: any) => {
  const [data, setData] = useState<Cardlist[] | null>(null);
  useEffect(() => {
    fetchData
      .fetchResponseAuthed(url, type)
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [type, url]);

  // Return both data and setData
  return [data, setData] as [
    Cardlist[] | null,
    React.Dispatch<React.SetStateAction<Cardlist[] | null>>
  ];
};

const CreateBulkCards: React.FC = () => {
  const fetchPromise = useFetchPromise();
  const { user } = useUser();
  const [modal, setModal] = useState(false);
  const [cardlists] = useFetchGetSet(
    "/Card/all/cardlists",
    "GET"
  ) as any;
  const [label, setLabel] = useState<Cardlist | null>(null);
  const [newCardlistName, setNewCardlistName] = useState("");
  const [isCardlistNameValid, setIsCardlistNameValid] = useState(true);
  const types = [
    { value: "raw", label: "Raw" },
    { value: "graded", label: "Graded" },
    { value: "pending", label: "Pending Reception" },
    { value: "retail", label: "Retail" },
  ];
  const [type, setType] = useState("");
  const [showUpdateMessage, setShowUpdateMessage] = useLocalStorage(
    LocalStorageKey.CARD_BULK_SHOWUPDATEMESSAGE,
    true
  );
  const [checked, setChecked] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const findPublicIdByLabel = (targetLabel: string) => {
    const matchedCard = cardlists?.find(
      (card: any) => card.label === targetLabel
    );
    return matchedCard ? matchedCard["publicId"] : "";
  };

  const handleCreateRecords = async () => {
    if (label && label.id === -1) {
      const body = {
        body: {
          label: newCardlistName,
          UserId: user["id"],
          listType: type,
        },
      };
      const response = await fetchPromise("/Card/new/cardlist", "POST", body);
      const data = await response.json();
      const publicId = data["publicId"];
      window.location.href = `/dashboard/cards/bulk?label=${newCardlistName}&publicId=${publicId}`;
      toggleModal();
      return;
    }
    // Redirect based on label
    const publicId = findPublicIdByLabel(label?.label ? label.label : "");
    window.location.href = `/dashboard/cards/bulk?label=${label?.label}&publicId=${publicId}`;
    toggleModal();
  };

  const validateNewCardlistName = (name: string) => {
    if (cardlists) {
      const isValid = !cardlists.some(
        (cardlist: any) => cardlist.label.toLowerCase() === name.toLowerCase()
      );
      setIsCardlistNameValid(isValid);
    }
  };

  const updateMessage = (
    <div style={{ marginBottom: "1rem" }}>
      <div>
        <p>
          Bulk import card lists are now shared across multiple users. When
          making changes, ensure that there is only one person working on a
          given list at a time to avoid losing changes.
        </p>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              color="primary"
            />
          }
          label="Don't show this message again"
        />
      </div>
    </div>
  );

  useEffect(() => {
    setNewCardlistName("");
    setType("");
  },[label])

  const isDisabled = () =>{
    if(label === null){
      return true
    }
    if(label.id === -1){
      return !isCardlistNameValid || newCardlistName === "" || type === "" ;
    }
    return false
  }

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Make Cards In Bulk
      </Button>
      <Modal isOpen={modal} toggle={toggleModal} className="add-cards-modal">
        <ModalHeader toggle={toggleModal}>Add Cards</ModalHeader>
        <ModalBody>
          {showUpdateMessage && updateMessage}
          {cardlists !== null && ( // Check if cardlists is not null
            <Autocomplete
              options={[
                { label: "New Card List", id: -1, cards: [] },
                ...cardlists.map((cl: any) => ({ label: cl.label, id: cl.id })),
              ]}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Card List"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue: any) => {
                setLabel(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === "") {
                  setLabel(null);
                }
              }}
            />
          )}
          {label && label.id === -1 && (
            <>
              <TextField
                label="New Card List Name"
                variant="outlined"
                fullWidth
                value={newCardlistName}
                onChange={(e) => {
                  setNewCardlistName(e.target.value);
                  validateNewCardlistName(e.target.value);
                }}
                error={!isCardlistNameValid}
                helperText={
                  !isCardlistNameValid ? "Card list name already exists." : ""
                }
                style={{ marginTop: "1rem" }}
              />
              <Autocomplete
                options={types}
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Type"
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) => {
                  setType(newValue ? newValue.value : "");
                }}
                style={{ marginTop: "1rem" }}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="add-cards-button"
            onClick={() => {
              handleCreateRecords();
              setShowUpdateMessage(!checked);
            }}
            disabled={isDisabled()}
          >
            Add Card(s)
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateBulkCards;
