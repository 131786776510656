import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../Hamburger.css';
import Switch from "react-switch";
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const fetchData = new FetchModule();

export default class BreakList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: "",
            sessionId: 0,
            options: [],
            selectedValue: null,
            session: null,
            isOpen: false,
            disabled: true,
            saveDisabled: false,
            startDate: null,
            confirmDelete: false,
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[3]
    }
    async componentDidMount () {
        var response = await fetchData.fetchResponseAuthed("/Breaking/session/"+this.state.sessionId, "GET")
        if (response.status == 200) {
            var data = await response.json()
            this.updateState("session", data)
            if (data.salesRep != "") {
                this.updateState("selectedValue", {
                    value: data.salesRep,
                    label: data.salesRep
                })
                if (this.state.salesRep != "") {
                    this.updateState("disabled", false)
                }
                
            }
            this.updateState("startDate", new Date(data.date))
        }
        var response2 = await fetchData.fetchResponseAuthed("/SaleOrder/salesReps", "GET")
        if (response2.status == 200) {
            var data =await response2.json()
            this.updateState("options", data)
        }
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    onChange = async (val) => {
        var session2 = this.state.session
        var session = {
            id: session2.id,
            creationDate: session2.creationDate,
            salesRep: val.value,
            saleId: session2.saleId
        }
        var response = await fetchData.postNoCors("/Breaking/update/salesrep", "POST", session)
        if (response.status == 200) {
            var data = await response.json()
            this.updateState("session", data)
            this.updateState("selectedValue", {
                value: data.salesRep,
                label: data.salesRep
            })
            this.updateState('disabled', false)
        }
    }
    isDisabled = () => {
        if (this.state.session != null) {
            return this.state.session.saleId == "" ? false : true
        }
        return false
    }
    getSalesRepSetting = () => {
        return(
            <div className="row-display">
                Sales Rep:
                <Select
                    isDisabled={this.isDisabled()}
                    className="basic-single selectFix"
                    value={this.state.selectedValue}
                    options={this.state.options}
                    onChange={this.onChange}
                />
            </div>

        )
    }
    handleSwitchChange = async (event) =>{ 
        var session2 = this.state.session
        var session = {
            id: session2.id,
            creationDate: session2.creationDate,
            isLive: event,
            salesRep: session2.salesRep,
            saleId: session2.saleId
        }
        var response = await fetchData.postNoCors("/Breaking/update/live", "POST", session)
        if (response.status == 200) {
            var data =await response.json()
            this.updateState("session", data)
        }        
    }
    getIsLive = () => {
        if (this.state.session != null) {
            return (
                <div className="row-display">
                    Live: &nbsp;
                    <Switch 
                        disabled={this.isDisabled()}
                        onChange={this.handleSwitchChange} 
                        checked={this.state.session.isLive}
                    />
                </div>
            )
        }

    }
    createSO = async () => {
        this.updateState("saveDisabled", true)
        let response = await fetchData.promise("/Break/salesOrder/"+this.state.sessionId, true, "POST", "")
        if (response.status == 200) {
            let data = await response.json()
            this.updateState("session", data)
            this.updateState("isOpen", false)
        }
        this.updateState("saveDisabled", false)
    }
    voidSO = async () => {
        this.updateState("saveDisabled", true) 
        let response = await fetchData.promise("/Break/salesOrder/"+this.state.sessionId+"/void", true, "DELETE", "")
        if (response.status == 200) {
            let data = await response.json()
            this.updateState("session", data)
            this.updateState("isOpen", false)
        }
        this.updateState("saveDisabled", false)
    }
    toggleModal = () => {
        if (!this.state.saveDisabled) {
            this.updateState("isOpen", !this.state.isOpen)
        }
    }
    toggleDeleteModal = () => {
        if (!this.state.saveDisabled) {
            this.updateState('confirmDelete', !this.state.confirmDelete)
        }
    }
    getSaleOrder = () => {
        if (this.state.session != null) {
            if(this.state.session.saleId == "") {
                return (
                    <div className="row-display">
                        <Button color="primary" onClick={this.toggleModal} disabled={this.state.disabled}>Create Sales Order</Button>
                        <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
                            <ModalHeader toggle={this.toggleModal}></ModalHeader>
                            <ModalBody>Are you sure you want to create a sales order?
                                Please verify that all items within each break are correct.
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" disabled={this.state.saveDisabled} onClick={this.toggleModal}>Cancel</Button>
                                <Button color="primary" disabled={this.state.saveDisabled} 
                                    onClick={this.createSO}>Create</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                )
            }
            return (
                <div className="row-display">
                    <a href={"https://inventory.dearsystems.com/Sale#"+this.state.session.saleId}
                        target="_blank" rel="noreferrer"
                    >
                        {this.state.session.saleOrder}</a>
                    &nbsp;
                    <Button color="danger"
                        onClick={this.toggleDeleteModal} 
                        disabled={this.state.disabled}
                    >VOID</Button>
                    <Modal isOpen={this.state.confirmDelete} toggle={this.toggleDeleteModal}>
                    <ModalHeader toggle={this.toggleModal}></ModalHeader>
                        <ModalBody>Are you sure you want to create a sales order?
                            Please verify that all items within each break are correct.
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" disabled={this.state.saveDisabled} onClick={this.toggleDeleteModal}>Cancel</Button>
                            <Button color="primary" disabled={this.state.saveDisabled} 
                                onClick={this.voidSO}>Confirm</Button>
                        </ModalFooter>
                    </Modal>


                    
                </div>
            )
        }
    }
    setStartDate = async (date) => {
        var session = this.state.session
        session.date = date;
        var response = await fetchData.postResponseBody("/Break/update/session/date", "PUT", session)
        if (response.status == 200) {
            var data = await response.json()
            this.updateState("session", data)
            this.updateState("startDate", new Date(data.date))
        }

    }
    getDatePicker = () => {
        if (this.state.session != null) {
            var disabled = this.isDisabled()
            if (!disabled) {
                return (
                    <div className="row-display">
                        <DatePicker selected={this.state.startDate} onChange={this.setStartDate}/>
                    </div>
                )
            }
            return (
                <div className="row-display">
                    <DatePicker disabled selected={this.state.startDate} onChange={this.setStartDate}/>
                </div>
            )
        }
    }
    render() {
        return (
        <div className="dashboard-nav-right-flip">
            <div className="main-card">
                <div className="card-header">Settings Page</div>
                <div className="card-body" style={{padding: "10px"}}>
                    {this.getSalesRepSetting()}
                    {this.getIsLive()}
                    {this.getDatePicker()}
                </div>
                <div className="card-footer">
                    {this.getSaleOrder()}
                </div>
            </div>
        </div>
        )
    }

}
