import React, { createContext, useContext, useEffect, useState } from 'react';
import { AddPlayerModal } from './AddPlayerModal';
import { useCardDashboard } from '../CardDashboardContext';


type AddPlayerContextProps = {
  children: React.ReactNode;

}
type AddPlayerProviderProps = {
  setOpenPlayerAddModal: (value: boolean) => void;
  dialogValue: any;
  setDialogValue: (value: any) => void;
  launchAddPlayerModal: (field:string,option:any,addToDatagrid:any) => void;
  openPlayerAddModal: boolean;
}


// Create a context
const AddPlayerModalContext = createContext<AddPlayerProviderProps | null>(null);

// Provider component
export const AddPlayerModalProvider =  ({ children}:AddPlayerContextProps) => {
  const [openPlayerAddModal, setOpenPlayerAddModal] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    label: "",
    value: "",
    publicId: "",
  })
  const [addToFunction, setAddToFunction] = useState(null);
  const [refocus, ] = useState(null);
  const {setModalOpen} = useCardDashboard();
  useEffect(()=>{
      setModalOpen(openPlayerAddModal);  

  },[openPlayerAddModal])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const launchAddPlayerModal = (field:string,option:any,addToDatagrid:any) => {
    setDialogValue((prev)=>{
      return {
        ...prev,
        label: option,
      }
    });
    setAddToFunction(() => addToDatagrid);
    setOpenPlayerAddModal(true);
  }
  return (
    <AddPlayerModalContext.Provider value={{setOpenPlayerAddModal,dialogValue,setDialogValue,launchAddPlayerModal,openPlayerAddModal}}>
      {children}
      <AddPlayerModal 
        open={openPlayerAddModal} 
        toggleOpen={setOpenPlayerAddModal} 
        addToDatagridFunction = {addToFunction}
        refocus={refocus}/>
    </AddPlayerModalContext.Provider>
  );
};

// Custom hook to use the AutocompleteContext
export function useAddPlayerModal() {
  const context = useContext(AddPlayerModalContext);
  if (!context) {
    throw new Error('useAddPlayerModalContext must be used within a AddPlayerModalContextProvider');
  }
  return context;
}