import {Button, Card} from '@mui/material'
import {useState} from 'react'
import {useNavigate} from "react-router-dom";
import {Form, FormGroup, Input, Label} from 'reactstrap'
import { useUser } from '../CommonUtility/UserContext';
import api from '../../Modules/APIModules';

export const LoginForm = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const {setUser,setInvalidationReason} = useUser()
    const navigate = useNavigate();


    async function login(e) {
        e.preventDefault()
        const response = await fetch(api.getLink() +"/Signin", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        });
        if (response.status == 200) {
            var data = await response.json()
            var cookie = data["token"]
            document.cookie = "jwtToken="+cookie+";path=/"
            setUser(data)
            localStorage.setItem('user', username)
            localStorage.setItem('userData', JSON.stringify(data))
            localStorage.setItem("token", cookie)
            localStorage.setItem('noDimensions', false);
            localStorage.setItem('noBarcodes', false);
            localStorage.setItem('noPrice', false);
            localStorage.setItem('inHouse', false);
            navigate("/dashboard")
            //return true
        }
        else if(response.status == 401 || response.status == 400){
            setInvalidationReason("Invalid username or password")
        }
        return false
    }
    return (
        <Card sx={{
            padding: '20px',
            width: '600px',
        }}>
            <Form 
                style={{display: 'flex', 
                    flexDirection: 'column'
                }}
                onSubmit={login}
            >
                <FormGroup>
                    <Label>Username</Label>
                    <Input 
                        name="username"
                        type="text" 
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <Input 
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormGroup>
                <Button type='submit'>Login</Button>
            </Form> 
        </Card>

    )
}
