import React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { useShowHosts } from './ShowHostsContext';
import ShowHostModal from '../../../Components/ShowHostComponents/ShowHostModal';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { CreateHost } from './ShowHostAPICalls';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
const ShowHostContext = createContext<ShowHostsContextProps | null>(null)
interface ShowHostsContextProps {
    firstName: string, setFirstName: (name: string) => void,
    lastName: string, setLastName: (name: string) => void,
    displayName: string, setDisplayName: (name: string) => void,
    isActive: boolean, setIsActive: (name: boolean) => void,
    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createHost: () => void,
    clearData: (name: boolean) => void,
    createHostModalOpen: boolean, 
    setCreateHostModalOpen: (name: boolean) => void,
    closeCreateHostModal: (name: boolean) => void,
    firstNameValid: () => boolean,
    lastNameValid: () => boolean,
    displayNameValid: () => boolean,
}

type ShowHostProviderProps = {
    children?: React.ReactNode;
}

export const ShowHostProvider = ({children} : ShowHostProviderProps) => {
    // Might not need if we want the host modal to be somewhere else
    const {addHost} = useShowHosts();
    const {newSnackbarMessage} = useSnackbar();

    const maxComission = 0.05;
    const minComission = 0;
    const [firstName, setFirstName] = useState(""); 
    const [lastName, setLastName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [canCreate, setCanCreate] = useState(false);
    const [createHostModalOpen, setCreateHostModalOpen] = useState(false);
    const {user} = useUser();
    
    async function createHost() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreateHost(firstName, lastName, displayName, isActive, user);
                if (response.status == 200) {
                    const data = await response.json();
                    addHost(data);
                    newSnackbarMessage("Successfully created Host", 'success');
                    if (createHostModalOpen) {
                        setCreateHostModalOpen(false);
                        clearData();
                    }
                } else if (response.status == 409) {
                    const result = await response.json();
                    newSnackbarMessage("Error: " +result.message, 'error');
                } 
                else {
                    newSnackbarMessage("Error creating host, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating host, connection invalid please try again later", 'error');
            }

            setCanCreate(true)
        }
    }
    // Update the canCreate state based on the input fields for validation reasons
    useEffect(() => {
        if (firstNameValid() && lastNameValid() && displayNameValid()) {
            setCanCreate(true)
        } else {
            setCanCreate(false)
        }
    }, [firstName, lastName, displayName])

    function clearData() {
        setFirstName("");
        setLastName("");
        setDisplayName("");
        setIsActive(true);
    }
    function closeCreateHostModal() {
        setCreateHostModalOpen(false);
        clearData();
    }

    function firstNameValid() {
        return firstName.length > 0;
    }
    function lastNameValid() {
        return lastName.length > 0;
    }
    function displayNameValid() {
        return displayName.length > 0;
    }

    const contextValues = {
        firstName, setFirstName, firstNameValid,
        lastName, setLastName, lastNameValid,
        displayName, setDisplayName, displayNameValid,
        isActive, setIsActive,
        canCreate, setCanCreate,
        createHost,
        clearData,
        createHostModalOpen, setCreateHostModalOpen, closeCreateHostModal,
        minComission, maxComission
    }
    return (
        <ShowHostContext.Provider value={contextValues}>
            <ShowHostModal />
            {children}
        </ShowHostContext.Provider>
        )
}

export function useShowHost() {
    const context = useContext(ShowHostContext);
    if (!context) {
        throw new Error("Invalid ShowHostContext, must be wrapped in a ShowHostProvider")
    }
    return context;
}