import {Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Grid, FormControl } from '@mui/material';
import { useShowChannel } from '../../Contexts/ShowContexts/ShowChannels/ShowChannelContext';
import PlatformAutoComplete from '../PlatformComponents/PlatformAutocomplete';

type ShowChannelModalProps = {
    children?: React.ReactNode;
}

const ShowChannelModal = ({} : ShowChannelModalProps)  => {
    const {
        channelName, setChannelName, channelNameValid,
        platform, setPlatform,
        canCreate,
        createChannel,
        createChannelModalOpen, closeChannelModal
    } = useShowChannel();
    
    return(
        <>
           <Dialog
                open={createChannelModalOpen}
                onClose={closeChannelModal}
           >
                <DialogTitle>Create new Show Channel</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField id="title" 
                                    label="Name" 
                                    value={channelName} 
                                    variant="standard"
                                    error={!channelNameValid()}
                                    onChange={(e: any) => {setChannelName(e.target.value)}}/>
                          </Grid>
                          <Grid item xs={12}>
                            <PlatformAutoComplete 
                                platform={platform}
                                setPlatform={setPlatform}
                            />
                          </Grid>
                          
                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {closeChannelModal(false)}}>Cancel</Button>
                    
                    <Button onClick={() => {createChannel()}} disabled={!canCreate}>Create</Button>
                    
                </DialogActions>
           </Dialog>
        </>
    )
}
export default ShowChannelModal;
  