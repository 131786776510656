import { Button, Divider, Dialog, DialogContent, DialogTitle, DialogActions, TextField,
     Grid, FormControl } from '@mui/material';
import { usePlatform } from '../../Contexts/ShowContexts/Platforms/PlatformContext';

type PlatformModalProps = {
    children?: React.ReactNode;
}

const PlatformModal = ({} : PlatformModalProps)  => {
    const {
        platformName, setPlatformName, platformNameValid,
        platformShorthand, setPlatformShorthand, platformShorthandValid,
        canCreate, 
        createPlatform,
        createPlatformModalOpen, closePlatformModal
    } = usePlatform();

    return(
        <>
           <Dialog
                open={createPlatformModalOpen}
                onClose={closePlatformModal}
           >
                <DialogTitle>Create new Platform</DialogTitle>
                <Divider />
                <DialogContent >
                    <FormControl
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                          }}
                          noValidate
                          autoComplete="off"
                    >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField id="title" 
                                    label="Name" 
                                    variant="standard"
                                    value={platformName} 
                                    error={!platformNameValid()}
                                    onChange={(e: any) => {setPlatformName(e.target.value)}}/>
                          </Grid>
                          <Grid item xs={6}>
                          <TextField id="title" 
                                    label="Shorthand" 
                                    variant="standard"
                                    value={platformShorthand} 
                                    error={!platformShorthandValid()}
                                    onChange={(e: any) => {setPlatformShorthand(e.target.value)}}/>
                          </Grid>
                        </Grid>

                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => {closePlatformModal(false)}}>Cancel</Button>
                    
                    <Button onClick={() => {createPlatform()}} disabled={!canCreate}>Create</Button>
                    
                </DialogActions>
           </Dialog>
        </>
    )
}
  
  
  export default PlatformModal;
  