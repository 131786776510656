import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useSnackbar } from "../../SnackbarContext/SnackbarContext";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";
import FetchModule from "../../../Modules/FetchModule";
import { RepackSeriesType } from "../../../Dash/RepackPage/Types/RepackSeriesType";
import { RepackBox } from "../../../Dash/RepackPage/Types/RepackBox";
import { RepackTicket } from "../../../Dash/RepackPage/Types/RepackTicket";
import useFetch from "../../../Components/CommonUtility/useFetch";
import { CreateStagedCards, CreateStagedTickets } from "./BuildRepacksAPICalls";
import { RepackItemType } from "../../../Dash/RepackPage/Types/RepackItemType";
import { RepackBoxType } from "../../../Dash/RepackPage/Types/RepackBoxType";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";

const fetchModule = new FetchModule();
const BuildRepacksContext = createContext<BuildRepacksContextProps | null>(
  null
);

interface HandleAddTicketParams {
  ticketName: string;
  setTicketName: React.Dispatch<React.SetStateAction<string>>;
  cost: number;
  setCost: React.Dispatch<React.SetStateAction<number>>;
  multipleTickets: boolean;
  setMultipleTickets: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfTickets: number;
  setNumberOfTickets: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  repackSeriesId: number;
}

interface BuildRepacksContextProps {
  // State properties and their setters
  boxTypes: RepackBoxType[];
  setBoxTypes: React.Dispatch<React.SetStateAction<RepackBoxType[]>>;
  itemTypes: RepackItemType[];
  setItemTypes: React.Dispatch<React.SetStateAction<RepackItemType[]>>;
  boxes: any[];
  setBoxes: React.Dispatch<React.SetStateAction<any[]>>;
  box: any;
  setBox: React.Dispatch<React.SetStateAction<any>>;
  boxLoading: any;
  selectedBox: RepackBox;
  setSelectedBox: React.Dispatch<React.SetStateAction<any>>;
  selectedCards: any[];
  setSelectedCards: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTickets: any[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<any[]>>;
  selectedType: RepackSeriesType | string;
  setSelectedType: React.Dispatch<
    React.SetStateAction<RepackSeriesType | string>
  >;
  selectedForRepack: any[];
  setSelectedForRepack: React.Dispatch<React.SetStateAction<any[]>>;
  tickets: RepackTicket[];
  setTickets: (ticket: any) => void;
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  ticketTypes: any[];
  setTicketTypes: React.Dispatch<React.SetStateAction<any[]>>;
  seriesList: any[];
  setSeriesList: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSeriesId: string;
  setSelectedSeriesId: React.Dispatch<React.SetStateAction<string>>;

  // Functions
  addToNewBox: () => void;
  addToSelectedBox: () => void;
  addToNewBoxTicket: () => void;
  addToSelectedBoxTicket: () => void;
  addToRepackStoreRepackVersion: (rows: any) => void;
  removeFromRepackStoreRepackVersion: (id: any) => void;
  addToRepackStore: (rows: any) => void;
  clearRepackStore: () => void;
  removeFromRepackStore: (id: any) => void;
  removeItemFromRepack: (itemId: any, boxId: any) => void;
  addToTickets: (product: any) => void;
  handleAddTicket: (params: HandleAddTicketParams) => void;
}
type BuildRepacksProviderProps = {
  children?: React.ReactNode;
};

export const BuildRepacksProvider = ({
  children,
}: BuildRepacksProviderProps) => {
  // CONTEXT
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();

  // STATE
  const { data: boxTypes, setData: setBoxTypes } = useFetch(
    "/Repack/get/types",
    true,
    []
  );
  const { data: itemTypes, setData: setItemTypes } = useFetch(
    "/Repack/get/itemTypes",
    true,
    []
  );
  const { data: tickets, setData: setTickets } = useFetch(
    "/Repack/get/alltickets",
    true,
    []
  );
  const { data: ticketTypes, setData: setTicketTypes } = useFetch(
    "/Repack/get/tickettypes",
    true,
    []
  );
  const { data: seriesList, setData: setSeriesList } = useFetch(
    "/Repack/get/allseries",
    true,
    []
  );
  const [selectedSeriesId, setSelectedSeriesId] = useState<string>(
    seriesList[0]?.publicId || ""
  );
  const [selectedBox, setSelectedBox] = useState<any>(null);

  const { data: boxes = [], setData: setBoxes } = useFetch(
    selectedSeriesId ? `/Repack/get/allstagedboxes/${selectedSeriesId}` : null,
    !!selectedSeriesId,
    []
  );

  const {
    data: box = {},
    setData: setBox,
    loading: boxLoading,
  } = useFetch(
    selectedBox?.publicId ? `/Repack/get/box/${selectedBox.publicId}` : null,
    !!selectedBox?.publicId,
    []
  );

  useEffect(() => {
    if (boxes.length > 0) {
    }
  }, [boxes]);
  const [id, setId] = useState<string | null>(null);
  const [selectedCards, setSelectedCards] = useState<any[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<RepackSeriesType | string>(
    ""
  );
  const [selectedForRepack, setSelectedForRepack] = useLocalStorage(
    LocalStorageKey.CARD_REPACK_SELECTEDFORREPACK,
    []
  );

  // FUNCTIONS
  const handleAddTicket = async ({
    ticketName,
    setTicketName,
    cost,
    setCost,
    multipleTickets,
    numberOfTickets,
    setNumberOfTickets,
    repackSeriesId,
    setLoading,
  }: HandleAddTicketParams) => {
    if (!ticketName || cost === 0 || isNaN(Number(cost))) {
      newSnackbarMessage("Please provide valid ticket details.", "error");
      return;
    }

    const numberOfItems = multipleTickets ? Number(numberOfTickets) : 1;

    if (
      multipleTickets &&
      (numberOfTickets === 0 ||
        isNaN(Number(numberOfTickets)) ||
        Number(numberOfTickets) <= 1)
    ) {
      newSnackbarMessage(
        "Please provide a valid number of tickets greater than 1.",
        "error"
      );
      return;
    }

    setLoading(true);

    try {
      const newTickets: RepackTicket[] = [];
      const response = await fetchModule.postResponseBody(
        "/repack/new/ticket",
        "POST",
        {
          UserId: user["id"],
          Name: ticketName,
          Cost: Number(cost),
          NumberOfTickets: numberOfItems,
          RepackSeriesId: repackSeriesId,
        }
      );

      const data = await response.json();

      for (let i = 0; i < numberOfItems; i++) {
        const newTicket: RepackTicket = {
          id: Date.now() + i,
          cost: Number(cost),
        };
        newTickets.push(newTicket);
      }

      setTickets([...tickets, ...data]);
      setTicketName("");
      setCost(0);
      setNumberOfTickets(0);
      newSnackbarMessage("Ticket(s) added successfully.", "success");
    } catch (error) {
      console.error("Failed to add ticket(s):", error);
      newSnackbarMessage("Failed to add ticket(s).", "error");
    } finally {
      setLoading(false);
    }
  };

  const removeItemFromRepack = useCallback(
    (itemId: number) => {
      setSelectedTickets((prevTickets) => {
        const item = prevTickets.find((p) => p.id === itemId);
        if (item) {
          setSelectedTickets((prev) => [...prev, item]);
        }
        return prevTickets.filter((p) => p.id !== itemId);
      });

      setSelectedForRepack((prevSelectedForRepack: any) => {
        const item = selectedCards.find((s) => s.id === itemId);
        if (item) {
          return [...prevSelectedForRepack, item];
        }
        return prevSelectedForRepack;
      });
    },
    [selectedCards, setSelectedForRepack]
  );

  const addToRepackStoreRepackVersion = (rows: any) => {
    const tempStore = [...selectedForRepack];
    rows.forEach((card: any) => {
      if (!tempStore.some((e) => e.id === card.id)) {
        tempStore.push(card);
      }
    });
    setSelectedForRepack(tempStore);
  };

  const removeFromRepackStoreRepackVersion = (rows: any) => {
    const updatedStore = selectedForRepack.filter(
      (e: any) => !rows.some((card: any) => e.id === card.id)
    );

    setSelectedForRepack(updatedStore);
  };

  const addToRepackStore = (rows: any) => {
    const tempStore = [...selectedForRepack];

    rows.forEach((card: any) => {
      if (card.repackItemId) {
        newSnackbarMessage(
          `Card already has a repack item with Public ID: ${card.publicId}`,
          "info"
        );
      } else if (!tempStore.some((e) => e.id === card.id)) {
        tempStore.push(card);
      }
    });

    setSelectedForRepack(tempStore);
  };

  const clearRepackStore = () => {
    setSelectedForRepack([]);
  };

  const removeFromRepackStore = (id: any) => {
    const filtererdList = selectedForRepack.filter((e: any) => e.id !== id);
    setSelectedForRepack(filtererdList);
  };

  const addToNewBox = () => {
    if (!selectedCards || selectedCards.length === 0) {
      newSnackbarMessage(
        "There are no cards selected to add to a new repack box",
        "error"
      );
      console.error("No valid selected cards.");
      return;
    }

    const updatedSelectedForRepack = selectedForRepack.filter(
      (item: any) =>
        !selectedCards.some((selectedItem: any) => selectedItem.id === item.id)
    );
    setSelectedForRepack(updatedSelectedForRepack);
  };


  const addToSelectedBox = async () => {
    if (!selectedBox) {
      newSnackbarMessage(
        "No valid selected box found. Make sure you've selected the box you'd like to add items to.",
        "error"
      );
      console.error("No valid selected box found.");
      return;
    }
  
    const selectedBoxId = selectedBox.publicId;
  
    try {
      // Create staged cards for each selected card and compile the results
      const newCardsResults = await Promise.all(
        selectedCards.map(async (item) => {
          const result = await CreateStagedCards(item, user, selectedBoxId);
          const data = await result.json();
          return data.result;
        })
      );
  
      // Combine old and new items
      const oldItems = selectedBox.repackItems || [];
      const updatedItems = [...oldItems, ...newCardsResults];
  
      // Update selected box's items
      setSelectedBox((prevBox: any) => ({
        ...prevBox,
        repackItems: updatedItems,
      }));
  
      // Update the boxes list with new items for the selected box
      const updatedBoxes = boxes.map((box: any) =>
        box.publicId === selectedBoxId
          ? { ...box, repackItems: updatedItems }
          : box
      );
      setBoxes(updatedBoxes);
  
      // Update the selected box in state
      if (selectedBox.publicId === selectedBoxId) {
        const updatedSelectedBox = updatedBoxes.find(
          (box: any) => box.publicId === selectedBoxId
        );
        if (updatedSelectedBox) {
          setBox(updatedSelectedBox);
        }
      }
  
      // Remove items that were successfully added to the selected box
      const newSelectedForRepack = selectedForRepack.filter(
        (item: any) =>
          !newCardsResults.some((selectedItem: any) => selectedItem.id === item.id)
      );
      setSelectedForRepack(newSelectedForRepack);
  
      // Show success message
      newSnackbarMessage("Items successfully added to the box.", "success");
    } catch (error) {
      console.error("Error adding items to the box:", error);
      newSnackbarMessage("Failed to add items to the box.", "error");
    }
  };
  

  const addToNewBoxTicket = () => {
    if (!selectedTickets || selectedTickets.length === 0) {
      newSnackbarMessage(
        "There are no tickets selected to add to a new repack box",
        "error"
      );
      console.error("No valid selected tickets.");
      return;
    }

    const updatedTickets = tickets.filter(
      () =>
        !selectedTickets.some(
          (selectedItem: any) => selectedItem.id === selectedItem.id
        )
    );
    setTickets(updatedTickets);
  };

  const addToSelectedBoxTicket = async () => {
    // Guard clause to check if selectedBox is valid
    if (!selectedBox || !selectedBox.publicId) {
      newSnackbarMessage(
        "No valid selected box found. Make sure you've selected the box you'd like to add the tickets to.",
        "error"
      );
      console.error("No valid selected box found.");
      return;
    }
  
    const selectedBoxId = selectedBox.publicId;
  
    try {
      // Create staged tickets for each selected ticket and compile the results
      const newTicketsResults = await Promise.all(
        selectedTickets.map(async (ticket) => {
          const response = await CreateStagedTickets(ticket, user, selectedBoxId);
          const responseJson = await response.json();
          return responseJson.result; 
        })
      );
  
      // Update selected box's items using prevBox to ensure latest data
      setSelectedBox((prevBox: any) => {
        if (!prevBox) {
          console.error("Previous box is undefined.");
          return null;
        }
        // Combine old items with the new ones
        const updatedItems = [...(prevBox.repackItems || []), ...newTicketsResults];

        return {
          ...prevBox,
          repackItems: updatedItems, 
        };
      });

      setBox((prevBox: any) => {
        if (!prevBox) {
          console.error("Previous box is undefined.");
          return null;
        }
        // Combine old items with the new ones
        const updatedItems = [...(prevBox.repackItems || []), ...newTicketsResults];
        return {
          ...prevBox,
          repackItems: updatedItems, 
        };
      });
  
      // Update the boxes list with new items for the selected box, ensuring to use prevBoxes
      setBoxes((prevBoxes: any) =>
        prevBoxes.map((box: any) =>
          box.publicId === selectedBoxId
            ? { ...box, repackItems: [...(box.repackItems || []), ...newTicketsResults] } 
            : box
        )
      );
  
      // Remove tickets that were successfully added from selectedTickets
      const newSelectedTickets = selectedTickets.filter(
        (ticket) =>
          !newTicketsResults.some((newTicket) => newTicket.id === ticket.id)
      );
      setSelectedTickets(newSelectedTickets);
  
      // Show success message
      newSnackbarMessage("Tickets successfully added to the box.", "success");
    } catch (error) {
      console.error("Error adding tickets to the box:", error);
      newSnackbarMessage("Failed to add tickets to the box.", "error");
    }
  };
  
  

  const addToTickets = (product: any) => {
    setTickets((prevTickets: any[]) => {
      if (prevTickets.some((p) => p.id === product.id)) {
        newSnackbarMessage("Product already exists in the list.", "error");
        return prevTickets;
      }
      return [...prevTickets, product];
    });
  };

  const contextValues = {
    boxTypes,
    setBoxTypes,
    itemTypes,
    setItemTypes,
    boxes,
    setBoxes,
    selectedBox,
    setSelectedBox,
    boxLoading,
    selectedSeriesId,
    setSelectedSeriesId,
    box,
    setBox,
    selectedCards,
    setSelectedCards,
    selectedTickets,
    setSelectedTickets,
    selectedType,
    setSelectedType,
    handleAddTicket,
    addToNewBox,
    addToSelectedBox,
    addToNewBoxTicket,
    addToSelectedBoxTicket,
    selectedForRepack,
    setSelectedForRepack,
    tickets,
    setTickets,
    addToRepackStoreRepackVersion,
    clearRepackStore,
    removeFromRepackStoreRepackVersion,
    addToRepackStore,
    removeFromRepackStore,
    removeItemFromRepack,
    addToTickets,
    id,
    setId,
    ticketTypes,
    setTicketTypes,
    seriesList,
    setSeriesList,
  };

  return (
    <BuildRepacksContext.Provider value={contextValues}>
      {children}
    </BuildRepacksContext.Provider>
  );
};

export function useBuildRepacks() {
  const context = useContext(BuildRepacksContext);
  if (!context) {
    throw new Error(
      "Invalid BuildRepacksContext, must be wrapped in a BuildRepacksProvider"
    );
  }
  return context;
}
