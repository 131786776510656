import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { usePlatform } from '../../Contexts/ShowContexts/Platforms/PlatformContext';

const AddPlatformButton: React.FC = () => {
    const {setCreatePlatformModal} = usePlatform();
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
                setCreatePlatformModal(true)
            }}
        >
            Add Platform
        </Button>
    );
};

export default AddPlatformButton;