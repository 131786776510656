import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Grid
} from '@mui/material';
import useFetchPromise from '../../../Components/CommonUtility/useFetchPromise';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { RepackBox } from '../Types/RepackBox';
import { RepackSeriesType } from '../Types/RepackSeriesType';
import SaveSeriesModal from './SaveSeriesModal';
import { RepackBoxType } from '../Types/RepackBoxType';

interface SeriesInfoPanelProps {
  boxTypes: RepackBoxType[];
  setSelectedType: React.Dispatch<React.SetStateAction<string | RepackSeriesType>>;
  selectedType: string | RepackSeriesType;
  boxes: RepackBox[];
}

const SeriesInfoPanel: React.FC<SeriesInfoPanelProps> = ({ boxTypes, setSelectedType, selectedType, boxes }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUser();
  const fetchPromise = useFetchPromise();

  const handleStageSave = () => {
    setIsModalOpen(true);
  };

  const handleConfirmSave = () => {
    const filteredBox = boxes.map((box: RepackBox) => [
      box.tickets?.map((ticket) => ticket.id),
    ]);

    fetchPromise("/Repack/construct/series", "POST", {
      Body: { boxes: filteredBox },
      RepackType: selectedType,
      UserId: user["id"],
    }).then(() => {
      setIsModalOpen(false);
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ paddingTop: '15px', width: '100%' }}>
      <FormControl fullWidth>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Button onClick={handleStageSave} fullWidth variant="contained">
              Save New Series
            </Button>
          </Grid>
        </Grid>
      </FormControl>

      <SaveSeriesModal 
        open={isModalOpen} 
        onClose={handleCloseModal} 
        onConfirm={handleConfirmSave} 
        boxTypes={boxTypes}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        boxes={boxes}
      />
    </div>
  );
};

export default SeriesInfoPanel;
