import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useUser } from '../CommonUtility/UserContext';
import { useShow } from '../../Contexts/ShowContexts/Shows/ShowContext';
import { GetShowSlots } from '../../Contexts/ShowContexts/ShowSlots/ShowSlotsAPICalls';
import { IShowSlot } from '../../Contexts/ShowContexts/ShowSlots/IShowSlot';
import { Tooltip } from '@material-ui/core';

export default function ShowSlotAutocomplete() {
  const {user} = useUser()
  const {channel, showSlot, setShowSlot, channelValid, startTime, startTimeValid} = useShow()
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<IShowSlot[]>([]);
  const loading = open && options.length === 0;

  async function refreshShowSlotData() {
    if (!channelValid() || startTime == null) return;
    const response = await GetShowSlots(channel?.publicId, startTime, user)
    if (response.status == 200) {
        const data = await response.json()
        setOptions(data)
    }
  }
  React.useEffect(() => {

    if (!loading) {
      return undefined;
    }

    (async () => {
      await refreshShowSlotData()
    })();
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={!channelValid() || !startTimeValid()}
      value={showSlot}
      onChange={(e: any, newValue: IShowSlot | null) => {setShowSlot(newValue)}}
      getOptionDisabled={(option: IShowSlot) => option.disabled}
      isOptionEqualToValue={(option: IShowSlot, value: IShowSlot) => option.id === value.id}
      getOptionLabel={(option: IShowSlot) => option.slotName}
      options={options}
      loading={loading}
      renderInput={(params) => {
        return (
          <Tooltip title="Select a Channel and Start Time to enable this field" 
                  
                    placement="top"
          > 
        <TextField
          {...params}
          label="Show Slots"
          variant="standard"
          disabled={!channelValid() || !startTimeValid()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        >
        </TextField>
        </Tooltip>

      )
    }
    
    }
    />
  );
}
