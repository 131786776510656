import { Box } from "@mui/material";
import { PayPeriodsProvider } from "../Contexts/PayPeriodContexts/PayPeriodsContext";
import PayPeriodsDataGrid from "../Dash/PayPeriodPage/PayPeriodDataGrid";
import PayPeriodHeader from "../Dash/PayPeriodPage/PayPeriodHeader";
import { CreatePayPeriodProvider } from "../Contexts/PayPeriodContexts/CreatePayPeriodContext";


const PayPeriodPage = () => (
  <Box
    sx={{
      width: '%',
      height: '100%',
      padding: '5px',
      display: 'flex',
      flexDirection: 'column'
    }}
  >
        <PayPeriodsProvider>
            <CreatePayPeriodProvider>
                <PayPeriodHeader/>
                <PayPeriodsDataGrid />
            </CreatePayPeriodProvider>
        </PayPeriodsProvider>
  </Box>
);

export default PayPeriodPage;
