import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import { useShowSaleModified } from "../../Contexts/ShowSaleModifiedContexts/ShowSaleModifiedContext";
import DeleteIcon from '@mui/icons-material/Delete';

export default function ShowSaleEditModal() {

    const {        
        selectedShowSale,
        modifiedAttributes, setModifiedAttributes,
        handleChange,
        open, setOpen,
        modifyShowSale,
        saleTypes, saleTypesLoading, saleTypesError,
        saleStatuses, statusLoading, statusError
    } = useShowSaleModified();

    function addAttribute(attribute: string, defaultValue: any) {
        setModifiedAttributes({
            ...modifiedAttributes,
            [attribute]: defaultValue
        })
    }
    function removeAttribute(attribute: string) {
        const newAttribs = modifiedAttributes
        delete newAttribs[attribute]
        setModifiedAttributes({
            ...newAttribs
        })
    }
    function getOriginalField(attributeName: string, value: any, label: string, spacing: number) {
        return (
            <Grid item xs={spacing}>
                <TextField
                    sx={{ width: "100%" }}
                    label={"Original " +label +" value"}
                    name={attributeName}
                    value={value}
                    onChange={handleChange}
                    disabled
                />
            </Grid>
        )
    }
    function renderNullModified(attributeName: string, label: string, value: any, id: any) {
        const defaultValue = modifiedAttributes && modifiedAttributes[attributeName] !== undefined ? modifiedAttributes[attributeName] : id
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "20px"
            }}>
                <Grid container spacing={3}>
                    {getOriginalField(attributeName, value, label, 6)}
                    <Grid item xs={6}>
                        <Button 
                            sx={{ width: "100%", height: "100%" }}
                            onClick={() => {addAttribute(attributeName, defaultValue)}}
                        >
                            Add {label} Override
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    function renderTextField(attributeName: string, label: string) {
        const attrib = attributeName.charAt(0).toLowerCase() + attributeName.slice(1)
        if (modifiedAttributes && modifiedAttributes[attributeName] !== undefined) {
            return (
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "20px"
                }}>
                    <Grid container spacing={3}>
                        {getOriginalField(attributeName, selectedShowSale.showSale[attrib], label, 4)}
                        <Grid item xs={4}>
                            <TextField
                                sx={{ width: "100%" }}
                                label={label}
                                name={attributeName}
                                value={modifiedAttributes[attributeName]}
                                onChange={handleChange}
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <Button 
                                sx={{ width: "100%", height: "100%" }}
                                onClick={() => {removeAttribute(attributeName)}} 
                                endIcon={<DeleteIcon/>}
                                >Remove Override
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

            )
        }
        if (selectedShowSale && saleStatuses.length > 0) {
            return (
                <>
                    {renderNullModified(attributeName, label, selectedShowSale[attrib], selectedShowSale[attrib])}
                </>
            )
        }
        return <></>
    }

    function renderSelectField(attributeName: string, label: string, options: any[], ogValue: string) {
        if (saleTypesLoading || statusLoading) return <div>Loading...</div>
        if (saleTypesError || statusError) return <div>Error...</div>
        if (modifiedAttributes && modifiedAttributes[attributeName] !== undefined) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "20px"
            }}>
                <Grid container spacing={3}>
                    {getOriginalField(attributeName, ogValue, label, 4)}
                    <Grid item xs={4}>
                        <TextField  
                            select
                            label={label}
                            name={attributeName}
                            value={modifiedAttributes[attributeName]}
                            onChange={handleChange}
                            fullWidth
                        >
                            {options.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name == undefined ? option.status : option.name }
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <Button 
                            sx={{ width: "100%", height: "100%" }}
                            onClick={() => {removeAttribute(attributeName)}} 
                            endIcon={<DeleteIcon/>}
                        >
                            Remove {label}
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        )
        }
        // If there's a selected show sale, render the disabled option
        if (selectedShowSale) {
            return (
                <>
                    {renderNullModified(attributeName, label, ogValue, selectedShowSale[attributeName].id)}
                </>
            )
        }
        return <></>
    }
    return (
        <>
            <Dialog open={open} onClose={() => {setOpen(false)}}
                maxWidth="md"    
                fullWidth
            >
                <DialogTitle > {selectedShowSale?.productName}</DialogTitle>
                <div></div>
                <DialogContent sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px"
                }}>
                    {renderTextField("breakId", "Break Id")}
                    
                    {renderSelectField("saleType", "Sale Type", saleTypes, selectedShowSale ? selectedShowSale?.showSale["saleType"].name : "")}
                    {renderSelectField("saleStatus", "Sale Status", saleStatuses, selectedShowSale ? selectedShowSale?.showSale["saleStatus"].status : "")}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setOpen(false)}}>Cancel</Button>
                    <Button onClick={() => {modifyShowSale()}}>
                        Save
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}