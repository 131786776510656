import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@material-ui/core";
import { AutocompleteHelper } from "../../../../Components/Autocomplete/ObjectExtractor";
import { matchSorter } from "match-sorter";
import "./Filter.css"; 
import { GridFilterItem } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-pro";

interface FilterAutocompleteProps {
  item: GridFilterItem,
  options: any[];
  isMultiple?: boolean;
  labelPath?: string;
}

const FilterAutocomplete = ({item, options,isMultiple,labelPath, }:FilterAutocompleteProps) => {
  
  const gridApi = useGridApiContext()

  const [value, setValue] = useState(()=>{
    if(isMultiple){
      return typeof item.value === 'string' ? [] : (item.value ?? [])
    }
    else{
      return item.value ?? ""
    }
  });

  const filterData = (options:any,valueToFilterOn:any)=>{
    if(labelPath !== ""){
      return matchSorter(options, valueToFilterOn, { keys: labelPath?.split(".") }).splice(0, 15);
    }
    return matchSorter(options, valueToFilterOn).splice(0, 15);
  }

  const handleFilterOptions = (options: string[], params: any) => {
    const { inputValue } = params;
    const filteredData = filterData(options,inputValue);
    return filteredData;
  }

  return (
    <Autocomplete
      id={item.field + "-" + item.operator + "- Autocomplete"}
      options={options}
      value={value}
      multiple={isMultiple}
      
      onChange={(event, newValue) => {
        setValue(newValue);
        if(labelPath){
          const extractedVal = AutocompleteHelper.getLabelViaInfer(newValue,labelPath);
          gridApi.current.upsertFilterItem({...item,value:extractedVal})
        }
        else{
          gridApi.current.upsertFilterItem({...item,value:newValue})
        }
      }}

      renderInput={(params) => {
        return (
        <TextField
          {...params}
          id={item.field + "-" + item.operator + "- AutocompleteTextField"}
          variant="standard"
          margin="dense"
          className="filter-autocomplete"
          autoFocus
          
        />
      )}}

      filterOptions={handleFilterOptions}
    />
  );
};

export default FilterAutocomplete;
