import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {Card} from '../Types/CardType';
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});


interface Props {
  rows: Card[];
  isOpen: boolean;
  onClose?: () => void;
  deleteRepackFromStore: (id: number) => void;
  toggleOpen: (isOpen: boolean) => void;
  clearRepackStore: () => void;
}

export default function RepackStorePreview({
  rows,
  isOpen,
  onClose,
  deleteRepackFromStore,
  toggleOpen,
}: Props) {
  const {
    clearRepackStore,
  } = useBuildRepacks();

  const columns: GridColDef<Card>[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      renderCell: (params) => {
        const id = params.id;

        return (
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remove"
            className="textPrimary"
            onClick={() => {
              deleteRepackFromStore(Number(id));
            }}
            color="inherit"
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Card",
      width: 650,
      editable: false,
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 150,
      editable: false,
    },
  ];

  useEffect(() => {
    toggleOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    toggleOpen(false);
    if (onClose) onClose(); 
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="md"
      TransitionComponent={Transition}
      >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Repack Store Preview</Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box sx={{ height: 400, width: "100%", padding: 2 }}>
        <DataGridPro rows={rows} columns={columns} pagination={false} />
      </Box>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button className="btn" onClick={() => clearRepackStore()} variant="outlined" style={{ marginRight: '10px', width: '15%' }}>
          Clear Selection
        </Button>
      </Box>
    </Dialog>
  );
}
