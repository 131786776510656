import * as React from 'react';
import {createContext, useContext} from 'react';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
import { IShow } from './IShow';
import usePaginationFiltering, { PaginationModel, SortModel } from '../../../Components/CommonUtility/usePaginationFiltering';

const ShowsContext = createContext<ShowsContextProps | null>(null);
interface ShowsContextProps {
    addShow: (show: IShow) => void,
    updateShow: (show: IShow) => void,
    shows: Array<IShow>,
    setShows: (hosts: Array<IShow>) => void,
    showsLoading: boolean,
    showsError: boolean,
    showPaginationModel?: PaginationModel,
    setShowPaginationModel: any,
    totalShowRows: number,
    setSortModel: any,
    sortModel?: SortModel,
    fetching: boolean
    filteringModel?: any, setFilteringModel: any,
}

type ShowsProviderProps = {
    children?: React.ReactNode;
}

export const ShowsProvider = ({children} : ShowsProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();

    const defaultPaginationModel: PaginationModel = {page: 0, pageSize: 100} 
    const defaultFilteringModel = {items: [], quickFilterValues: []}
    const defaultSortModel: SortModel = null

    const {data: shows, setData: setShows, loading: showsLoading, error: showsError, 
        paginationModel: showPaginationModel, setPaginationModel: setShowPaginationModel, totalRows: totalShowRows, sortModel, setSortModel, fetching,
        filteringModel, setFilteringModel
    }: {
        data: Array<IShow>, setData: any, loading: boolean, error: any, 
        paginationModel: PaginationModel, setPaginationModel: any, totalRows: number, 
        filteringModel: any, setFilteringModel: any,
        sortModel: SortModel, setSortModel: any, fetching: boolean
    } = usePaginationFiltering("/show/all/complex", "/show/count", defaultPaginationModel, defaultFilteringModel, defaultSortModel)

    function addShow(show: IShow) {
        setShows([...shows, show])
    }
    function updateShow(newShow: IShow) {
        const newShows: Array<IShow> = shows.map( (host: IShow) => (host.publicId === newShow.publicId) ? newShow : host)
        setShows(newShows);
        newSnackbarMessage("Updated new show successfully", 'success');
    }
    const contextValues = {
        addShow, updateShow, setShows, shows, showsLoading, showsError,
        filteringModel, setFilteringModel,
        showPaginationModel, setShowPaginationModel, totalShowRows, setSortModel, sortModel, fetching
    }
    return (
        <ShowsContext.Provider value={contextValues}>
            {children}
        </ShowsContext.Provider>
        )
}

export function useShows() {
    const context = useContext(ShowsContext);
    if (!context) {
        throw new Error("Invalid ShowContext, must be wrapped in a ShowProvider")
    }
    return context;
}