import { useEffect, useState } from "react";
import { useFilter } from "./useFilter";
import useFetchPromise from "../useFetchPromise";
import React from "react";
import { useSort } from "./useSort";
import { usePagination } from "./usePagination";
import { useGridApiRef } from "@mui/x-data-grid-pro";


export type DatagridDataConnectionProps = {
  children?: React.ReactNode;
  automaticallyRefreshData?: boolean;
  isPaginated?: boolean;
  goToURL?: string;
  baseurl:string;
  sortKey: string;
  filterKey: string;
}

export const useDatagridDataConnection = ({
  filterKey,
  sortKey,
  automaticallyRefreshData,
  isPaginated,
  baseurl,
  goToURL
} : DatagridDataConnectionProps) => {
  
  const {    filterModel,
    setFilterModel,
    updateFilterModel,
    getPreprocessedFilterModel} = useFilter({localStorageKey: filterKey});
  const {paginationModel,setCurrentPage,setPageSize,lastEditWasManual,setLastEditWasManual} = usePagination();
  const {sortModel, setSortModel, resetSortModel} = useSort({localStorageKey: sortKey});
  const fetchPromise = useFetchPromise();
  const [rows,setRows] = useState<any[]>([]);
  const [isGettingData,setIsGettingData] = useState<boolean>(false);
  const [totalRows,setTotalRows] = useState<number>(0);
  const [jumpToRef,setJumpToRef] = useState<number>(0);
  const gridApi = useGridApiRef();

  useEffect(() => {
    if(automaticallyRefreshData && !lastEditWasManual && filterModel && paginationModel && sortModel){
      refreshData();
    }
    else{
      setLastEditWasManual(false);
    }
  },[filterModel,paginationModel,sortModel])
  

  const refreshData = () => {
    if(isPaginated){
      getPagedData();
    }
    else{
      throw new Error("Unpaginated data not yet implemented")
    }
  }

  const getPagedData = async () => {
    setIsGettingData(true);
    setLastEditWasManual(true);
    const processedFilterModel = getPreprocessedFilterModel();
    const responsePromise = await fetchPromise(
      baseurl,
      "POST",
      {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        filterModel: processedFilterModel,
        sortModel: sortModel,
      }
    );

    if (!responsePromise.ok) {
      throw new Error("Fetch request failed");
    }

    const responseData = await responsePromise.json();
    setRows(responseData.items);
    setTotalRows(responseData.totalItems);
    setPageSize(responseData.pageSize);
    setCurrentPage(responseData.currentPage);
    setIsGettingData(false);

  }

  const goTo = async (identifier: number | string) => {
    
    const processedFilterModel = getPreprocessedFilterModel();
    setIsGettingData(true);
    setLastEditWasManual(true);
    try {
      const responsePromise = await fetchPromise(
        goToURL,
        "POST",
        {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          filterModel: processedFilterModel,
          sortModel: sortModel,
          jumptype: identifier,
        }
      );

      if (!responsePromise.ok) {
        throw new Error("Fetch request failed");
      }

      resetSortModel();
      const responseData = await responsePromise.json();
      setRows(responseData.items);
      setTotalRows(responseData.totalItems);
      //setPageSize(responseData.pageSize);
      setCurrentPage(responseData.currentPage);
      setJumpToRef(responseData.lastId);
      
      setIsGettingData(false);

    } catch (error) {
      console.error("An error occurred:", error);
      setIsGettingData(false);
      setLastEditWasManual(false);
    }
  }

  const goToViaURLParams = async (queryParam: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get(queryParam); // Replace 'myParam' with the name of your parameter
  
    if (myParam) {
      fetchPromise(
        goToURL,
        "POST",
        {
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          filterModel: {
            items: [],
            quickFilterValues: [],
          },
          sortModel: sortModel,
          jumptype: myParam,
        }
      ).then((res) => res.json().then((responseData:any) => {
        resetSortModel();
        setRows(responseData.items);
        setTotalRows(responseData.totalItems);
        setCurrentPage(responseData.currentPage);
        setTimeout(() => {
          setJumpToRef(responseData.lastId);
          urlParams.delete(queryParam);
          window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
        }, 100);
      }))
    }
  }

  const [oldRef, setOldRef] = React.useState<number | null>(null);
  
 

  useEffect(() => {
    if ( jumpToRef !== undefined && jumpToRef !== oldRef && rows.some((row) => row.id === jumpToRef)) {
      setOldRef(jumpToRef);
      const rowIndex = rows.findIndex(row => { return row.id === jumpToRef});
      
      if(rowIndex === -1){
        return
      }
      const scrollTo = 25*rowIndex; 
      gridApi.current.scroll({ top: scrollTo,left:0 });
    }
  }, [rows,jumpToRef]);
  return {
    refreshData,
    rows,
    setRows,
    isGettingData,
    totalRows,
    goTo,
    goToViaURLParams,
    filterModel,
    setFilterModel,
    updateFilterModel,
    getPreprocessedFilterModel,
    paginationModel,setCurrentPage,setPageSize,lastEditWasManual,setLastEditWasManual,
    sortModel, setSortModel, resetSortModel,
    setJumpToRef,
    gridApi,

  }
}

