import React from 'react';
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import { IShow } from '../../Contexts/ShowContexts/Shows/IShow';
import { useNavigate } from 'react-router-dom';
import { ManageAccounts } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

interface EditShowGridButtonProps {
    id: GridRowId;
    show: IShow;
}

const SingleShowViewButton: React.FC<EditShowGridButtonProps>  = ({show}) => {
    const navigate = useNavigate()

    const handleClick = () => () => {
        navigate("/dashboard/show/" + show.publicId);
    };
    return (
        <Tooltip title="Manage show">
        <GridActionsCellItem
            icon={<ManageAccounts />}
            label="Edit"
            className="textPrimary"
            onClick={handleClick()}
            color="inherit"
      />
      </Tooltip>
    );
};

export default SingleShowViewButton;
