export const GetDefaultFilter = () => {
  return {
    items: [],
    quickFilterValues: [],
  }
}
export const GetDefaultSort = () => { 
  return []
}
export const GetDefaultPagination = () => {
  return {
    isLoading: false,
    data: [],
    total: 1,
    page: 1,
    pageSize: 1500,
    lastEditWasManual: false,
  }
}