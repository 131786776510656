import React, { createContext, useContext, useEffect, useState } from 'react';
import { AddSkuModal } from './AddSkuModal';
import { useCardDashboard } from '../CardDashboardContext';


type AddSkuContextProps = {
  children: React.ReactNode;

}
type AddSkuProviderProps = {
  setOpenSkuAddModal: (value: boolean) => void;
  dialogValue: any;
  setDialogValue: (value: any) => void;
  launchAddSkuModal: (field:string,option:any,addToDatagrid:any) => void;
  openSkuAddModal: boolean;
  field:string;
  skuType: string;
}


// Create a context
const AddSkuModalContext = createContext<AddSkuProviderProps | null>(null);

// Provider component
export const AddSkuModalProvider =  ({ children}:AddSkuContextProps) => {
  const [openSkuAddModal, setOpenSkuAddModal] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    label: "",
    value: "",
    publicId: "",
    rank: ""
  })
  const [addToFunction, setAddToFunction] = useState(null);
  const [field, setField] = useState("");
  const [skuType] = useState("");
  const {setModalOpen} = useCardDashboard();
  useEffect(()=>{
      setModalOpen(openSkuAddModal);  

  },[openSkuAddModal])
  const launchAddSkuModal = (field:string,option:any,addToDatagrid:any,typePublicId?:string) => {
    setField(field);
    setDialogValue((prev)=>{
      return {
        ...prev,
        label: option,
        publicId: typePublicId? typePublicId : "",
      }
    });
    setAddToFunction(() => addToDatagrid);
    setOpenSkuAddModal(true);
  }
  return (
    <AddSkuModalContext.Provider value={{setOpenSkuAddModal,dialogValue,setDialogValue,launchAddSkuModal,openSkuAddModal,field,skuType}}>
      {children}
      <AddSkuModal 
        open={openSkuAddModal} 
        toggleOpen={setOpenSkuAddModal} 
        addToDataGridFunction = {addToFunction}
      />
    </AddSkuModalContext.Provider>
  );
};

// Custom hook to use the AutocompleteContext
export function useAddSkuModal() {
  const context = useContext(AddSkuModalContext);
  if (!context) {
    throw new Error('useAddSkuModalContext must be used within a AddSkuModalContextProvider');
  }
  return context;
}