import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { VideoCameraBack } from '@mui/icons-material';

const ShowQuicknavButton: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Button
            color="primary"
            variant="text"
            startIcon={<VideoCameraBack />}
            onClick={() => {
                navigate("/dashboard/shows")
            }}
        >
            Manage Shows
        </Button>
    );
};

export default ShowQuicknavButton;