import FetchModule from "../../Modules/FetchModule";
const fetchModule = new FetchModule();

export async function CreatePayPeriod(
    payoutDate: any,
    days: any[],
    user: any,
){
    const response = await fetchModule.postResponseBody("/PayPeriod/new", "POST", 
    {
        UserId: user["id"],
        PayoutDate: payoutDate,
        Days: days
    })
    return response    
}

export async function AssignShowsToPayPeriod(
    shows: any,
    payperiodPublicId: string,
    user: any,
){
    const response = await fetchModule.postResponseBody("/PayPeriod/assign/shows", "PUT", 
    {
        UserId: user["id"],
        Guid: payperiodPublicId,
        ShowIds: shows,
    })
    return response    
}

export async function UnassignShowsToPayPeriod(
    shows: any,
    user: any,
){
    const response = await fetchModule.postResponseBody("/PayPeriod/unassign/shows", "PUT", 
    {
        UserId: user["id"],
        ShowIds: shows,
    })
    return response    
}