import FetchModule from '../../../Modules/FetchModule';
const fetchModule = new FetchModule();

export async function GetShowSlots(
    channelPublicId: string, 
    date: Date,
    user: any,
){
    const response = await fetchModule.postResponseBody("/show/slots", "POST", 
    {
        UserId: user["id"],
        Body: {
            "ChannelPublicId": channelPublicId,
            "Date": date
        }
    })
    return response    
}

export async function CreateShowSlot(
    showSlotName: string, 
    user: any,
){
    const response = await fetchModule.postResponseBody("/show/slot/new", "POST", 
    {
        UserId: user["id"],
        Body: {
            "showSlotName": showSlotName,
        }
    })
    return response    
}
