import React from 'react';
import { useShowSales } from '../../Contexts/ShowSalesContexts/ShowSaleContext';
import { DataGridPremium, GridColDef, GridActionsCellItem} from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';

type ShowSaleDataGridProps = {
    children?: React.ReactNode;
    row: any;
}


  
const ShowSaleCommissionDataGrid = ({row} : ShowSaleDataGridProps)  => {
    const {deleteCommision} = useShowSales();
    const saleCommissionData = row.row.saleCommissions;  

    const columns= React.useMemo<GridColDef<any>[]>( () => [
        { field: 'showHost', headerName: 'Host', width: 100,
            valueGetter: (value: any) => {
                return value.displayName;
              },
        },
        { field: 'commissionAmount', headerName: 'Amount', width: 100, groupable: false,
            valueGetter: (value) => {
                if (!value) {
                  return value +"%";
                }
                // Convert the decimal value to a percentage
                return value * 100 + "%";
              },
        },
        { field: 'commissionType', headerName: 'Commission Type', width: 100, groupable: false},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            groupable: false,
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key={"ShowSaleCommisionDataGrid-Delete-"+id}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => deleteCommision(row)}
                    />,
                ];
              },
        }
    ],[])

    return(
        <>
           <DataGridPremium 
                rows={saleCommissionData}
                columns={columns}
                />
        </>
    )
}
  
  
  export default ShowSaleCommissionDataGrid;
  