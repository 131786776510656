import React, { createContext, useCallback, useContext, useState } from 'react';
import useFetch from '../../Components/CommonUtility/useFetch';
import { Spinner } from 'reactstrap';
import FetchModule from '../../Modules/FetchModule';
import { FilterPresetTypeNames } from '../../Components/CommonUtility/ServerSide/FilterPresets/FilterPresetTypeNames';

type CardDashboardContextProps = {
  children: React.ReactNode;
};

type CardDashboardProviderProps = {
  skuList: any;
  setSkuList: any;
  filterList: any;
  setFilterList: any;
  statusTypes: any;
  players: any;
  vendors: any;
  setVendors: any;
  users: any;
  updateSkus: (field: string, item: any) => void;
  updatePlayers: (item: any) => void;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  updateVendors: (field: string, item: any, setter: any, typeId: any) => void;
  teams: any;
  setTeams: any;
  recalculateFilter: any;
  deleteChecklistEntry: any;
  getChecklistData: any;
  cachedChecklistResults: any;
  findTeams: any;
};

const fetchData = new FetchModule();

// Create a context
const CardDashboardContext = createContext<CardDashboardProviderProps | null>(null);

// Provider component
export const CardDashboardProvider = ({ children }: CardDashboardContextProps) => {
  const { data: skuList, setData: setSkuList, loading: skuListLoading } = useFetch("/Card/all/skuitems", true, {});
  const { data: filterList, setData: setFilterList, loading: filterListLoading } = useFetch("/Filter/all/"+FilterPresetTypeNames.CARD_FILTER_FOR_OPTIONS, true, []);
  const { data: statusTypes, loading: statusTypesLoading } = useFetch("/Card/all/cardstatustypes", true, []);
  const { data: players, setData: setPlayers, loading: playersLoading } = useFetch("/Card/all/players", true, []);
  const { data: vendors, setData: setVendors, loading: vendorsLoading } = useFetch("/Card/all/vendors", true, []);
  const { data: users, loading: usersLoading } = useFetch("/Card/all/users", true, []);
  const { data: teams, setData: setTeams, loading: teamsLoading } = useFetch("/carddata/team/all", true, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [cachedChecklistResults, setCachedChecklistResults] = useState<any>({});

  const updateSkus = (field: string, item: any) => {
    setSkuList((prevVals: any) => {

      const itemExists = prevVals[field]["items"].find((x: any) => x.publicId === item.publicId);

      if(itemExists){
        const vals = { ...prevVals };
        vals[field]["items"] = vals[field]["items"].map((x: any) => x.publicId === item.publicId ? item : x);
        return vals;
      }
      else{
        const vals = { ...prevVals };
        vals[field]["items"].push(item);
        return vals;
      }
  
    });
  };

  const updatePlayers = (item: any) => {
    setPlayers((prevVals: any) => {
      const vals = [...prevVals];
      vals.push(item);
      return vals;
    });
  };

  const updateVendors = (item: any) => {
    setVendors((prevVals: any) => {
      const vals = [...prevVals];
      vals.push(item);
      return vals;
    });
  };

  const isLoading = skuListLoading || filterListLoading || statusTypesLoading || playersLoading || vendorsLoading || usersLoading || teamsLoading;

  const deleteChecklistEntry = (id: number) => {
    setCachedChecklistResults((prevVals: any) => {
      const vals = { ...prevVals };
      delete vals[id];
      return vals;
    });
  };

  const updateChecklistEntry = (id: number, checklistData: any) => {
    setCachedChecklistResults((prevVals: any) => {
      const vals = { ...prevVals };
      vals[id] = checklistData;
      return vals;
    });
  };

  const getChecklistData = useCallback((id: number) => {
    if (id in cachedChecklistResults) {
      return cachedChecklistResults[id];
    }
    return [];
  }, [cachedChecklistResults]);

  const recalculateFilter = (card: any) => {
    if (shouldRecalculateChecklistSearch(card)) {
      fetchData.fetchResponseAuthed(
        "/Checklist/search/" + card["player"].label + "/" + card["year"].label + "/" + card["manufacturer"].label + "/" + card["series"].label,
      ).then((response: { json: () => Promise<any>; }) => {
        response.json().then(data => {
          updateChecklistEntry(card["id"], data);
        });
      });
    }
  };

  const findTeams = (player:string,year:string,mfgr:string,series:string,set:string,apiRef:any,id:number,field:string) => {
    if(player && year && mfgr && series && set){
      fetchData.fetchResponseAuthed(
        "/carddata/team/checklist/" + player + "/" + year + "/" + mfgr + "/" + series + "/" + set,
      ).then((response: { json: () => Promise<any>; }) => {
        response.json().then(async data => {
          await apiRef.current.setEditCellValue({ id, field, value: data });
        });
      });
    }
  }


  const shouldRecalculateChecklistSearch = (row: any) => {
    if (row === null) {
      return false;
    }
    const id = row["id"];
    if (id && id in cachedChecklistResults) {
      const reference = cachedChecklistResults[id];
      if (reference["player"] === row["player"] && reference["mfgr"] === row["manufacturer"] && reference["series"] === row["series"] && reference["year"] === row["year"]) {
        return row["player"] && row["manufacturer"] && row["series"] && row["year"];
      }
    }
    return row["player"] && row["player"].label && 
      row["manufacturer"] && row["manufacturer"].label && 
      row["series"] && row["series"].label && 
      row["year"] && row["year"].label;
  };

  const contextValues = {
    skuList,
    setSkuList,
    filterList,
    setFilterList,
    statusTypes,
    players,
    vendors,
    setVendors,
    users,
    updateSkus,
    updatePlayers,
    modalOpen,
    setModalOpen,
    updateVendors,
    teams,
    setTeams,
    recalculateFilter,
    deleteChecklistEntry,
    getChecklistData,
    cachedChecklistResults,
    findTeams
  };

  return (
    <CardDashboardContext.Provider value={contextValues}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner />
        </div>
      ) : (
        children
      )}
    </CardDashboardContext.Provider>
  );
};

// Custom hook to use the CardDashboardContext
export function useCardDashboard() {
  const context = useContext(CardDashboardContext);
  if (!context) {
    throw new Error('useCardDashboard must be used within a CardDashboardProvider');
  }
  return context;
}