import React, { Component } from 'react';
import { Button, Table, InputGroup, Input } from 'reactstrap';
import '../Hamburger.css';
import './break.css';
import FetchModule from "../../Modules/FetchModule";
import BreakListItem from './BreakListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faCircleUser, faBars, faMoneyBill, faHouse, faUsers, faBarcode, faBox, faFileLines);

const fetchData = new FetchModule();

export default class BreakList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: "breaks",
            currentProducts: {},
            products: [],
            productsToShow: [],
            searchText: "",
            sessionId: 0,
            session: null,
        }
        let path = window.location.pathname
        let id = path.split('/')
        this.state.sessionId = id[3]
    }

    async componentDidMount() {
        const response = await fetchData.fetchResponseAuthed("/Breaking/breaks/"+this.state.sessionId, "GET");
        let data = await response.json()
        this.updateState("products", data)
        this.updateProductDisplay(this.state.searchText);
    }
    updateProductDisplay = (text) => {
        this.setState({productsToShow:
            this.state.products
                .sort((a, b) => {
                    let x = a.breakSKU
                    let y = b.breakSKU
                    return x == y ? 0 : x > y ? 1 : -1;
                })
                .filter(product => {
                    var containsAll = true;
                    text.split(' ').forEach(word => {
                        if (!product.breakSKU.toLowerCase().includes(word.toLowerCase())) {
                            containsAll = false;
                        }
                    })
                    return containsAll ? product : null;
                })
        })
    }
    updateState = (name, state, func) => {
        this.setState({
            [name]: state
        }, func)
    }
    textChange = (event) => {
        this.updateState('searchText', event.target.value)
        this.updateProductDisplay(event.target.value)
    }
    displayProduct = (item) => {
        return (
            <tr key={item.product.id +"P"}>
                <td>{item.product.name}</td>
                <td>                    
                    <Input className="fit-search" 
                        id={item.product.id} 
                        value={item.quantity}
                        type="number"
                        onChange={this.quantityChanged}
                        placeholder="Enter in a valid quantity"
                    />
                </td>
                <td><Button color="danger" onClick={this.removeProduct} value={item.product.id}>X</Button></td>
            </tr>
        )
    }
    displayProduct = (product) => {
        return (
            <BreakListItem 
                session={this.props.session}
                key={product.breakID}
                product={product} navigate={this.props.navigate}/>
        )
    }
    getData = () => {
        return this.state.productsToShow.map(product => this.displayProduct(product))
    }
    createBreak = async () => {
        let prods = this.props.currentProducts
        var products = Object.values(prods)
        var bodyData = []
        products.forEach(val => {
            bodyData.push({
                ProductCode: val.product.sku,
                PriceTier1: val.product.priceTier1 * val.quantity,
                Quantity: val.quantity
            })
        })
        await fetchData.promise("/Breaking/new", true, "POST", bodyData)
    }
    getSearchBody = () => {
        return (
            <div style={{height: '95%'}}>
                <InputGroup className="whole-search" >
                <div className="fit-search search-bar">
                    <Input className="fit-search" id="searchText" 
                        value={this.state.searchText}
                        onChange={this.textChange}
                        placeholder="Search"
                    />
                    <FontAwesomeIcon onClick={this.clearText} className="search-box-clear" icon="xmark" />
                </div>
                </InputGroup>
                <Table className="fixTable" >
                        <thead>
                            <tr className="thead-fix">
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Break Type</th>
                                <th>Date</th>
                                <th>Price</th>
                                <th>Edit</th>
                           </tr>
                        </thead>
                        <tbody className="tableBody" style={{overflowY: "scroll"}}>
                            {this.getData()}
                        </tbody>
                    </Table>
            </div>
        )
    }
    getFlexDirection = () => {
    }
    render() {
        return (
            <div className="dashboard-nav-right-flip">
                <div className="main-card" style={{padding: "10px"}}>
                    {this.getSearchBody()}
                </div>
            </div>
        )
    }

}
